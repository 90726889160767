import React, { Component } from 'react';
import './home.css';

export class MembershipAdvantages extends Component {
  render() {
    return (
      <div className="membership-advantages p-4">
        <div className="container">
          <h3>Membership advantages</h3>
          <div className="row">
            <div className="card border-0 col-4">
              <div className="card-img text-start pt-3 p-2 mx-2">
                <i className="icon foreground-ic-orange-trust-badge" aria-hidden="true"></i>
              </div>
              <div className="card-body text-start py-3">
                <h4 className="card-title text-decoration-underline">Certification program</h4>
                <p className="line-height-1-5 fs-4 fw-bold">Selected IoT devices and solutions are certified to be compliant with Orange networks.</p>
              </div>
            </div>
            <div className="card border-0 col-4">
              <div className="card-img text-start pt-3 p-2 mx-2">
                <i className="icon foreground-Orange_Experts" aria-hidden="true"></i>
              </div>
              <div className="card-body text-start py-3">
                <h4 className="card-title text-decoration-underline">New business opportunities program</h4>
                <p className="line-height-1-5 fs-4 fw-bold">
                  As part of the Orange products and solutions repository, Foreground is the place where to showcase online.
                </p>
              </div>
            </div>
            <div className="card border-0 col-4">
              <div className="card-img text-start pt-3 p-2 mx-2">
                <i className="icon foreground-Improvement" aria-hidden="true"></i>
              </div>
              <div className="card-body text-start py-3">
                <h4 className="card-title text-decoration-underline">Management solution</h4>
                <p className="line-height-1-5 fs-4 fw-bold">
                  To get the best out of a repository Foreground provides an extended management solution with analytics.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center mt-3">
          <a href={`/register`} className="btn btn-secondary">
            Sign up to Foreground
          </a>
        </div>
      </div>
    );
  }
}
