import { Component } from 'react';
import { createBrowserHistory } from 'history';
import LoadingSpinner from '../../components/LoadingSpinner';

import './product_management.css';

import { ORANGE_ADMIN_SCOPE, ORANGE_PARTNER_SCOPE, ORANGE_USER_SCOPE, PARTNER_SCOPE, SESSION_STORAGE_DOC_EDIT_ONLY } from '../../models/constants';
import {
  deviceIsApproved,
  deviceIsAssessed,
  deviceIsConnected,
  DeviceSolution,
  DeviceCompany,
  DeviceUseCase,
  Verticals,
  DeviceStatus,
  DeviceMaturity,
  BatteryLife,
  DeviceProductLiveCycle
} from '../../models/device';
import ProductService from '../../services/product.service';
import Utils from '../../helpers/Utils';
import AddReview from './AddReview';
import { AppProps, PropsMatch } from '../../models/Props';
import { PageHeader } from '../../components/PageHeader';
import ModalDeleteReview from '../../components/user-management/DeleteReviewModal';
import { BackToTop } from '../../components/backToTopButton';

class DeviceInformationsProps {
  public appProps: AppProps;
  public history: any;
  public match: PropsMatch;
}

class DeviceInformationsState {
  isLoading: boolean;
  nbPages: number;
  device: DeviceSolution;
  currentPage: number;
  nbDevices: number;
  verticals: Verticals[];
  deviceVerticals: Verticals[];
  useCases: DeviceUseCase[];
  deviceUseCases: DeviceUseCase[];
  isASolution: boolean;
  showAddReview: boolean;
  showDeleteModal: boolean;
  isErrorAddReview: boolean;
  addReviewErrorMessage: string;
  isErrorReviewDeletion: boolean;
  reviewDeletionErrorMessage: string;
  user: string;
  review: string;
  reviewId: number;

  constructor(isAsolution: boolean) {
    this.isLoading = true;
    this.nbPages = 1;
    this.device = null;
    this.currentPage = 0;
    this.nbDevices = 0;
    this.verticals = [];
    this.deviceVerticals = [];
    this.useCases = [];
    this.deviceUseCases = [];
    this.isASolution = isAsolution;
    this.showAddReview = false;
    this.showDeleteModal = false;
    this.isErrorReviewDeletion = false;
    this.reviewDeletionErrorMessage = '';
    this.isErrorAddReview = false;
    this.addReviewErrorMessage = '';
    this.user = '';
    this.review = '';
    this.reviewId = 0;
  }
}

export class DeviceInformations extends Component<DeviceInformationsProps, DeviceInformationsState> {
  constructor(props) {
    super(props);

    const isAsolution = props.match.path === '/solutions/:id';
    this.state = new DeviceInformationsState(isAsolution);
  }

  async componentDidMount() {
    try {
      const id = this.props.match.params.id;
      const promises = [];
      if (this.state.isASolution) {
        promises.push(ProductService.solutionDetails(id));
      } else {
        promises.push(ProductService.deviceDetails(id));
      }
      promises.push(ProductService.deviceVerticals());
      promises.push(ProductService.deviceUseCases());
      const response = await Promise.all(promises);
      const deviceVerticals = [];
      const deviceUseCases = [];
      const device = response[0].data;
      const verticals = response[1].data;
      const useCases = response[2].data;
        device.verticals.forEach((element) => {
          const vertical = element.parent !== 0 ? verticals.find((v) => v.id === element.parent) : element;
          const v = deviceVerticals.find((v) => v.id === vertical.id);
          if (!!!v) {
            deviceVerticals.push(vertical);
          }
        });


        device.usescases.forEach((element) => {
          const usecase = element.parent !== 0 ? useCases.find((u) => u.id === element.parent) : element;
          const u = deviceUseCases.find((u) => u.id === usecase.id);
          if (!!!u) {
            deviceUseCases.push(usecase);
          }
        });

      this.setState({
        isLoading: false,
        device: response[0].data,
        verticals: response[1].data,
        useCases: response[2].data,
        deviceVerticals: deviceVerticals,
        deviceUseCases: deviceUseCases,
      });
    } catch (error) {
      console.log(`ERROR DeviceInformations, in gathering process : ${JSON.stringify(error)}`);
      this.setState({ isLoading: false });
    }
  }

render() {
    const scope = this.props.appProps.scope;
    let device = this.state.device;
    if (!!!device) {
      device = new DeviceSolution();
      device.company = new DeviceCompany('', '');
      device.versions = [];
      device.status = new DeviceStatus(-110, '');
      device.maturity = new DeviceMaturity(-111, '');
      device.batteryLife = new BatteryLife(-112, '');
      device.productLifeCycle = new DeviceProductLiveCycle(2, 'In Production');

    }
    // const baseUrl = window.location.hostname === 'localhost' || '127.0.0.1' ? 'https://foreground-v3-pre-prod.apps.fr01.paas.tech.orange' : '';
    const baseUrl = process.env.REACT_APP_BASE_URL_API;
    const image = !!device && !!device.images ? device.images.find((i) => i.isReference) : null;
    const productPrice =
      !!device && device.pricingFrom1To25Number > 0 ? `${device.pricingFrom1To25} ${device.pricingUnit === '€' ? '€ (EXW)' : '$ (US)'}` : '';
    const deviceSolution = this.state.isASolution;
    document.title = device.name+' - Foreground V3';
    return (
      <div className="p-3 bg-white">
        {this.state.isLoading ? (
          <LoadingSpinner />
        ) : (
          <div>
            <PageHeader title={device.name}></PageHeader>
            <div className="row mt-2">
              <div className="col-md-4">
                <div className="mb-3">
                  <strong aria-label="description" id="description" dangerouslySetInnerHTML={{ __html: device.description }}></strong>
                </div>
                {!deviceSolution ? (
                  <div>
                    <h2 className="mt-2">
                      By{' '}
                      <a id="companyName" className="device-company" href={`/companies/${device.company.slug}`}>
                        {device.company.name}
                      </a>
                    </h2>
                    <div id="productPrice" className="display-4 text-primary">
                      {productPrice}
                    </div>
                    {productPrice !== '' ? (
                      <p className="mb-3">
                        <strong>per unit</strong>
                      </p>
                    ) : (
                      ''
                    )}
                  </div>
                ) : (
                  <></>
                )}
                <div className="mb-3">
                  <h3 className="mb-0 ">Verticals</h3>
                  <ul id="deviceVerticals" className="pl-3 pt-1">
                    {!!this.state.deviceVerticals
                      ? this.state.deviceVerticals.map((vertical, i=0) => {
                          return (
                            <li key={vertical.label}>
                              <strong aria-label={'vertical-item'+ i++}>{vertical.label}</strong>
                            </li>
                          );
                        })
                      : null}
                  </ul>
                </div>
                <div className="mb-3">
                  <h3 className="mb-0 ">Use cases</h3>
                  <ul id="deviceUseCases" className="pl-3 pt-1">
                    {!!this.state.deviceUseCases
                      ? this.state.deviceUseCases.map((usecase, i= 0) => {
                          return (
                            <li key={usecase.label}>
                              <strong aria-label={'useCases-item' + i++}>{usecase.label}</strong>
                            </li>
                          );
                        })
                      : null}
                  </ul>
                </div>
                {!deviceSolution ? (
                  <div className="mb-3">
                    <h3 className="mb-0 ">Sensors</h3>
                    {!!device.deviceModularity ? (
                      <ul className="pl-3 pt-1">
                        <li>
                          <strong aria-label='sensors'>{device.deviceModularity.sensors}</strong>
                        </li>
                      </ul>
                    ) : (
                      <></>
                    )}
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div className="col-md-3" style={{ maxHeight: '400px' }}>
                {!!image && !!image.link ? (
                  <img
                    className="container product-img"
                    style={{ height: '100%' }}
                    src={`${baseUrl}/public/storage/img/${image.link}`}
                    alt={`${device.name} image`}
                    onError={(e) => {
                      (e.target as HTMLImageElement).onerror = null;
                      (e.target as HTMLImageElement).remove();
                    }}
                  />
                ) : (
                  <div className="product-img"></div>
                )}
              </div>
              <div className="col-md-5">
                <div className="p-2">
                  <div>
                    <strong aria-label='last updated'>Last updated: {Utils.formatDateToWeb(device.updatedAt)}</strong>
                  </div>
                  <div className="mt-2">
                    <strong aria-label='product life cycle'>Product availability Status : {device.productLifeCycle.label}</strong>
                  </div>
                  {!this.state.isASolution ? (
                    <>
                      <div className="w-250">
                        {scope === ORANGE_ADMIN_SCOPE || scope === ORANGE_USER_SCOPE ? (
                          <button className="btn btn-dark btn-block mt-3" onClick={() => this.setState({ showAddReview: true, review: '' })}>
                            <span className="icon foreground-ic_Pencil me-2" aria-hidden="true"></span> Add review
                          </button>
                        ) : (
                          ''
                        )}
                      </div>
                      <div className="w-250">
                        {scope === ORANGE_ADMIN_SCOPE || scope === PARTNER_SCOPE ? (
                          <>
                            <button
                              className='btn btn-dark btn-block mt-3'
                              onClick={() => {
                                sessionStorage.setItem(SESSION_STORAGE_DOC_EDIT_ONLY, SESSION_STORAGE_DOC_EDIT_ONLY);
                                this.props.history.push(`/products/${device.slug}/edit`);
                              }}
                            >
                              <span className='icon foreground-ic_Pencil me-2' aria-hidden='true'></span> Add documents
                            </button>
                            <button
                              id='editDevice'
                              className='btn btn-dark btn-block mt-3'
                              onClick={() => {
                                sessionStorage.removeItem(SESSION_STORAGE_DOC_EDIT_ONLY);
                                this.props.history.push(`/products/${device.slug}/edit`);
                              }}
                            >
                              <span className='icon foreground-ic_Pencil me-2' aria-hidden='true'></span>Edit informations
                            </button>
                          </>
                        ) : (
                          ''
                        )}
                      </div>
                      {this.props.appProps.canDeleteCompaniesAndDevices ? (
                        <div className="w-250">
                          <button
                            id="deleteDevice"
                            className="btn btn-dark btn-block mt-3"
                            onClick={() => {
                              ProductService.deleteDevice(device.slug);
                              this.props.history.push('/products');
                            }}
                          >
                            <span className="icon foreground-Trash me-2" aria-hidden="true"></span> Delete
                          </button>
                        </div>
                      ) : null}
                    </>
                  ) : (
                    <></>
                  )}
                  <div className="mt-3">
                    {deviceIsAssessed(device) ? (
                      <img
                        id="deviceIsAssessed"
                        className="imgLabel me-2"
                        src={`${process.env.PUBLIC_URL}/assets/img/Orange_Assessed_Small_Logo_Black_RGB.svg`}
                        alt="Orange label assessed"
                      ></img>
                    ) : (
                      <></>
                    )}
                    {deviceIsConnected(device) ? (
                      <img
                        id="deviceIsConnected"
                        className="imgLabel me-2"
                        src={`${process.env.PUBLIC_URL}/assets/img/Orange_Connected_Small_Logo_Black_RGB.svg`}
                        alt="Orange label connected"
                      ></img>
                    ) : (
                      <></>
                    )}
                    {deviceIsApproved(device) ? (
                      <img
                        id="deviceIsApproved"
                        className={`imgLabel ${deviceIsAssessed(device) && deviceIsConnected(device) ? 'mt-2' : ''}`}
                        src={`${process.env.PUBLIC_URL}/assets/img/Orange_Approved_Small_Logo_Black_RGB.svg`}
                        alt="Orange label approved"
                      ></img>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/***********************************************************************
             * Description Section
             *********************************************************************/}
            <div className="row border-bottom border-light mt-5 pb-2 mb-4">
              <h2 className="">Description</h2>
            </div>
            {deviceSolution ? (
              <>
                <div className="row mb-3">
                  <div className="col-md-2">
                    <p className="text-muted">
                      <strong>Device Solution</strong>
                    </p>
                  </div>
                  <div className="col-md-3">
                    <strong>
                      <ul className="list-group" style={{ overflowY: 'scroll', height: '100px' }}>
                        {!!device.devices ? (
                          device.devices.map((d) => {
                            return (
                              <li key={d.slug} className="">
                                <a href={`/products/${d.slug}`}> {d.name} </a>
                              </li>
                            );
                          })
                        ) : (
                          <p className="text">No device</p>
                        )}
                      </ul>
                    </strong>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-md-2">
                    <p className="text-muted">
                      <strong>Solution Partner</strong>
                    </p>
                  </div>
                  <div className="col-md-3">
                    <strong>
                      <ul className="list-group" style={{ overflowY: 'scroll', height: '100px' }}>
                        {!!device.companies ? (
                          device.companies.map((d) => {
                            return (
                              <li key={d.slug} className="">
                                <a href={`/companies/${d.slug}`}> {d.name} </a>
                              </li>
                            );
                          })
                        ) : (
                          <p className="text">No partner</p>
                        )}
                      </ul>
                    </strong>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
            <div className="row mb-3">
              <div className="col-md-2">
                <p className="text-muted">
                  <strong id="featuresLabel">{!deviceSolution ? 'Main features' : 'Main expertises'}</strong>
                </p>
              </div>
              <div className="col-md-6">
                <strong aria-label='main features' id="features"> {device.features} </strong>
              </div>
            </div>
            {!deviceSolution ? (
              <div className="row mb-3">
                <div className="col-md-2">
                  <p className="text-muted">
                    <strong>Web site</strong>
                  </p>
                </div>
                <div className="col-md-6">
                  <a id="website" href={device.website} target="_blank">
                    {device.website}
                  </a>
                </div>
              </div>
            ) : (
              <></>
            )}
            {/***********************************************************************
             * Features Section
             *********************************************************************/}
            <div className="row border-bottom border-light mt-5 pb-2 mb-4">
              <h2 className="">Features</h2>
            </div>
            <div className="row">
              <div className="col-md-6">
                <h3>Product</h3>
                <div className="row mb-3">
                  <div className="col-md-4">
                    <p className="text-muted">
                      <strong>Type</strong>
                    </p>
                  </div>
                  <div className="col-md-8">
                    <strong id="statusLabel" aria-label="type">{device.status.label}</strong>
                  </div>
                </div>
                {!deviceSolution ? (
                  <div className="row mb-3">
                    <div className="col-md-4">
                      <p className="text-muted">
                        <strong>Sample availability</strong>
                      </p>
                    </div>
                    <div className="col-md-8">
                      <strong id="samplesAvailable" aria-label="sample availability">{device.samplesAvailable ? 'Yes' : 'No'}</strong>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                <div className="row mb-3">
                  <div className="col-md-4">
                    <p className="text-muted">
                      <strong>Device maturity</strong>
                    </p>
                  </div>
                  <div className="col-md-8">
                    <strong id="deviceMaturity" aria-label="device maturity">{device.maturity.label}</strong>
                  </div>
                </div>
                {!deviceSolution ? (
                  <>
                    <div className="row mb-3">
                      <div className="col-md-4">
                        <p className="text-muted">
                          <strong>Dimensions (mm)</strong>
                        </p>
                      </div>
                      <div className="col-md-8">
                        <strong id="dimensions" aria-label="dimension (mm)">
                          {device.dimensionX}x{device.dimensionY}x{device.dimensionZ}
                        </strong>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-4">
                        <p className="text-muted">
                          <strong>Weight (g)</strong>
                        </p>
                      </div>
                      <div className="col-md-8">
                        <strong id="weight" aria-label="weight (g)">{device.weight}</strong>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-4">
                        <p className="text-muted">
                          <strong>External power supply</strong>
                        </p>
                      </div>
                      <div className="col-md-8">
                        <strong id="powerSupply" aria-label="external power supply">{device.powerSupply ? 'Yes' : 'No'}</strong>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-4">
                        <p className="text-muted">
                          <strong>Battery longevity</strong>
                        </p>
                      </div>
                      <div className="col-md-8">
                        <strong id="batteryLife" aria-label="battery longevity">{device.batteryLife.content}</strong>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-4">
                        <p className="text-muted">
                          <strong>Rechargeable battery</strong>
                        </p>
                      </div>
                      <div className="col-md-8">
                        <strong id="batteryRechargeable" aria-label="rechargeable battery">{device.batteryRechargeable ? 'Yes' : 'No'}</strong>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-4">
                        <p className="text-muted">
                          <strong>Operating temperature range (°C)</strong>
                        </p>
                      </div>
                      <div className="col-md-8">
                        <strong id="operatingTemperature" aria-label="operating temperature range (°C)">{`${device.operatingTemperatureStart} / + ${device.operatingTemperatureStop}`}</strong>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-4">
                        <p className="text-muted">
                          <strong>eUICC (eSIM) compatibility</strong>
                        </p>
                      </div>
                      <div className="col-md-8">
                        <strong id="esim" aria-label="eUICC (eSIM) compatibility">{device.esim ? 'Yes' : 'No'}</strong>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-4">
                        <p className="text-muted">
                          <strong>Regulation compliancy</strong>
                        </p>
                      </div>
                      <div className="col-md-8">
                        <strong>
                          <ul id="deviceRegulations">
                            {!!device.regulations
                              ? device.regulations.map((r, i = 0) => {
                                  return <li key={r.id} aria-label={"regulationCompliancy-item" + i++}>{r.label}</li>;
                                })
                              : null}
                          </ul>
                        </strong>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                {deviceSolution ? (
                  <>
                    <div className="row mb-3">
                      <div className="col-md-4">
                        <p className="text-muted">
                          <strong>Platform available</strong>
                        </p>
                      </div>
                      <div className="col-md-8">
                        <strong>{device.platform.isPlatformAvailable ? 'Yes' : 'No'}</strong>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-4">
                        <p className="text-muted">
                          <strong>Cloud Description</strong>
                        </p>
                      </div>
                      <div className="col-md-8">
                        <strong>{device.cloud}</strong>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-4">
                        <p className="text-muted">
                          <strong>Integrated with LiveObject</strong>
                        </p>
                      </div>
                      <div className="col-md-8">
                        <strong>{device.platform.isIntegratedWithLO ? 'Yes' : 'No'}</strong>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-4">
                        <p className="text-muted">
                          <strong>Frontend</strong>
                        </p>
                      </div>
                      <div className="col-md-8">
                        <strong>{device.frontend.label}</strong>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
              {/***********************************************************************
               * Network Section
               *********************************************************************/}
              <div className="col-md-6">
                <h3>Network</h3>
                <div className="row mb-3">
                  <div className="col-md-4">
                    <p className="text-muted">
                      <strong>Network/Radio interfaces</strong>
                    </p>
                  </div>
                  <div className="col-md-8">
                    <strong>
                      <ul id="deviceNetworks">
                        {!!device.networks
                          ? device.networks.map((n, i= 0) => {
                              return <li key={n.id} aria-label={"interface-item" + i++}>{n.label}</li>;
                            })
                          : null}
                      </ul>
                    </strong>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-md-4">
                    <p className="text-muted">
                      <strong>Geolocation technology</strong>
                    </p>
                  </div>
                  <div className="col-md-8">
                    <strong>
                      <ul id="deviceGeolocation">
                        {!!device.geolocation
                          ? device.geolocation
                              .filter((n) => n.geolocation)
                              .map((n, i = 0 ) => {
                                return <li key={n.id} aria-label={"geolocationTechnology-item" + i++}>{n.label}</li>;
                              })
                          : null}
                      </ul>
                    </strong>
                  </div>
                </div>
                {!deviceSolution ? (
                  <>
                    <div className="row mb-3">
                      <div className="col-md-4">
                        <p className="text-muted">
                          <strong>IP norm</strong>
                        </p>
                      </div>
                      <div className="col-md-8">
                        <strong>
                          <ul id="deviceIpNorms">
                            {!!device.ipnorms
                              ? device.ipnorms.map((n, i = 0) => {
                                  return <li key={n.id} aria-label={"IPNorm-item" + i++}>{n.label}</li>;
                                })
                              : null}
                          </ul>
                        </strong>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-4">
                        <p className="text-muted">
                          <strong>Iot protocol</strong>
                        </p>
                      </div>
                      <div className="col-md-8">
                        <strong>
                          <ul id="deviceProtocols">
                            {!!device.protocols
                              ? device.protocols.map((p, i = 0) => {
                                  return <li key={p.id} aria-label={"Iot protocol " + i++}>{p.label}</li>;
                                })
                              : null}
                          </ul>
                        </strong>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-4">
                        <p className="text-muted">
                          <strong>Range (m)</strong>
                        </p>
                      </div>
                      <div className="col-md-8">
                        <strong id="range" aria-label="range">{device.range}</strong>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                <h3>Distribution</h3>
                <div className="row mb-3">
                  <div className="col-md-4">
                    <p className="text-muted">
                      <strong>Worldwide availability</strong>
                    </p>
                  </div>
                  <div className="col-md-8">
                    <strong>
                      <ul className="list-group" style={{ overflowY: 'scroll', height: '200px' }}>
                        {!!device.productionCountries ? (
                          device.productionCountries.map((c, i = 0 ) => {
                            return (
                              <li key={c.id} aria-label={"worldwideAvailability-item" + i++} className="list-group-item">
                                {c.countryName}
                              </li>
                            );
                          })
                        ) : (
                          <p className="text">No country</p>
                        )}
                      </ul>
                    </strong>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-md-4">
                    <p className="text-muted">
                      <strong aria-label="deployed in our countries">Deployed in our countries</strong>
                    </p>
                  </div>
                  <div className="col-md-8">
                    <strong>
                      <ul id="deployedInCountries" className="list-group" style={{ overflowY: 'scroll', height: '200px' }}>
                        {!!device.deployedInCountries ? (
                          device.deployedInCountries.map((c, i= 0) => {
                            return (
                              <li key={c.id} aria-label={"deployedInCounrty-item" + i++} className="list-group-item">
                                {c.countryName}
                              </li>
                            );
                          })
                        ) : (
                          <p className="text">No country</p>
                        )}
                      </ul>
                    </strong>
                  </div>
                </div>
              </div>
            </div>
            {/***********************************************************************
             * Documents Section
             *********************************************************************/}
            <div className="row border-bottom border-light mt-5 pb-2 mb-4">
              <h2 className="">Documents</h2>
            </div>
            <div className="mb-3">
              <div className="mt-5 pb-2 mb-4">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col" className="col-md-7">
                        <p className="text-muted text-small">Name</p>
                      </th>
                      <th scope="col">
                        <p className="text-muted text-small">Type</p>
                      </th>
                      <th scope="col">
                        <p className="text-muted text-small">Category</p>
                      </th>
                      <th scope="col">
                        <p className="text-muted text-small">Updated at</p>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {!!device.documents
                      ? device.documents.map((d) => {
                          return (
                            <tr key={d.id}>
                              <td>
                                <a href={`${baseUrl}/public/storage/files/${d.link}`} target="_blank">
                                  {d.name}
                                </a>
                              </td>
                              <td>
                                <img
                                  className="document-extension"
                                  src={Utils.getIconForDocument(d.link)}
                                  alt={Utils.getExtensionDocumentForAlt(d.link)}
                                />
                              </td>
                              <td>{d.type.label}</td>
                              <td>{Utils.formatDateToWebWithTime(d.updatedAt)}</td>
                              <td></td>
                            </tr>
                          );
                        })
                      : null}
                  </tbody>
                </table>
              </div>
            </div>
            {/***********************************************************************
             * Pricing Section
             *********************************************************************/}
            <div className="row border-bottom border-light mt-5 pb-2 mb-4">
              <h2 className="">Pricing</h2>
            </div>
            <div className="row mb-3">
              <div className="col-md-2">
                <p className="text-muted">
                  <strong>Business model</strong>
                </p>
              </div>
              <div className="col-md-3">
                <strong aria-label="business model"> {device.businessModel} </strong>
              </div>
            </div>
            {!deviceSolution ? (
              <>
                <div className="row mb-3">
                  <div className="col-md-2">
                    <p className="text-muted">
                      <strong>Currency</strong>
                    </p>
                  </div>
                  <div className="col-md-3">
                    <strong aria-label="currency"> {device.pricingUnit === '€' ? '€ (EXW)' : '$ (US$)'} </strong>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-md-2">
                    <p className="text-muted">
                      <strong>Price</strong>
                    </p>
                  </div>
                  <div className="col-md-3">
                    <div className="row">
                      <div className="col-md-6">
                        <strong> 1-25 units</strong>
                      </div>
                      <div className="col-md-6">
                        <strong aria-label="pricing 1 to 25">{device.pricingFrom1To25Number === 0 ? 'Not filed' : `${device.pricingFrom1To25} ${device.pricingUnit}`}</strong>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <strong> 26-50 units</strong>
                      </div>
                      <div className="col-md-6">
                        <strong aria-label="pricing 26 to 50">{device.pricingFrom26To50Number === 0 ? 'Not filed' : `${device.pricingFrom26To50} ${device.pricingUnit}`}</strong>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <strong> 51-100 units</strong>
                      </div>
                      <div className="col-md-6">
                        <strong aria-label="pricing 51 to 100">{device.pricingFrom51To100Number === 0 ? 'Not filed' : `${device.pricingFrom51To100} ${device.pricingUnit}`}</strong>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <strong> 101-250 units</strong>
                      </div>
                      <div className="col-md-6">
                        <strong aria-label="pricing 101 to 250">
                          {device.pricingFrom101To250Number === 0 ? 'Not filed' : `${device.pricingFrom101To250} ${device.pricingUnit}`}
                        </strong>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <strong> 251-500 units</strong>
                      </div>
                      <div className="col-md-6">
                        <strong aria-label="pricing 251 to 500">
                          {device.pricingFrom251To500Number === 0 ? 'Not filed' : `${device.pricingFrom251To500} ${device.pricingUnit}`}
                        </strong>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <strong> 501-1000 units</strong>
                      </div>
                      <div className="col-md-6">
                        <strong aria-label="pricing 501 to 1000">
                          {device.pricingFrom501To1000Number === 0 ? 'Not filed' : `${device.pricingFrom501To1000} ${device.pricingUnit}`}
                        </strong>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <strong> 1001-5000 units</strong>
                      </div>
                      <div className="col-md-6">
                        <strong aria-label="pricing 1001 to 5000">
                          {device.pricingFrom1001To5000Number === 0 ? 'Not filed' : `${device.pricingFrom1001To5000} ${device.pricingUnit}`}
                        </strong>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <strong> 5001-10 000 units</strong>
                      </div>
                      <div className="col-md-6">
                        <strong aria-label="pricing 5001 to 10000">
                          {device.pricingFrom5001To10000Number === 0 ? 'Not filed' : `${device.pricingFrom5001To10000} ${device.pricingUnit}`}
                        </strong>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <strong> 10 001+ units</strong>
                      </div>
                      <div className="col-md-6">
                        <strong aria-label="pricing above 10000">{device.pricingAbove10000Number === 0 ? 'Not filed' : `${device.pricingAbove10000} ${device.pricingUnit}`}</strong>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
            {/***********************************************************************
             * Reviews Section
             *********************************************************************/}
            {scope !== ORANGE_PARTNER_SCOPE && scope !== PARTNER_SCOPE ? (
              <>
                <div className="row border-bottom border-light mt-5 pb-2 mb-4">
                  <h2 className="">{`Reviews (${!!device.reviews ? device.reviews.length : 0})`}</h2>
                </div>
                <table style={{ width: '100%' }}>
                  <tbody>
                    {!!device.reviews ? device.reviews.map((item) => {
                          return (
                            <tr aria-label={'reviews-item' + item.id} key={`reviews-${item.id}`}>
                              <td style={{ width: '30%' }}>
                                {' '}
                                <div className="media mb-4">
                                  <div>
                                    <i className="foreground-Avatar align-self-start mr-2 icon-27"></i>
                                    <strong> {`${item.user.firstName} ${item.user.lastName}`} </strong>
                                  </div>
                                  <div className="text-muted ms-4 mt-2 mb-2">{Utils.formatDateToWebWithTime(item.createdAt)}</div>
                                  <p className="ms-4">{item.review}</p>
                                </div>
                              </td>
                              <td style={{ width: '100%' }}></td>
                              <td>
                                <button
                                  id={`deleteUser-review${item.id}`}
                                  aria-label={`deleteUser-review${item.id}`}
                                  className="btn icon foreground-Trash delete_button not_underlined"
                                  onClick={() => {
                                    this.setState({ showDeleteModal: true, user: item.user.firstName + ' ' + item.user.lastName, reviewId: item.id });
                                  }}
                                ></button>
                              </td>
                            </tr>
                          );
                        })
                      : null}
                  </tbody>
                </table>
              </>
            ) : null}
          </div>
        )}
        {/*Modal call*/}
        <AddReview
          show={this.state.showAddReview}
          isErrorReviewValidation={this.state.isErrorAddReview}
          validationErrorMessage={this.state.addReviewErrorMessage}
          onChange={(e) => this.handleOnChangeReview(e)}
          onClose={() => this.handleCloseAddReviewDlg()}
          onAddReview={() => this.handleAddReview()}
        ></AddReview>
        <ModalDeleteReview
          show={this.state.showDeleteModal}
          user={this.state.user}
          isErrorReviewDeletion={this.state.isErrorReviewDeletion}
          reviewDeletionErrorMessage={this.state.reviewDeletionErrorMessage}
          onClose={() => this.handleCloseDeleteReviewDlg()}
          onDelete={() => this.handleDeleteReview(this.state.reviewId)}
        ></ModalDeleteReview>
        {BackToTop}
      </div>
    );
  }

  async handleOnChangeReview(e) {
    this.setState({ review: e.target.value.trim() });
  }

  async handleCloseAddReviewDlg() {
    this.setState({ showAddReview: false });
  }

  async handleCloseDeleteReviewDlg() {
    this.setState({ showDeleteModal: false });
  }

  async handleDeleteReview(id) {
    try {
      const res = await ProductService.deleteReview(this.state.device.slug, id);
      if (res.status === 204) {
        document.dispatchEvent(
          new CustomEvent('addNotification', {
            detail: {
              type: 'success',
              content: `The review has been deleted.`,
            },
          })
        );
        this.setState({ showDeleteModal: false, isErrorReviewDeletion: false });
        this.componentDidMount();
      } else {
        this.setState({ isErrorReviewDeletion: true, reviewDeletionErrorMessage: res.statusText });
      }
    } catch (error) {
      this.setState({ isErrorReviewDeletion: true, reviewDeletionErrorMessage: 'Review was not deleted. Please try again...' });
      console.log('handleDeleteReview : ' + JSON.stringify(error));
    }
  }

  async handleAddReview() {
    if (this.state.review.length > 0) {
      this.setState({ isErrorAddReview: false, addReviewErrorMessage: '' });
      try {
        const res = await ProductService.addReview(this.state.device.slug, this.state.review);
        if (res.status === 201) {
          document.dispatchEvent(
            new CustomEvent('addNotification', {
              detail: {
                type: 'success',
                content: `The review has been added.`,
              },
            })
          );
          this.setState({ showAddReview: false });
          this.componentDidMount();
        } else {
          this.setState({ isErrorAddReview: true, addReviewErrorMessage: res.statusText });
        }
      } catch (error) {
        this.setState({ isErrorAddReview: true, addReviewErrorMessage: 'Review was not added. Please try again...' });
        console.log('handleAddReview : ' + JSON.stringify(error));
      }
    } else {
      this.setState({ isErrorAddReview: true, addReviewErrorMessage: 'Please enter a comment' });
    }
  }
}

export default createBrowserHistory();
