import { Component } from 'react';
import { cloneDeep } from 'lodash';
import CompanyService from '../../services/company.service';
import { createBrowserHistory } from 'history';
import LoadingSpinner from '../../components/LoadingSpinner';

import './company-management.css';
import { WizardHeader } from '../../components/Wizard/WizardHeader';
import { WizardFooter } from '../../components/Wizard/WizardFooter';
import { FOREGROUND_SCOPE } from '../../models/constants';
import { CompanyInformationStep } from './CompanyInformationStep';
import { CompanyStatusStep } from './CompanyStatusStep';
import { Company, SustainableDevelopment } from '../../models/company';
import { CompanyReferenceIoTStep } from './CompanyReferenceIoTStep';
import { CompanyFinanceStep } from './CompanyFinanceStep';
import { CompanyRDPolicyStep } from './CompanyRDPolicyStep';
import { CompanyDocumentStep } from './CompanyDocumentStep';
import DocumentService from '../../services/document.service';
import { AppProps, PropsMatch } from '../../models/Props';
import { CompanyESGCSRStep } from './CompanyESGCSRStep';
import ScrollToTop from '../../components/scrollToTop';

class CompanyCreateModifyProps {
  public appProps: AppProps;
  public history: any;
  public match: PropsMatch;
}

class CompanyCreateModifyState {
  isLoading: boolean;
  currentStep: number;
  company: Company;
  unmodifyCompany: Company;
  isCreation: boolean;
  isError: boolean;
  errorMessage: string;
  wizardStep: Array<string>;

  constructor() {
    this.isLoading = true;
    this.currentStep = 1;
    this.company = new Company();
    this.unmodifyCompany = null;
    this.isCreation = true;
    this.isError = false;
    this.errorMessage = '';
  }
}

enum COMPANY_WIZARD_STEP {
  COMPANY_STATUS = 'Company status',
  INFORMATION = 'Information',
  REFERENCE_IOT = 'References in Iot & Product range',
  FINANCE = 'Finance',
  RD_POLICY = 'RD policy & quality policy production',
  ESG_CSR = 'ESG/CSR or Sustainability policy',
  DOCUMENT = 'Document',
}

export class CompanyCreateModify extends Component<CompanyCreateModifyProps, CompanyCreateModifyState> {
  private childCompanyStatusStep: any;
  private childCompanyInformationStep: any;
  private childCompanyReferenceIoTStep: any;
  private childCompanyFinanceStep: any;
  private childCompanyRDPolicyStep: any;
  private childCompanyESGCSRStep: any;
  private childCompanyDocumentStep: any;

  constructor(props) {
    super(props);
    this.state = new CompanyCreateModifyState();
    this.onStepChange = this.onStepChange.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onPrevious = this.onPrevious.bind(this);
    this.onNext = this.onNext.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  async componentDidMount() {
    try {
      const id = this.props.match.params.id;
      const scope = this.props.appProps.scope;
      const wizardStep = [];
      if (!!id && id !== 'add') {
        const response = await CompanyService.companiesAllDetails(id);
        const informationI18N = response[1].data;
        let company = { ...response[0].data, informationI18N };
        if (!response[0].data.sustainableDevelopment) {
          const defEsgCSR = new SustainableDevelopment(null, null, false, '', false, false, '', '');
          company = { ...company, sustainableDevelopment: defEsgCSR };
        }

        const companyCopy = cloneDeep(company);
        if (scope === FOREGROUND_SCOPE.ORANGE_ADMIN || scope === FOREGROUND_SCOPE.ORANGE_USER) {
          wizardStep.push(COMPANY_WIZARD_STEP.COMPANY_STATUS);
          wizardStep.push(COMPANY_WIZARD_STEP.INFORMATION);
          wizardStep.push(COMPANY_WIZARD_STEP.REFERENCE_IOT);
          wizardStep.push(COMPANY_WIZARD_STEP.FINANCE);
          wizardStep.push(COMPANY_WIZARD_STEP.RD_POLICY);
          wizardStep.push(COMPANY_WIZARD_STEP.ESG_CSR);
          wizardStep.push(COMPANY_WIZARD_STEP.DOCUMENT);
        }
        if (scope === FOREGROUND_SCOPE.ORANGE_VALIDATION) {
          wizardStep.push(COMPANY_WIZARD_STEP.INFORMATION);
          wizardStep.push(COMPANY_WIZARD_STEP.REFERENCE_IOT);
          wizardStep.push(COMPANY_WIZARD_STEP.FINANCE);
          wizardStep.push(COMPANY_WIZARD_STEP.RD_POLICY);
          wizardStep.push(COMPANY_WIZARD_STEP.ESG_CSR);
          wizardStep.push(COMPANY_WIZARD_STEP.DOCUMENT);
        }
        if (scope === FOREGROUND_SCOPE.ORANGE_PARTNER || scope === FOREGROUND_SCOPE.PARTNER) {
          wizardStep.push(COMPANY_WIZARD_STEP.INFORMATION);
          wizardStep.push(COMPANY_WIZARD_STEP.REFERENCE_IOT);
          wizardStep.push(COMPANY_WIZARD_STEP.FINANCE);
          wizardStep.push(COMPANY_WIZARD_STEP.RD_POLICY);
          wizardStep.push(COMPANY_WIZARD_STEP.ESG_CSR);
          wizardStep.push(COMPANY_WIZARD_STEP.DOCUMENT);
        }
        this.setState({
          isLoading: false,
          company: company,
          unmodifyCompany: companyCopy,
          isCreation: false,
          wizardStep: wizardStep,
        });
      } else {
        wizardStep.push(COMPANY_WIZARD_STEP.INFORMATION);
        wizardStep.push(COMPANY_WIZARD_STEP.REFERENCE_IOT);
        wizardStep.push(COMPANY_WIZARD_STEP.FINANCE);
        wizardStep.push(COMPANY_WIZARD_STEP.RD_POLICY);
        wizardStep.push(COMPANY_WIZARD_STEP.ESG_CSR);
        wizardStep.push(COMPANY_WIZARD_STEP.DOCUMENT);

        this.setState({
          isLoading: false,
          wizardStep: wizardStep,
        });
      }
    } catch (error) {
      console.log(`ERROR Company : ${JSON.stringify(error)}`);
      this.setState({ isLoading: false });
    }
  }

  onStepChange(step) {
    const currentStep = this.state.currentStep;
    let allAreValid = true;
    for (let i = currentStep; i < step; i++) {
      const child = this.getNextStep(i);
      if (!!child) {
        const valide = child.validate();
        if (!valide) {
          allAreValid = false;
          this.setState({
            currentStep: i,
          });
          break;
        }
      }
    }
    if (allAreValid) {
      this.setState({
        currentStep: step,
      });
    }
  }

  onCancel() {
    if (this.state.isCreation) {
      this.props.history.push('/companies');
    } else {
      this.props.history.push(`/companies/${this.state.company.slug}`);
    }
  }

  onPrevious() {
    const currentStep = this.state.currentStep;

    this.setState({
      currentStep: currentStep - 1,
      isError: false,
    });
  }

  onNext() {
    const currentStep = this.state.currentStep;
    const child = this.getNextStep(currentStep);
    this.setState({ isError: false });
    if (!!child) {
      const valide = child.validate();
      if (valide) {
        this.setState({
          currentStep: currentStep + 1,
        });
      }
    } else {
      this.setState({
        currentStep: currentStep + 1,
      });
    }
  }

  getNextStep(currentStep) {
    const wizardName = this.state.wizardStep[currentStep - 1];
    switch (wizardName) {
      case COMPANY_WIZARD_STEP.COMPANY_STATUS:
        return this.childCompanyStatusStep;
      case COMPANY_WIZARD_STEP.INFORMATION:
        return this.childCompanyInformationStep;
      case COMPANY_WIZARD_STEP.REFERENCE_IOT:
        return this.childCompanyReferenceIoTStep;
      case COMPANY_WIZARD_STEP.FINANCE:
        return this.childCompanyFinanceStep;
      case COMPANY_WIZARD_STEP.RD_POLICY:
        return this.childCompanyRDPolicyStep;
      case COMPANY_WIZARD_STEP.ESG_CSR:
        return this.childCompanyESGCSRStep;
      case COMPANY_WIZARD_STEP.DOCUMENT:
        return this.childCompanyDocumentStep;

      default:
        return null;
    }
  }

  async onSubmit() {
    if (this.state.isCreation) {
      if (!this.childCompanyRDPolicyStep.validate()) {
        return;
      }
      try {
        const res = await CompanyService.createCompany(this.state.company);
        if (res.status === 201) {
          const otherCreation = [];
          this.state.company.informationI18N.forEach((t) => {
            if (!!t.description || !!t.jointProjectWithOrange) {
              otherCreation.push(CompanyService.createCompanyinformantionI18N(res.data.slug, t.countryCode, t));
            }
          });
          if (otherCreation.length > 0) {
            await Promise.all(otherCreation)
              .then(() => {
                this.onSuccess(res.data.slug);
              })
              .catch(() => {
                this.setState({ isError: true, errorMessage: 'Error during the request (please reload the page)' });
              });
          } else {
            this.onSuccess(res.data.slug);
          }
        } else {
          if (res.status >= 400) {
            this.setState({ isError: true, errorMessage: res.data.message });
          }
        }
      } catch (e) {
        let message = 'Error';
        this.setState({ isError: true, errorMessage: message });
      }
    } else {
      const otherUpdate = [];
      // modification of existing company
      if (!this.childCompanyDocumentStep.validate()) {
        return;
      }
      try {
        const res = await CompanyService.updateCompany(this.state.company.slug, this.state.company);
        if (res.status === 204 || res.status === 304) {

          this.state.company.informationI18N.forEach((t) => {
            const previousState = this.state.unmodifyCompany.informationI18N.find((p) => p.countryCode === t.countryCode);
            if (!!t.description || !!t.jointProjectWithOrange) {
              if (!previousState) {
                otherUpdate.push(
                  CompanyService.createCompanyinformantionI18N(this.state.company.slug, t.countryCode, t)
                );
              } else {
                if (!!t.description && (t.description !== previousState.description || t.jointProjectWithOrange !== previousState.jointProjectWithOrange)) {
                  otherUpdate.push(
                    CompanyService.updateCompanyinformantionI18N(this.state.company.slug, t.countryCode, t)
                  );
                }
              }
            }
          });

          if (!this.state.unmodifyCompany.corporateSourcingContract && this.state.company.status.id === 6) {
            otherUpdate.push(
              CompanyService.createCompanyCorporateSourcingContract(this.state.company.slug, this.state.company.corporateSourcingContract)
            );
          }else {
            if (!!this.state.company.corporateSourcingContract &&
              (JSON.stringify(this.state.company.corporateSourcingContract) !== JSON.stringify(this.state.unmodifyCompany.corporateSourcingContract))) {
              otherUpdate.push(
                CompanyService.updateCompanyCorporateSourcingContract(this.state.company.slug, this.state.company.corporateSourcingContract)
              );
            }
          }

          if (!this.state.unmodifyCompany.rdPolicy) {
              otherUpdate.push(CompanyService.createCompanyRdPolicy(this.state.company.slug, this.state.company.rdPolicy));
          } else {
            if (!!this.state.company.rdPolicy && (JSON.stringify(this.state.company.rdPolicy) !== JSON.stringify(this.state.unmodifyCompany.rdPolicy))) {
              otherUpdate.push(CompanyService.updateCompanyRdPolicy(this.state.company.slug, this.state.company.rdPolicy));
            }
          }

          if (!this.state.unmodifyCompany.customerReferences) {
            otherUpdate.push(CompanyService.createCompanyCustomerReferences(this.state.company.slug, this.state.company.customerReferences));
          } else {
            if (!!this.state.company.customerReferences && (JSON.stringify(this.state.company.customerReferences) !== JSON.stringify(this.state.unmodifyCompany.customerReferences))) {
              otherUpdate.push(CompanyService.updateCompanyCustomerReferences(this.state.company.slug, this.state.company.customerReferences));
            }
          }

          if (!this.state.unmodifyCompany.financeAndSustainability) {
            otherUpdate.push(CompanyService.createCompanyFinanceAndSustainability(this.state.company.slug, this.state.company.financeAndSustainability));
          } else {
            if (!!this.state.company.financeAndSustainability && (JSON.stringify(this.state.company.financeAndSustainability) !== JSON.stringify(this.state.unmodifyCompany.financeAndSustainability))) {
              otherUpdate.push(CompanyService.updateCompanyFinanceAndSustainability(this.state.company.slug, this.state.company.financeAndSustainability));

            }
          }

          if (!this.state.unmodifyCompany.qualityPolicyProduction) {
            otherUpdate.push(CompanyService.createCompanyQualityPolicyProduction(this.state.company.slug, this.state.company.qualityPolicyProduction));
          } else {
            if (!!this.state.company.qualityPolicyProduction && (JSON.stringify(this.state.company.qualityPolicyProduction) !== JSON.stringify(this.state.unmodifyCompany.qualityPolicyProduction))) {
              otherUpdate.push(CompanyService.updateCompanyQualityPolicyProduction(this.state.company.slug, this.state.company.qualityPolicyProduction));
            }
          }

          if (!!this.state.company.documentsToUpdate) {
            this.state.company.documentsToUpdate.forEach((d) => {
              otherUpdate.push(DocumentService.updateDocument(d.id, d));
            });
          }

          if (!!this.state.company.documentsToDelete) {
            this.state.company.documentsToDelete.forEach((id) => {
              otherUpdate.push(DocumentService.deleteDocument(id));
            });
          }

          if (!!this.state.company.documentsToAdd) {
            otherUpdate.push(DocumentService.createCompanyDocument(this.state.company.slug, this.state.company.documentsToAdd));
          }
          if (otherUpdate.length > 0) {
            await Promise.all(otherUpdate)
              .then(() => {
                this.onSuccess(this.state.company.slug);
              })
              .catch(() => {
                this.setState({ isError: true, errorMessage: 'Error during the needed requests for the update (please reload the page)' });
              });
          }else {
            this.onSuccess(this.state.company.slug);
          }

        } else if (res.status >= 400) {
          this.setState({ isError: true, errorMessage: res.data.message });
        }
      } catch (e) {
        let message = 'Error (company modification impossible)';
        this.setState({ isError: true, errorMessage: message });
      }
    }
  }

  onSuccess(companySlug) {
    const contentEnd = this.state.isCreation ? ' has been created.' : ' has been updated.';
    document.dispatchEvent(
      new CustomEvent('addNotification', {
        detail: {
          type: 'success',
          content: `The company ${this.state.company.name}${contentEnd}`,
        },
      })
    );
    const scope = this.props.appProps.scope;
    if (scope === FOREGROUND_SCOPE.PARTNER || scope === FOREGROUND_SCOPE.ORANGE_PARTNER) {
      this.props.history.go(0);
    } else {
      this.props.history.push(`/companies/${companySlug}`);
    }
  }

  render() {
    const scope = this.props.appProps.scope;
    const isLoading = this.state.isLoading;

    const isCreation = this.state.isCreation;

    const canCancel = scope !== FOREGROUND_SCOPE.PARTNER && scope !== FOREGROUND_SCOPE.ORANGE_PARTNER;
    const isReadOnly = scope === FOREGROUND_SCOPE.ORANGE_USER || scope === FOREGROUND_SCOPE.ORANGE_VALIDATION;

    const currentStep = this.state.currentStep;

    const scopeIsOrangeValidationOrPartnerOrOrangePartner =
      scope === FOREGROUND_SCOPE.ORANGE_VALIDATION || scope === FOREGROUND_SCOPE.PARTNER || scope === FOREGROUND_SCOPE.ORANGE_PARTNER;

    const displayCompanyStatus =
      !isLoading && currentStep === 1 && !isCreation && (scope === FOREGROUND_SCOPE.ORANGE_ADMIN || scope === FOREGROUND_SCOPE.ORANGE_USER);

    const displayCompanyInformation =
      !isLoading &&
      ((currentStep === 1 && isCreation) ||
        (!isCreation && (scope === FOREGROUND_SCOPE.ORANGE_ADMIN || scope === FOREGROUND_SCOPE.ORANGE_USER) && currentStep === 2) ||
        (scopeIsOrangeValidationOrPartnerOrOrangePartner && currentStep === 1));

    const displayCompanyReferenceIoT =
      !isLoading &&
      ((currentStep === 2 && isCreation) ||
        (!isCreation && (scope === FOREGROUND_SCOPE.ORANGE_ADMIN || scope === FOREGROUND_SCOPE.ORANGE_USER) && currentStep === 3) ||
        (scopeIsOrangeValidationOrPartnerOrOrangePartner && currentStep === 2));

    const displayCompanyFinance =
      !isLoading &&
      ((currentStep === 3 && isCreation) ||
        (!isCreation && (scope === FOREGROUND_SCOPE.ORANGE_ADMIN || scope === FOREGROUND_SCOPE.ORANGE_USER) && currentStep === 4) ||
        (scopeIsOrangeValidationOrPartnerOrOrangePartner && currentStep === 3));

    const displayCompanyRDPolicy =
      !isLoading &&
      ((currentStep === 4 && isCreation) ||
        (!isCreation && (scope === FOREGROUND_SCOPE.ORANGE_ADMIN || scope === FOREGROUND_SCOPE.ORANGE_USER) && currentStep === 5) ||
        (scopeIsOrangeValidationOrPartnerOrOrangePartner && currentStep === 4));

    const displayCompanyESGCSR =
      !isLoading &&
      ((currentStep === 5 && isCreation) ||
        (!isCreation && (scope === FOREGROUND_SCOPE.ORANGE_ADMIN || scope === FOREGROUND_SCOPE.ORANGE_USER) && currentStep === 6) ||
        (scopeIsOrangeValidationOrPartnerOrOrangePartner && currentStep === 5));

    const displayCompanyDocument =
      !isLoading &&
      ((currentStep === 6 && isCreation) ||
        (!isCreation && (scope === FOREGROUND_SCOPE.ORANGE_ADMIN || scope === FOREGROUND_SCOPE.ORANGE_USER) && currentStep === 7) ||
        (scopeIsOrangeValidationOrPartnerOrOrangePartner && currentStep === 6));
    const canGoForward = !isCreation;

    document.title = isCreation ? 'Add Partner - Foreground V3':'Modify Partner - Foreground V3';
    return (
      <div className="p-3 bg-white">
        <h1 id="partner-wizard-title" className="display-2 mt-3">
          {!this.state.isCreation ? this.state.company.name : 'Add Partner'}
        </h1>
        {this.state.isError ? (
          <p id="error-expiration-nda" className="errorMessage mt-2">
            {this.state.errorMessage}
          </p>
        ) : null}
        {this.state.isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            <div style={{ marginTop: '20px' }}></div>
            <WizardHeader
              currentStep={currentStep}
              stepLabels={this.state.wizardStep}
              canGoForward={canGoForward}
              onStepChange={this.onStepChange}
            ></WizardHeader>

            <div style={{ marginTop: '20px', marginBottom: '20px', height: 'auto' }}>
              <ScrollToTop/>
              <CompanyStatusStep
                company={this.state.company}
                isReadOnly={isReadOnly}
                ref={(instance) => {
                  this.childCompanyStatusStep = instance;
                }}
                scope={this.props.appProps.scope}
                shouldDisplay={displayCompanyStatus}
              ></CompanyStatusStep>

              <CompanyInformationStep
                company={this.state.company}
                isReadOnly={isReadOnly}
                ref={(instance) => {
                  this.childCompanyInformationStep = instance;
                }}
                scope={this.props.appProps.scope}
                shouldDisplay={displayCompanyInformation}
              ></CompanyInformationStep>

              <CompanyReferenceIoTStep
                company={this.state.company}
                isReadOnly={isReadOnly}
                ref={(instance) => {
                  this.childCompanyReferenceIoTStep = instance;
                }}
                scope={this.props.appProps.scope}
                shouldDisplay={displayCompanyReferenceIoT}
              ></CompanyReferenceIoTStep>

              <CompanyFinanceStep
                company={this.state.company}
                isReadOnly={isReadOnly}
                ref={(instance) => {
                  this.childCompanyFinanceStep = instance;
                }}
                scope={this.props.appProps.scope}
                shouldDisplay={displayCompanyFinance}
              ></CompanyFinanceStep>

              <CompanyRDPolicyStep
                company={this.state.company}
                isReadOnly={isReadOnly}
                ref={(instance) => {
                  this.childCompanyRDPolicyStep = instance;
                }}
                scope={this.props.appProps.scope}
                shouldDisplay={displayCompanyRDPolicy}
              ></CompanyRDPolicyStep>

              <CompanyESGCSRStep
                company={this.state.company}
                isReadOnly={isReadOnly}
                ref={(instance) => {
                  this.childCompanyESGCSRStep = instance;
                }}
                scope={this.props.appProps.scope}
                shouldDisplay={displayCompanyESGCSR}
              ></CompanyESGCSRStep>

              <CompanyDocumentStep
                company={this.state.company}
                isReadOnly={isReadOnly}
                ref={(instance) => {
                  this.childCompanyDocumentStep = instance;
                }}
                scope={this.props.appProps.scope}
                shouldDisplay={displayCompanyDocument}
              ></CompanyDocumentStep>
            </div>

            <WizardFooter
              currentStep={currentStep}
              nbMaxItem={!!this.state.wizardStep ? this.state.wizardStep.length : 0}
              canCancel={canCancel}
              actionLabel={isCreation ? 'Create' : 'Save'}
              onCancel={this.onCancel}
              onPrevious={this.onPrevious}
              onNext={this.onNext}
              onSubmit={this.onSubmit}
              isReadOnly={isReadOnly}
            ></WizardFooter>
          </>
        )}
      </div>
    );
  }
}

export default createBrowserHistory();
