import Utils from '../helpers/Utils';
import instance from '../helpers/InterceptorAxios';
import { cloneDeep } from 'lodash';
import {
    DeviceInformationI18n,
    Device,
    DeviceVersionToAdd,
    DeviceVersionToUpdate,
    DeviceTechnology,
    DeviceTechnologyImage,
} from '../models/device';
import { ForegroundImage, ForegroundImageToUpdate } from '../models/image';
import { FOREGROUND_SCOPE } from '../models/constants';
import { ForegroundDocument } from '../models/document';
import { ForegroundOption } from '../components/ForegroundSelect';

export default class ProductService {
    /**
     * List products
     * @returns {Promise<AxiosResponse<T>>}
     */
    static async productsList(
        offset: number,
        limit: number,
        productsStatus: string,
        productsTypes: string,
        productsCompanies: string,
        productsNetworkInterface: string,
        productsVerticals: string,
        productsUsescases: string,
        orderBy,
        deviceName: string,
        companyName: string,
    ) {
        const params = {};
        if (!!offset) {
            params['offset'] = offset;
        }
        if (!!limit) {
            params['limit'] = limit;
        }
        if (!!productsStatus) {
            params['deviceStatus'] = productsStatus;
        }
        if (!!productsTypes) {
            params['deviceTypes'] = productsTypes;
        }
        if (!!productsCompanies) {
            params['deviceCompanies'] = productsCompanies;
        }
        if (!!productsNetworkInterface) {
            params['deviceNetworkInterfaces'] = productsNetworkInterface;
        }
        if (!!productsVerticals) {
            params['deviceVerticals'] = productsVerticals;
        }
        if (!!productsUsescases) {
            params['deviceUseCases'] = productsUsescases;
        }
        if (orderBy != null) {
            params['orderBy'] = orderBy;
            params['sortOrder'] = orderBy === 'id' ? 'ASC' : 'DESC';
        }

        if (!!deviceName && deviceName !== '') {
            params['deviceName'] = deviceName;
        }
        if (!!companyName && companyName !== '') {
            params['companyName'] = companyName;
        }
        return await instance.get(`api/v1/devices`, { params: params, headers: Utils.getAuthHeader() });
    }

    static async productsCount() {
        return await instance.get(`/api/v1/devicesCount`, { headers: Utils.getAuthHeader() });
    }

    static async deviceProductsLivesCycles(){
        return await instance.get(`/api/v1/devicesProductLivesCycles`,{ headers: Utils.getAuthHeader() });
    }
    static async deviceDetails(deviceSlug: string) {
        return await instance.get(`api/v1/devices/${deviceSlug}`, { headers: Utils.getAuthHeader() });
    }

    static async solutionDetails(deviceSlug: string) {
        return await instance.get(`api/v1/solutions/${deviceSlug}`, { headers: Utils.getAuthHeader() });
    }

    static async deviceAllDetails(deviceSlug: string) {
        return Promise.all([
            instance.get(`api/v1/devices/${deviceSlug}`, { headers: Utils.getAuthHeader() }),
            instance.get(`api/v1/devices/${deviceSlug}/informationI18N`, { headers: Utils.getAuthHeader() }),
        ]);
    }

    static async deviceTypes() {
        return await instance.get(`api/v1/deviceTypes`, { headers: Utils.getAuthHeader() });
    }

    static async deviceVerticals() {
        return await instance.get(`api/v1/verticals`, { headers: Utils.getAuthHeader() });
    }

    static async deviceUseCases() {
        return await instance.get(`api/v1/deviceUseCases`, { headers: Utils.getAuthHeader() });
    }

    static async deviceBatteryLifes() {
        return await instance.get(`api/v1/deviceBatteryLifes`, { headers: Utils.getAuthHeader() });
    }

    /**
     * List maturity
     * @returns {Promise<AxiosResponse<T>>}
     */

    static async maturitiesList() {
        return await instance.get(`api/v1/deviceMaturities`, { headers: Utils.getAuthHeader() });
    }

    /**
     * List regulations compliancy
     * @returns {Promise<AxiosResponse<T>>}
     */
    static async regulationComplianciesList() {
        return await instance.get(`api/v1/regulationCompliancies`, { headers: Utils.getAuthHeader() });
    }

    /**
     * List module maker
     * @returns {Promise<AxiosResponse<T>>}
     */
    static async modulesMakersList() {
        return await instance.get(`api/v1/moduleMakers`, { headers: Utils.getAuthHeader() });
    }

    /**
     * List modem maker
     * @returns {Promise<AxiosResponse<T>>}
     */
    static async modemsMakersList() {
        return await instance.get(`api/v1/modemMakers`, { headers: Utils.getAuthHeader() });
    }

    /**
     * List cpu maker
     * @returns {Promise<AxiosResponse<T>>}
     */
    static async cpusMakersList() {
        return await instance.get(`api/v1/cpuMakers`, { headers: Utils.getAuthHeader() });
    }

    /**
     * List network interfaces
     * @returns {Promise<AxiosResponse<T>>}
     */
    static async networkInterfacesList() {
        return await instance.get(`api/v1/networkInterfaces`, { headers: Utils.getAuthHeader() });
    }

    /**
     * List IP Norms
     * @returns {Promise<AxiosResponse<T>>}
     */
    static async deviceIPNormsList() {
        return await instance.get(`api/v1/deviceIPNorms`, { headers: Utils.getAuthHeader() });
    }

    /**
     * List IOT Protocols
     * @returns {Promise<AxiosResponse<T>>}
     */
    static async deviceIOTProtocolsList() {
        return await instance.get(`api/v1/deviceIOTProtocols`, { headers: Utils.getAuthHeader() });
    }

    /**
     * List Frontend
     * @returns {Promise<AxiosResponse<T>>}
     */
    static async deviceFrontendsList() {
        return await instance.get(`api/v1/deviceFrontends`, { headers: Utils.getAuthHeader() });
    }

    static async createDeviceinformantionI18N(deviceSlug: string, countryCode: string, data: DeviceInformationI18n) {
        return await instance.post(`api/v1/devices/${deviceSlug}/informationI18N/countries/${countryCode}`, data, {
            headers: Utils.getAuthHeader(),
        });
    }

    static async updateDeviceinformantionI18N(deviceSlug: string, countryCode: string, data: DeviceInformationI18n) {
        return await instance.put(`api/v1/devices/${deviceSlug}/informationI18N/countries/${countryCode}`, data, {
            headers: Utils.getAuthHeader(),
        });
    }

    static async updatedevice(companySlug: string, deviceSlug: string, device: Device, scope: FOREGROUND_SCOPE) {
        const deviceToUpdate = cloneDeep(device);

        if (!!deviceToUpdate.documentsToAdd) {
            delete deviceToUpdate.documentsToAdd;
        }
        if (!!deviceToUpdate.documentsToUpdate) {
            delete deviceToUpdate.documentsToUpdate;
        }
        if (!!deviceToUpdate.documentsToDelete) {
            delete deviceToUpdate.documentsToDelete;
        }
        if (!!deviceToUpdate.imagesToAdd) {
            delete deviceToUpdate.imagesToAdd;
        }
        if (!!deviceToUpdate.imagesToUpdate) {
            delete deviceToUpdate.imagesToUpdate;
        }
        if (!!deviceToUpdate.imagesToDelete) {
            delete deviceToUpdate.imagesToDelete;
        }

        if (!!deviceToUpdate.images) {
            delete deviceToUpdate.images;
        }

        if (!!deviceToUpdate.id) {
            delete deviceToUpdate.id;
        }
        if (!!deviceToUpdate.slug) {
            delete deviceToUpdate.slug;
        }
        if (!!deviceToUpdate.createdAt) {
            delete deviceToUpdate.createdAt;
        }
        if (!!deviceToUpdate.updatedAt) {
            delete deviceToUpdate.updatedAt;
        }

        if (!!deviceToUpdate.modemMaker) {
            deviceToUpdate.otherModemMaker = null;
        } else {
            if (!!deviceToUpdate.otherModemMaker) {
                deviceToUpdate.modemMaker = null;
            }
        }

        if (!!deviceToUpdate.cpuMaker) {
            deviceToUpdate.otherCpuMaker = null;
        } else {
            if (!!deviceToUpdate.otherCpuMaker) {
                deviceToUpdate.cpuMaker = null;
            }
        }

        if (!!deviceToUpdate.moduleMaker) {
            deviceToUpdate.otherModuleMaker = null;
        } else {
            if (!!deviceToUpdate.otherModuleMaker) {
                deviceToUpdate.moduleMaker = null;
            }
        }

        if (!!deviceToUpdate.documents) {
            delete deviceToUpdate.documents;
        }

        if (!!deviceToUpdate.versionsToAdd) {
            delete deviceToUpdate.versionsToAdd;
        }
        if (!!deviceToUpdate.versionsToUpdate) {
            delete deviceToUpdate.versionsToUpdate;
        }
        if (!!deviceToUpdate.versionsToDelete) {
            delete deviceToUpdate.versionsToDelete;
        }
        if (!!deviceToUpdate.versions) {
            delete deviceToUpdate.versions;
        }
        if (!!deviceToUpdate.technologies) {
            delete deviceToUpdate.technologies;
        }
        if (scope !== FOREGROUND_SCOPE.ORANGE_ADMIN) {
            delete deviceToUpdate.iotMarketplace;
        }

        if (!!deviceToUpdate.esgCsrSustainability) {
            delete deviceToUpdate.esgCsrSustainability.id;
            delete deviceToUpdate.esgCsrSustainability.deviceId;
        }

        return await instance.put(`api/v1/companies/${companySlug}/devices/${deviceSlug}`, deviceToUpdate, { headers: Utils.getAuthHeader() });
    }

    static async createDevice(companySlug: string, device: Device) {
        const deviceToCreate = cloneDeep(device) as Device;

        if (!!deviceToCreate.modemMaker) {
            delete deviceToCreate.otherModemMaker;
        } else {
            if (!!deviceToCreate.otherModemMaker) {
                delete deviceToCreate.modemMaker;
            }
        }

        if (!!deviceToCreate.cpuMaker) {
            delete deviceToCreate.otherCpuMaker;
        } else {
            if (!!deviceToCreate.otherCpuMaker) {
                delete deviceToCreate.cpuMaker;
            }
        }

        if (!!deviceToCreate.moduleMaker) {
            delete deviceToCreate.otherModuleMaker;
        } else {
            if (!!deviceToCreate.otherModuleMaker) {
                delete deviceToCreate.moduleMaker;
            }
        }

        if (!!deviceToCreate.documentsToAdd) {
            deviceToCreate.documents.push(
                new ForegroundDocument(
                    deviceToCreate.documentsToAdd.fileName,
                    deviceToCreate.documentsToAdd.fileContent,
                    new ForegroundOption(deviceToCreate.documentsToAdd.type.label, deviceToCreate.documentsToAdd.type.id),
                ),
            );
            delete deviceToCreate.documentsToAdd;
        }

        if (deviceToCreate.documents.length === 0) {
            delete deviceToCreate.documents;
        }

        if (!!deviceToCreate.documentsToUpdate) {
            delete deviceToCreate.documentsToUpdate;
        }
        if (!!deviceToCreate.documentsToDelete) {
            delete deviceToCreate.documentsToDelete;
        }

        if (!!deviceToCreate.imagesToAdd) {
            delete deviceToCreate.imagesToAdd;
        }
        if (!!deviceToCreate.imagesToUpdate) {
            delete deviceToCreate.imagesToUpdate;
        }
        if (!!deviceToCreate.imagesToDelete) {
            delete deviceToCreate.imagesToDelete;
        }

        delete deviceToCreate.iotMarketplace;
        return await instance.post(`api/v1/companies/${companySlug}/devices`, deviceToCreate, { headers: Utils.getAuthHeader() });
    }

    static async addReview(deviceSlug: string, review: string) {
        return await instance.post(`/api/v1/devices/${deviceSlug}/reviews`, { review: review }, { headers: Utils.getAuthHeader() });
    }

    static async deleteReview(deviceSlug: string, id: number) {
        return await instance.delete(`/api/v1/devices/${deviceSlug}/reviews/${id}`, { headers: Utils.getAuthHeader() });
    }

    static async addImage(deviceSlug: string, image: ForegroundImage) {
        return await instance.post(`/api/v1/devices/${deviceSlug}/images`, image, { headers: Utils.getAuthHeader() });
    }

    static async modifyImage(image: ForegroundImageToUpdate) {
        return await instance.patch(`/api/v1/images/${image.id}`, { isReference: image.isReference }, { headers: Utils.getAuthHeader() });
    }

    static async deleteImage(imageId: number) {
        return await instance.delete(`/api/v1/images/${imageId}`, { headers: Utils.getAuthHeader() });
    }

    static async addVersion(deviceSlug: string, version: DeviceVersionToAdd) {
        if (!!version.newVersion) {
            delete version.newVersion;
        }
        return await instance.post(`/api/v1/devices/${deviceSlug}/versions`, version, { headers: Utils.getAuthHeader() });
    }

    static async modifyVersion(deviceSlug: string, versionId: string, version: DeviceVersionToUpdate) {
        return await instance.patch(`/api/v1/devices/${deviceSlug}/versions/${versionId}`, version, { headers: Utils.getAuthHeader() });
    }

    static async deleteVersion(deviceSlug: string, versionId: string) {
        return await instance.delete(`/api/v1/devices/${deviceSlug}/versions/${versionId}`, { headers: Utils.getAuthHeader() });
    }

    static async deleteDevice(deviceSlug: string) {
        return await instance.delete(`/api/v1/devices/${deviceSlug}`, { headers: Utils.getAuthHeader() });
    }

    /**
     * Technologies
     * @returns {Promise<AxiosResponse<T>>}
     */
    static async getDeviceTechnologies() {
        return await instance.get(`api/v1/deviceTechnologies`, { headers: Utils.getAuthHeader() });
    }

    static async addTechnology(techno: DeviceTechnology) {
        return await instance.post(`/api/v1/deviceTechnologies`, techno, { headers: Utils.getAuthHeader() });
    }

    static async deleteTechnology(technologyId: number) {
        return await instance.delete(`/api/v1/deviceTechnologies/${technologyId}`, { headers: Utils.getAuthHeader() });
    }

    static async updateTechnologyOrder(technologyId: number, newOrder: number) {
        return await instance.patch(`/api/v1/deviceTechnologies/${technologyId}`, { order: newOrder }, { headers: Utils.getAuthHeader() });
    }

    static async updateTechnology(techno: DeviceTechnology) {
        const technologyId: number = techno.id;
        let image: DeviceTechnologyImage;
        if (techno.image.name) {
            image = new DeviceTechnologyImage(techno.image.fileContent, techno.image.name);
        }
        let newTechno: DeviceTechnology = new DeviceTechnology(null, techno.label, image);
        return await instance.patch(`/api/v1/deviceTechnologies/${technologyId}`, newTechno, { headers: Utils.getAuthHeader() });
    }
}
