import React, { Component } from 'react';
import { ManageTranslation } from '../../components/ManageTranslation/ManageTranslation';
import {
  BAD_WEB_URL,
  EMPTY_DESCRIPTION,
  FOREGROUND_SCOPE,
  MANDATORY_FIELD_MISSING,
  ORANGE_ADMIN_SCOPE,
  PLEASE_SELECT_COMPANY,
  PLEASE_SELECT_DESCRIPTION_EN,
  PLEASE_SELECT_DEVICE_TYPE,
  PLEASE_SELECT_STATUS_PRODUCTION,
  WEB_URL_REGEX,
} from '../../models/constants';
import './product_management.css';
import { ImageLoader } from '../../components/ImageLoader';
import ProductService from '../../services/product.service';
import { DeviceImage } from './DeviceImage';
import { ForegroundOption, ForegroundSelectSingle } from '../../components/ForegroundSelect';
import { ForegroundImage, ForegroundImageToUpdate } from '../../models/image';
import { DeviceWizardStepProps } from './DeviceWizard';
import { DeviceInformationI18n, DeviceProductLiveCycle, DeviceTypes } from '../../models/device';
import { BackToTop } from '../../components/backToTopButton';
import { Company } from '../../models/company';
import CompanyService from '../../services/company.service';
import LoadingSpinner from '../../components/LoadingSpinner';

class DeviceInformationStepState {
  currentCompany: ForegroundOption;
  companyListOptions: Array<ForegroundOption>;
  companiesListResponse: Array<Company>;
  errorMessage: string;
  isErrorMessage: boolean;
  isErrorOnDeviceName: boolean;
  isErrorOnDeviceType: boolean;
  isErrorOnDeviceProductLifeCycle: boolean;
  isErrorOnDeviceWebsite: boolean;
  isErrorOnDeviceWebsiteFr: boolean;
  isErrorOnDescription: boolean;
  isErrorOnCompany: boolean;
  isErrorOnImage: boolean;
  noImageAsReference: boolean;
  deviceProductLifeCycle: Array<DeviceProductLiveCycle>
  productsLivesCyclesOptions: Array<ForegroundOption>;
  currentProductLifeCycle: ForegroundOption;
  deviceTypes: Array<DeviceTypes>;
  deviceTypesOptions: Array<ForegroundOption>;
  currentDeviceType: ForegroundOption;
  images: Array<ForegroundImage>;
  isLoading: boolean;

  constructor() {
    this.currentCompany = null;
    this.companyListOptions = [];
    this.companiesListResponse = [];
    this.errorMessage = '';
    this.isErrorMessage = false;
    this.isErrorOnDeviceName = false;
    this.isErrorOnDeviceType = false;
    this.isErrorOnDeviceProductLifeCycle = false;
    this.isErrorOnDeviceWebsite = false;
    this.isErrorOnDeviceWebsiteFr = false;
    this.isErrorOnDescription = false;
    this.isErrorOnCompany = false;
    this.isErrorOnImage = false;
    this.noImageAsReference = false;
    this.deviceProductLifeCycle = [];
    this.productsLivesCyclesOptions = [];
    this.currentProductLifeCycle = null;
    this.deviceTypes = [];
    this.deviceTypesOptions = [];
    this.currentDeviceType = null;
    this.images = [];
    this.isLoading = true;
  }
}

export class DeviceInformationStep extends Component<DeviceWizardStepProps, DeviceInformationStepState> {
  constructor(props) {
    super(props);
    this.state = new DeviceInformationStepState();

    this.handleImageChanged = this.handleImageChanged.bind(this);
    this.handleChangeDeviceType = this.handleChangeDeviceType.bind(this);
    this.handleChangeStatusProductLifeCycle = this.handleChangeStatusProductLifeCycle.bind(this);
    this.handleChangeImageReference = this.handleChangeImageReference.bind(this);
    this.handleDeleteImage = this.handleDeleteImage.bind(this);
    this.handleTranslateValueChanged = this.handleTranslateValueChanged.bind(this);
    this.handleWebsiteFrChanged = this.handleWebsiteFrChanged.bind(this);
  }

  async componentDidMount() {
    const isCreation = !!!this.props.device.id;
    const responseProductsLivesCycles = await ProductService.deviceProductsLivesCycles();
    const deviceProductsLivesCycles = responseProductsLivesCycles.data
      .map((item: { label: string; id: number; }) => {
        return new ForegroundOption(item.label, item.id);
      });
    const deviceProductLifeCycle = !!this.props.device.productLifeCycle ? responseProductsLivesCycles.data.find((a) => a.id === this.props.device.productLifeCycle.id): null;

    const responseTypes = await ProductService.deviceTypes();
    const deviceType = !!this.props.device.type ? responseTypes.data.find((a) => a.id === this.props.device.type.id) : null;
    const deviceTypes = responseTypes.data
      .filter((item: { id: number; }) => item.id !== 6 && item.id !== 7)
      .map((item: { label: string; id: any; }) => {
        return new ForegroundOption(item.label, item.id);
      });

    const isErrorOnDeviceType = this.props.isReadOnly ? false : !isCreation && deviceType == null;
    const isErrorOnDeviceWebsite = this.props.isReadOnly
      ? false
      : !isCreation && (this.props.device.website == null || this.props.device.website === '');

    const isError = isErrorOnDeviceType || isErrorOnDeviceWebsite;
    const errorMessage = isError ? MANDATORY_FIELD_MISSING : '';

    const response = this.props.scope === ORANGE_ADMIN_SCOPE ? await CompanyService.companiesList(null, null, null, null, null, null, null, null): null;
    const companiesList = !!response ? response.data.map((item: { name: string; id: number }) => {
      return new ForegroundOption(item.name, item.id);
    }): null;
    const currentCompany = !!this.props.device.company && !!companiesList ? companiesList.find((a: ForegroundOption) => a.label === this.props.device.company.name) : null;
    const isErrorOnCompany = this.props.isReadOnly && this.props.scope !== FOREGROUND_SCOPE.ORANGE_ADMIN ? false : !isCreation && currentCompany==null;
    this.setState({
      companiesListResponse: !!response ? response.data : null,
      currentCompany: currentCompany,
      companyListOptions: companiesList,
      deviceTypes: responseTypes.data,
      deviceTypesOptions: deviceTypes,
      currentDeviceType: !!deviceType ? new ForegroundOption(deviceType.label, deviceType.id) : null,
      isErrorOnDeviceType: isErrorOnDeviceType,
      deviceProductLifeCycle: responseProductsLivesCycles.data,
      productsLivesCyclesOptions: deviceProductsLivesCycles,
      // in new creation Device availability Status must be in Production default
      currentProductLifeCycle: !!deviceProductLifeCycle ? new ForegroundOption(deviceProductLifeCycle.label, deviceProductLifeCycle.id) : responseProductsLivesCycles.data[1],
      isErrorOnDeviceWebsite: isErrorOnDeviceWebsite,
      isErrorOnCompany: isErrorOnCompany,
      errorMessage: errorMessage,
      images: this.props.device.images,
      isLoading: false,
    });
  }

  validate() {
    if (this.props.isReadOnly) {
      return true;
    }

    const deviceName = this.props.device.name;
    if (!!!deviceName || deviceName.trim().length === 0) {
      this.setState({ isErrorOnDeviceName: true, errorMessage: MANDATORY_FIELD_MISSING });
      return false;
    }

    if (this.state.currentDeviceType == null) {
      this.setState({ isErrorOnDeviceType: true, errorMessage: PLEASE_SELECT_DEVICE_TYPE });
      return false;
    }
    const website = this.props.device.website;
    if (!!!website || website.trim().length === 0) {
      this.setState({ isErrorOnDeviceWebsite: true, errorMessage: MANDATORY_FIELD_MISSING });
      return false;
    }
    if (this.state.currentProductLifeCycle == null) {
      this.setState({ isErrorOnDeviceProductLifeCycle: true, errorMessage: PLEASE_SELECT_STATUS_PRODUCTION });
      return false;
    }

    const description = this.props.device.description;
    if (!!!description || description.trim().length === 0 || description.trim() === EMPTY_DESCRIPTION) {
      this.setState({ isErrorOnDescription: true, errorMessage: PLEASE_SELECT_DESCRIPTION_EN });
      return false;
    }

    if (this.props.device.images.length === 0) {
      this.setState({ isErrorOnImage: true });
      return false;
    }

    const idx = this.props.device.images.findIndex((i) => i.isReference);
    if (idx === -1) {
      this.setState({ noImageAsReference: true });
      return false;
    }

    if (this.state.currentCompany == null && this.props.scope === FOREGROUND_SCOPE.ORANGE_ADMIN) {
      this.setState({ isErrorOnCompany: true });
      return false;
    }
    return true;
  }

  handleImageChanged(fileContent: string, fileName: string) {
    if (!!!this.props.device.imagesToAdd) {
      this.props.device.imagesToAdd = [];
    }
    const DevicePictureLength = this.props.device.images.length;

    this.props.device.images = [];
    this.props.device.images.push(new ForegroundImage(fileName, fileContent));
    this.props.device.imagesToAdd.push(new ForegroundImage(fileName, fileContent));

    // for device created, without any associated picture => abnormal condition
    if (DevicePictureLength < 1 && !!this.props.device.slug){
      this.props.device.imagesToAdd[0].isReference = false;
    }
  }

  handleChangeDeviceType(event: ForegroundOption) {
    this.props.device.type = !!this.state.deviceTypes && Array.isArray(this.state.deviceTypes) ?
      this.state.deviceTypes.find((a) => a.id === event.value)
      : null;
    this.setState({ currentDeviceType: event, isErrorOnDeviceType: this.props.device.type == null });
  }

  handleChangeStatusProductLifeCycle(event: ForegroundOption) {
    this.props.device.productLifeCycle = !!this.state.deviceProductLifeCycle && Array.isArray(this.state.deviceProductLifeCycle) ?
      this.state.deviceProductLifeCycle.find((a) => a.id === event.value): null;
    this.setState({ currentProductLifeCycle: event, isErrorOnDeviceProductLifeCycle: false });
  }

  handleChangeImageReference(id: number) {
    if (!!!this.props.device.imagesToAdd) {
      this.props.device.imagesToUpdate = [];
    }

    this.props.device.images.forEach((i) => {
      if (i.id === id) {
        i.isReference = true;
        this.props.device.imagesToUpdate.push(new ForegroundImageToUpdate(i.id, true));
      } else {
        if (i.isReference) {
          i.isReference = false;
          this.props.device.imagesToUpdate.push(new ForegroundImageToUpdate(i.id, false));
        }
      }
    });
    this.setState({ images: this.props.device.images });
  }

  handleDeleteImage(id: number) {
    if (!!!this.props.device.imagesToDelete) {
      this.props.device.imagesToDelete = [];
    }
    this.props.device.imagesToDelete.push(id);
    const imageIdx = this.props.device.images.findIndex((v) => v.id === id);
    if (imageIdx !== -1) {
      this.props.device.images.splice(imageIdx, 1);
    }
    if (this.props.device.images.length === 1) {
      if (!!!this.props.device.imagesToAdd) {
        this.props.device.imagesToUpdate = [];
      }
      const image = this.props.device.images[0];
      image.isReference = true;
      this.props.device.imagesToUpdate.push(new ForegroundImageToUpdate(image.id, true));
    }
    this.setState({ images: this.props.device.images });
  }

  handleTranslateValueChanged() {
    if (this.props.device.description.trim().length !== 0 && this.props.device.description.trim() !== EMPTY_DESCRIPTION) {
      this.setState({ isErrorOnDescription: false });
    } else {
      this.setState({ isErrorOnDescription: true, errorMessage: 'Please fill english description' });
    }
  }

  handleWebsiteFrChanged(e) {
    const infoFr = this.props.device.informationI18N.find((t) => t.countryCode === 'FR');
    if (!!infoFr) {
      infoFr.website = e.target.value;
    } else {
      this.props.device.informationI18N.push(new DeviceInformationI18n(null, 'FR', null, null, e.target.value));
    }
    if (WEB_URL_REGEX.test(e.target.value.trim())) {
      this.setState({ isErrorOnDeviceWebsiteFr: false });
    } else {
      this.setState({ isErrorOnDeviceWebsiteFr: true, errorMessage: BAD_WEB_URL });
    }
  }

  handleChangeCompany(event: ForegroundOption) {
    if (this.props.scope === ORANGE_ADMIN_SCOPE ) {
      this.props.device.company = !!this.state.companiesListResponse && Array.isArray(this.state.companiesListResponse) ?
         this.state.companiesListResponse.find((a) => a.id === event.value): null;
        this.setState({ currentCompany: event, isErrorOnCompany: false });
    }
  }

  render() {
    if (this.props.shouldDisplay) {
      const baseUrl = process.env.REACT_APP_BASE_URL_API;
      const currentCompany = this.state.currentCompany;
      const companyListOptions = this.state.companyListOptions;
      const isCreation = !!!this.props.device.id;
      const currentProductLifeCycle = !!!this.state.currentProductLifeCycle? this.state.productsLivesCyclesOptions[1]: this.state.currentProductLifeCycle;
      const productLifeCycle =this.state.productsLivesCyclesOptions;
      const image = !!this.props.device.images ? this.props.device.images.find((i) => i.isReference) : null;
      const otherImages = !!this.props.device.images ? this.props.device.images.filter((i) => !i.isReference) : [];
      const currentDeviceType = this.state.currentDeviceType;
      const infoFr = this.props.device.informationI18N.find((t) => t.countryCode === 'FR');
      const websiteFr = !!infoFr ? infoFr.website : null;
      const deviceTypes = this.state.deviceTypesOptions;

      return (
        <>
          {this.state.isLoading ? <LoadingSpinner /> :
            <>
            <div className='mb-3 mt-4'>All mandatory fields are marked with an *</div>
            <div className='row mt-4 col-md-8'>
              <div className='col-md-6'>
                <label htmlFor='deviceName' className='is-required'>
                  Device Name
                </label>
                <input
                  className='w-100 form-control mt-2'
                  type='text'
                  id='deviceName'
                  maxLength={191}
                  defaultValue={this.props.device.name}
                  onChange={(e) => {
                    this.props.device.name = e.target.value;
                    if (e.target.value.trim().length === 0) {
                      this.setState({ isErrorOnDeviceName: true, errorMessage: MANDATORY_FIELD_MISSING });
                    } else {
                      this.setState({ isErrorOnDeviceName: false });
                    }
                  }}
                  required
                  readOnly={this.props.isReadOnly}
                  disabled={this.props.isReadOnly}
                  aria-describedby={this.state.isErrorOnDeviceName ? 'error-deviceName' : ''}
                  aria-invalid={this.state.isErrorOnDeviceName}
                  aria-errormessage='error-deviceName'
                  aria-required='true'
                ></input>
                {this.state.isErrorOnDeviceName ? (
                  <p id='error-deviceName' aria-label='error-deviceName' className='errorMessage mt-2'>
                    {this.state.errorMessage}
                  </p>
                ) : null}
              </div>
              <div className='col-md-6'>
                <label htmlFor='deviceType' className='is-required'>
                  Device Type
                </label>
                <ForegroundSelectSingle
                  inputId='deviceType'
                  placeholder={PLEASE_SELECT_DEVICE_TYPE}
                  value={currentDeviceType}
                  options={deviceTypes}
                  onChange={(e) => this.handleChangeDeviceType(e)}
                  isDisabled={this.props.isReadOnly}
                  isClearable={false}
                  closeOnSelect={true}
                  ariaDescribedBy={this.state.isErrorOnDeviceType ? 'error-deviceType' : ''}
                  ariaInvalid={this.state.isErrorOnDeviceType}
                  ariaRequired={true}
                />
                {this.state.isErrorOnDeviceType ? (
                  <p id='error-deviceType' aria-label='error-deviceType' className='errorMessage mt-2'>
                    {this.state.errorMessage}
                  </p>
                ) : null}
              </div>
            </div>
            <div className='row  mt-4 col-md-8'>
              <div className='col-md-6'>
                <label htmlFor="website" className="is-required">
                  Web page of the device
                </label>
                <input
                  className="w-100 form-control mt-2"
                  type="text"
                  id="website"
                  placeholder="http://...."
                  defaultValue={this.props.device.website}
                  onChange={(e) => {
                    this.props.device.website = e.target.value;
                    if (e.target.value.trim().length === 0) {
                      this.setState({ isErrorOnDeviceWebsite: true, errorMessage: MANDATORY_FIELD_MISSING });
                    } else {
                      if (WEB_URL_REGEX.test(e.target.value.trim())) {
                        this.setState({ isErrorOnDeviceWebsite: false });
                      } else {
                        this.setState({ isErrorOnDeviceWebsite: true, errorMessage: BAD_WEB_URL });
                      }
                    }
                  }}
                  required
                  readOnly={this.props.isReadOnly}
                  disabled={this.props.isReadOnly}
                  aria-describedby={this.state.isErrorOnDeviceWebsite ? 'error-deviceWebsite' : ''}
                  aria-invalid={this.state.isErrorOnDeviceWebsite}
                  aria-required="true"
                ></input>
                {this.state.isErrorOnDeviceWebsite ? (
                  <p id="error-deviceWebsite" aria-label="error-deviceWebsite" className="errorMessage mt-2">
                    {this.state.errorMessage}
                  </p>
                ) : null}
              </div>
              <div className="col-md-6">
                <label htmlFor="websiteFr" className="">
                  Web page of the device in french
                </label>

                <input
                  className="w-100 form-control mt-2"
                  type="text"
                  id="websiteFr"
                  placeholder="http://...."
                  defaultValue={websiteFr}
                  onChange={(e) => {
                    this.handleWebsiteFrChanged(e);
                  }}
                  readOnly={this.props.isReadOnly}
                  disabled={this.props.isReadOnly}
                ></input>
              </div>

            </div>
            <div className='row  mt-4 col-md-8'>
              <div className='col-md-6'>
                <label htmlFor='currentDeviceProductLifeCycleStatus' className='is-required'>
                  Product availability Status
                </label>
                  <ForegroundSelectSingle
                    inputId='currentDeviceProductLifeCycleStatus'
                    placeholder={PLEASE_SELECT_STATUS_PRODUCTION}
                    value={currentProductLifeCycle}
                    options={productLifeCycle}
                    onChange={(e) => this.handleChangeStatusProductLifeCycle(e)}
                    isDisabled={this.props.isReadOnly}
                    isClearable={false}
                    closeOnSelect={true}
                    ariaDescribedBy={this.state.isErrorOnDeviceProductLifeCycle ? 'error-inProductionStatus' : ''}
                    ariaInvalid={this.state.isErrorOnDeviceProductLifeCycle}
                    ariaRequired={true}
                  />
                  {this.state.isErrorOnDeviceProductLifeCycle ? (
                    <p id='error-productLifeCycleStatus' className='errorMessage mt-2'>
                      {this.state.errorMessage}
                    </p>
                  ) : null}
              </div>

              {this.props.scope === FOREGROUND_SCOPE.ORANGE_ADMIN &&
                <div className="col-md-6">
                  <label className='is-required' htmlFor='company'>
                    Company
                  </label>
                  <ForegroundSelectSingle
                    inputId='company'
                    placeholder={PLEASE_SELECT_COMPANY}
                    value={currentCompany}
                    options={companyListOptions}
                    onChange={(e) => this.handleChangeCompany(e)}
                    isClearable={false}
                    closeOnSelect={true}
                    isDisabled={this.props.isReadOnly}
                    ariaDescribedBy={this.state.isErrorOnCompany ? 'error-company' : ''}
                    ariaInvalid={this.state.isErrorOnCompany}
                    ariaRequired={true}
                  />
                  {this.state.isErrorOnCompany ? (
                    <p id='error-country' className='errorMessage mt-2'>
                      {PLEASE_SELECT_COMPANY}
                    </p>
                  ) : null}
                </div>
              }
            </div>
            <div className="row mt-4 col-md-8">
              <span id="labelDescription" className="fw-bold is-required mb-2">
                Description and features
              </span>
              <ManageTranslation
                manageObject={this.props.device}
                field="description"
                isReadOnly={this.props.isReadOnly}
                ariaLabel={'labelDescription'}
                ariaDescribedBy={this.state.isErrorOnDescription ? 'error-deviceDescription' : ''}
                ariaInvalid={this.state.isErrorOnDescription}
                handleTranslateValueChanged={this.handleTranslateValueChanged}
              ></ManageTranslation>
              {this.state.isErrorOnDescription ? (
                <p id="error-deviceDescription" className="errorMessage mt-2">
                  {this.state.errorMessage}
                </p>
              ) : null}
            </div>
            <div className="row mt-4">
              <div className="col-md-2">
                <label htmlFor="uploadImg" className="is-required">
                  Pictures
                </label>
              </div>
            </div>
            <div className="row mt-2 ">
              {!isCreation ? (
                <>
                  <div className="d-flex flex-row mb-4">
                    {!!image ? (
                      <DeviceImage
                        baseUrl={baseUrl}
                        image={image}
                        alt={this.props.device.name}
                        index={0}
                        onDeleteImage={this.handleDeleteImage}
                        onImageReferenceChanged={this.handleChangeImageReference}
                        isReadOnly={this.props.isReadOnly}
                      ></DeviceImage>
                    ) : (
                      ''
                    )}
                    {otherImages.map((i, index) => (
                      <DeviceImage
                        key={i.id}
                        baseUrl={baseUrl}
                        image={i}
                        alt={this.props.device.name}
                        index={index + 1}
                        onDeleteImage={this.handleDeleteImage}
                        onImageReferenceChanged={this.handleChangeImageReference}
                        isReadOnly={this.props.isReadOnly}
                      ></DeviceImage>
                    ))}
                  </div>
                  {this.state.noImageAsReference ? (
                    <p id="error-noImageAsReference" className="errorMessage mt-2">
                      Please make an image as reference or upload a new on
                    </p>
                  ) : (
                    ''
                  )}
                </>
              ) : (
                ''
              )}
              {!this.props.isReadOnly ? (
                <div>
                  <ImageLoader
                    imageUrl={null}
                    altLabel={`${this.props.device.name}`}
                    handleImageChanged={this.handleImageChanged}
                    isError={this.state.isErrorOnImage}
                    isReadOnly={this.props.isReadOnly}
                  ></ImageLoader>
                </div>
              ) : null}
            </div>
            {BackToTop}
            </>
          }
        </>
      );
    } else {
      return null;
    }
  }
}
