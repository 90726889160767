import React, { Component } from 'react';
import {
  MANDATORY_FIELD_MISSING,
  PLEASE_SELECT_DEVICE_FRONTEND,
  PLEASE_SELECT_DEVICE_MATURITY,
  PLEASE_SELECT_NETWORK,
  PLEASE_SELECT_PROTOCOL,
  PLEASE_SELECT_PROVIDER,
  PLEASE_SELECT_REGULATION,
} from '../../models/constants';
import ProductService from '../../services/product.service';
import './product_management.css';
import {
  ForegroundOption,
  ForegroundSelectMetaAction,
  ForegroundSelectMulti,
  ForegroundSelectSingle,
  SELECT_ACTION,
} from '../../components/ForegroundSelect';
import { DeviceWizardStepProps } from './DeviceWizard';
import {
  CpuMaker,
  DeviceFrontend,
  DeviceIOTProtocol,
  DeviceIPNorm,
  DeviceMaturity,
  ModemMaker,
  ModuleMaker,
  NetworkInterface,
} from '../../models/device';
import { BackToTop } from '../../components/backToTopButton';

class DeviceIOTProtocolsOption extends ForegroundOption {
  public parent: number;
  constructor(label: string, value: any, parent: number) {
    super(label, value);
    this.parent = parent;
  }
}

class DeviceFeatureStepState {
  isCreation: boolean;
  errorMessage: string;
  isErrorOnMaturity: boolean;
  isErrorOnChipsetProvider: boolean;
  isErrorOnCpuProvider: boolean;
  isErrorOnRegulation: boolean;
  isErrorOnNetwork: boolean;
  isErrorOnProtocol: boolean;
  isErrorOnCloud: boolean;
  isErrorOnFrontend: boolean;
  currentDeviceMaturity: ForegroundOption;
  deviceMaturityList: ForegroundOption[];
  currentDeviceRegulationCompliancies: ForegroundOption[];
  regulationComplianciesList: ForegroundOption[];
  currentDeviceModuleMaker: ForegroundOption;
  modulesMakersList: ForegroundOption[];
  currentDeviceModemMaker: ForegroundOption;
  modemsMakersList: ForegroundOption[];
  currentDeviceCpuMaker: ForegroundOption;
  cpusMakersList: ForegroundOption[];
  currentDeviceNetworks: ForegroundOption[];
  networksInterfaceList: ForegroundOption[];
  currentDeviceGeolocation: ForegroundOption[];
  DeviceGeolocationList: ForegroundOption[];
  geolocationsTechnologyList: ForegroundOption[];
  deviceIPNormsList: ForegroundOption[];
  currentDeviceIpNorms: ForegroundOption[];
  deviceIOTProtocolsList: DeviceIOTProtocolsOption[];
  currentDeviceProtocols: DeviceIOTProtocolsOption[];
  deviceFrontendsList: ForegroundOption[];
  currentDeviceFrontend: ForegroundOption;
  currentDeviceIsPlatformAvailable: boolean;
  currentDeviceIsIntegratedWithLO: boolean;
  disableDisplayModuleProvider: boolean;
  disableDisplayOtherModuleProvider: boolean;
  disableDisplayChipsetProvider: boolean;
  disableDisplayOtherChipsetProvider: boolean;
  disableDisplayCpuProvider: boolean;
  disableDisplayOtherCpuProvider: boolean;

  constructor() {
    this.isCreation = false;
    this.errorMessage = '';
    this.isErrorOnMaturity = false;
    this.isErrorOnChipsetProvider = false;
    this.isErrorOnCpuProvider = false;
    this.isErrorOnRegulation = false;
    this.isErrorOnNetwork = false;
    this.isErrorOnProtocol = false;
    this.isErrorOnCloud = false;
    this.isErrorOnFrontend = false;
    this.currentDeviceMaturity = null;
    this.deviceMaturityList = [];
    this.currentDeviceRegulationCompliancies = [];
    this.regulationComplianciesList = [];
    this.currentDeviceModuleMaker = null;
    this.modulesMakersList = [];
    this.currentDeviceModemMaker = null;
    this.modemsMakersList = [];
    this.currentDeviceCpuMaker = null;
    this.cpusMakersList = [];
    this.currentDeviceNetworks = [];
    this.networksInterfaceList = [];
    this.currentDeviceGeolocation = [];
    this.DeviceGeolocationList = [];
    this.geolocationsTechnologyList = [];
    this.deviceIPNormsList = [];
    this.currentDeviceIpNorms = [];
    this.deviceIOTProtocolsList = [];
    this.currentDeviceProtocols = [];
    this.deviceFrontendsList = [];
    this.currentDeviceFrontend = null;
    this.currentDeviceIsPlatformAvailable = false;
    this.currentDeviceIsIntegratedWithLO = false;
    this.disableDisplayModuleProvider = false;
    this.disableDisplayOtherModuleProvider = false;
    this.disableDisplayChipsetProvider = false;
    this.disableDisplayOtherChipsetProvider = false;
    this.disableDisplayCpuProvider = false;
    this.disableDisplayOtherCpuProvider = false;
  }
}

export class DeviceFeatureStep extends Component<DeviceWizardStepProps, DeviceFeatureStepState> {
  constructor(props) {
    super(props);
    this.state = new DeviceFeatureStepState();
    this.handleChangeDeviceMaturity = this.handleChangeDeviceMaturity.bind(this);
    this.handleChangeDeviceModuleMaker = this.handleChangeDeviceModuleMaker.bind(this);
    this.handleChangeDeviceModemMaker = this.handleChangeDeviceModemMaker.bind(this);
    this.handleChangeDeviceCpuMaker = this.handleChangeDeviceCpuMaker.bind(this);
    this.handleChangeDeviceRegulationCompliance = this.handleChangeDeviceRegulationCompliance.bind(this);
    this.handleChangeDeviceNetworkInterfaces = this.handleChangeDeviceNetworkInterfaces.bind(this);
    this.handleChangeDeviceGeolocations = this.handleChangeDeviceGeolocations.bind(this);
    this.handleChangeDeviceIpNorms = this.handleChangeDeviceIpNorms.bind(this);
    this.handleChangeDeviceProtocols = this.handleChangeDeviceProtocols.bind(this);
    this.handleClickPlatform = this.handleClickPlatform.bind(this);
    this.handleChangeDeviceFrontend = this.handleChangeDeviceFrontend.bind(this);
  }

  async componentDidMount() {
    try {
      const response = await Promise.all([
        ProductService.maturitiesList(),
        ProductService.regulationComplianciesList(),
        ProductService.modulesMakersList(),
        ProductService.modemsMakersList(),
        ProductService.cpusMakersList(),
        ProductService.networkInterfacesList(),
        ProductService.deviceIPNormsList(),
        ProductService.deviceIOTProtocolsList(),
        ProductService.deviceFrontendsList(),
      ]);
      const maturity = this.props.device.maturity;
      const moduleMaker = this.props.device.moduleMaker;
      const otherModuleMaker = this.props.device.otherModuleMaker;
      const modemMaker = this.props.device.modemMaker;
      const otherModemMaker = this.props.device.otherModemMaker;
      const cpuMaker = this.props.device.cpuMaker;
      const otherCpuMaker = this.props.device.otherCpuMaker;
      const regulations = this.props.device.regulations;
      const deviceNetworks = this.props.device.networks;
      const deviceNetworksGeolocation = this.props.device.geolocation;
      const deviceIpNorms = this.props.device.ipnorms;
      const deviceProtocols = this.props.device.protocols;
      const deviceFrontend = this.props.device.frontend;
      const devicePlatform = this.props.device.platform;

      let disableDisplayModuleProvider = false, disableDisplayOtherModuleProvider = false;
      let disableDisplayChipsetProvider = false, disableDisplayOtherChipsetProvider = false;
      let disableDisplayCpuProvider = false, disableDisplayOtherCpuProvider = false;

      const isCreation = !!!this.props.device.id;
      if (!!!isCreation) {
        disableDisplayModuleProvider = !moduleMaker;
        disableDisplayOtherModuleProvider = !otherModuleMaker;
        disableDisplayChipsetProvider = !modemMaker;
        disableDisplayOtherChipsetProvider = !otherModemMaker;
        disableDisplayCpuProvider = !cpuMaker;
        disableDisplayOtherCpuProvider = !otherCpuMaker;

        // Three special cases, which should not occur but occur
        // (existing database state, prior to this coupled field management process)
        if (!!!moduleMaker && !!!otherModuleMaker) {
          disableDisplayModuleProvider = false;
          disableDisplayOtherModuleProvider = false;
        }
        if (!!!modemMaker && !!!otherModemMaker) {
          disableDisplayChipsetProvider = false;
          disableDisplayOtherChipsetProvider = false;
        }

        if (!!!cpuMaker && !!!otherCpuMaker) {
          disableDisplayCpuProvider = false;
          disableDisplayOtherCpuProvider = false;
        }
      }

      this.setState({
        isCreation: isCreation,
        deviceMaturityList: response[0].data.map((item) => {
          return new ForegroundOption(item.label, item.id);
        }),
        currentDeviceMaturity: !!maturity ? new ForegroundOption(maturity.label, maturity.id) : null,

        regulationComplianciesList: response[1].data.map((item) => {
          return new ForegroundOption(item.label, item.id);
        }),
        currentDeviceRegulationCompliancies: !!regulations
          ? regulations.map((item) => {
              return new ForegroundOption(item.label, item.id);
            })
          : [],

        modulesMakersList: response[2].data.map((item) => {
          return new ForegroundOption(item.name, item.id);
        }),
        currentDeviceModuleMaker: !!moduleMaker ? new ForegroundOption(moduleMaker.name, moduleMaker.id) : null,
        disableDisplayModuleProvider: disableDisplayModuleProvider,
        disableDisplayOtherModuleProvider: disableDisplayOtherModuleProvider,
        modemsMakersList: response[3].data.map((item) => {
          return new ForegroundOption(item.name, item.id);
        }),
        currentDeviceModemMaker: !!modemMaker ? new ForegroundOption(modemMaker.name, modemMaker.id) : null,
        disableDisplayChipsetProvider: disableDisplayChipsetProvider,
        disableDisplayOtherChipsetProvider: disableDisplayOtherChipsetProvider,
        cpusMakersList: response[4].data.map((item) => {
          return new ForegroundOption(item.name, item.id);
        }),
        currentDeviceCpuMaker: !!cpuMaker ? new ForegroundOption(cpuMaker.name, cpuMaker.id) : null,
        disableDisplayCpuProvider: disableDisplayCpuProvider,
        disableDisplayOtherCpuProvider: disableDisplayOtherCpuProvider,
        networksInterfaceList: response[5].data
          .filter((n) => n.connectivity === true)
          .map((item) => {
            return new ForegroundOption(item.label, item.id);
          }),
        currentDeviceNetworks: !!deviceNetworks
          ? deviceNetworks.map((item) => {
              return new ForegroundOption(item.label, item.id);
            })
          : [],

        geolocationsTechnologyList: response[5].data
          .filter((item) => item.geolocation === true)
          .map((item) => {
            return new ForegroundOption(item.label, item.id);
          }),
        currentDeviceGeolocation: !!deviceNetworksGeolocation
          ? deviceNetworksGeolocation.map((item) => {
              return new ForegroundOption(item.label, item.id);
            })
          : [],

        deviceIPNormsList: response[6].data.map((item) => {
          return new ForegroundOption(item.label, item.id);
        }),
        currentDeviceIpNorms: !!deviceIpNorms
          ? deviceIpNorms.map((item) => {
              return new ForegroundOption(item.label, item.id);
            })
          : [],

        deviceIOTProtocolsList: response[7].data.map((item) => {
          return new DeviceIOTProtocolsOption(item.label, item.id, item.parent);
        }),
        currentDeviceProtocols: !!deviceProtocols
          ? deviceProtocols.map((item) => {
              return new DeviceIOTProtocolsOption(item.label, item.id, item.parent);
            })
          : [],

        currentDeviceIsPlatformAvailable: !!devicePlatform ? devicePlatform.isPlatformAvailable : false,
        currentDeviceIsIntegratedWithLO: !!devicePlatform ? devicePlatform.isIntegratedWithLO : false,

        deviceFrontendsList: response[8].data.map((item) => {
          return new ForegroundOption(item.label, item.id);
        }),
        currentDeviceFrontend: !!deviceFrontend ? new ForegroundOption(deviceFrontend.label, deviceFrontend.id) : null,
      });
    } catch (e) {
        console.error(e);
    }
  }

  handleChangeDeviceMaturity(event: ForegroundOption) {
      this.props.device.maturity = new DeviceMaturity(event.value, event.label);
      this.setState({ currentDeviceMaturity: event, isErrorOnMaturity: false });
  }

  handleChangeDeviceModuleMaker(event: ForegroundOption, action: ForegroundSelectMetaAction) {
    if (action.action === SELECT_ACTION.CLEAR) {
      this.props.device.moduleMaker = null;
      this.setState({ currentDeviceModuleMaker: null, disableDisplayModuleProvider: false, disableDisplayOtherModuleProvider: false });
    } else {
      this.props.device.moduleMaker = new ModuleMaker(event.value, event.label);
      this.setState({ currentDeviceModuleMaker: event, disableDisplayModuleProvider: false, disableDisplayOtherModuleProvider: true });
    }
  }

  handleChangeDeviceModemMaker(event: ForegroundOption, action: ForegroundSelectMetaAction) {
    if (action.action === SELECT_ACTION.CLEAR) {
      this.props.device.modemMaker = null;
      this.setState({ currentDeviceModemMaker: null, disableDisplayChipsetProvider: false, disableDisplayOtherChipsetProvider: false });

      const otherChipsetProvider = !!this.props.device.otherModemMaker ? this.props.device.otherModemMaker : '';
      if (!!!this.props.device.modemMaker && otherChipsetProvider.trim().length === 0) {
        this.setState({ isErrorOnChipsetProvider: true, errorMessage: PLEASE_SELECT_PROVIDER });
      }
    } else {
      this.props.device.modemMaker = new ModemMaker(event.value, event.label);
      this.setState({
        currentDeviceModemMaker: event,
        isErrorOnChipsetProvider: false,
        disableDisplayChipsetProvider: false,
        disableDisplayOtherChipsetProvider: true,
      });
    }
  }

  handleChangeDeviceCpuMaker(event: ForegroundOption, action: ForegroundSelectMetaAction) {
    if (action.action === SELECT_ACTION.CLEAR) {
      this.props.device.cpuMaker = null;
      this.setState({ currentDeviceCpuMaker: null, disableDisplayCpuProvider: false, disableDisplayOtherCpuProvider: false });

      const otherCpuProvider = !!this.props.device.otherCpuMaker ? this.props.device.otherCpuMaker : '';
      if (!!!this.props.device.cpuMaker && otherCpuProvider.trim().length === 0) {
        this.setState({ isErrorOnCpuProvider: true, errorMessage: PLEASE_SELECT_PROVIDER });
      }
    } else {
      this.props.device.cpuMaker = new CpuMaker(event.value, event.label);
      this.setState({
        currentDeviceCpuMaker: event,
        isErrorOnCpuProvider: false,
        disableDisplayCpuProvider: false,
        disableDisplayOtherCpuProvider: true,
      });
    }
  }

  handleChangeDeviceRegulationCompliance(event: ForegroundOption[], action: ForegroundSelectMetaAction) {
    const currentDeviceRegulationCompliancies = this.state.currentDeviceRegulationCompliancies;

    switch (action.action) {
      case SELECT_ACTION.SELECT_OPTION:
        this.props.device.regulations = !!event
          ? event.map((r) => {
              return { id: r.value, label: r.label };
            })
          : [];
        this.setState({ currentDeviceRegulationCompliancies: event, isErrorOnRegulation: false });

        break;
      case SELECT_ACTION.REMOVE_VALUE:
        const newSelectionRegulations = currentDeviceRegulationCompliancies.filter((e) => e.value !== action.removedValue.value);
        this.props.device.regulations = this.props.device.regulations.filter((e) => e.id !== action.removedValue.value);
        const isError = this.props.device.regulations.length === 0;
        const errorMessage = isError ? PLEASE_SELECT_REGULATION : '';
        this.setState({
          currentDeviceRegulationCompliancies: newSelectionRegulations,
          isErrorOnRegulation: isError,
          errorMessage: errorMessage,
        });
        break;
      case SELECT_ACTION.CLEAR:
        this.props.device.regulations = [];
        this.setState({ currentDeviceRegulationCompliancies: [], isErrorOnRegulation: true, errorMessage: PLEASE_SELECT_REGULATION });
        break;
      default:
    }
  }

  handleChangeDeviceNetworkInterfaces(event: ForegroundOption[], action: ForegroundSelectMetaAction) {
    const currentDeviceNetworks = this.state.currentDeviceNetworks;

    switch (action.action) {
      case SELECT_ACTION.SELECT_OPTION:
        this.props.device.networks = !!event
          ? event.map((r) => {
              return new NetworkInterface(r.value, r.label, true, false);
            })
          : [];
        this.setState({ currentDeviceNetworks: event, isErrorOnNetwork: false });
        break;
      case SELECT_ACTION.REMOVE_VALUE:
        const newSelectionNetworks = currentDeviceNetworks.filter((e) => e.value !== action.removedValue.value);
        this.props.device.networks = this.props.device.networks.filter((e) => e.id !== action.removedValue.value);
        const isError = this.props.device.networks.length === 0;
        const errorMessage = isError ? PLEASE_SELECT_NETWORK : '';
        this.setState({ currentDeviceNetworks: newSelectionNetworks, isErrorOnNetwork: isError, errorMessage: errorMessage });
        break;
      case SELECT_ACTION.CLEAR:
        this.props.device.networks = [];
        this.setState({ currentDeviceNetworks: [], isErrorOnNetwork: true, errorMessage: PLEASE_SELECT_NETWORK });
        break;
      default:
    }
  }

  handleChangeDeviceGeolocations(event: ForegroundOption[], action: ForegroundSelectMetaAction) {
    const currentDeviceGeolocation = this.state.currentDeviceGeolocation;

    switch (action.action) {
      case SELECT_ACTION.SELECT_OPTION:
        this.setState({ currentDeviceGeolocation: event });
        this.props.device.geolocation = !!event
          ? event.map((r) => {
              return new NetworkInterface(r.value, r.label, false, true);
            })
          : [];
        break;
      case SELECT_ACTION.REMOVE_VALUE:
        const newSelectionGeolocation = currentDeviceGeolocation.filter((e) => e.value !== action.removedValue.value);
        this.setState({ currentDeviceGeolocation: newSelectionGeolocation });
        this.props.device.geolocation = this.props.device.geolocation.filter((e) => e.id !== action.removedValue.value);
        break;
      case SELECT_ACTION.CLEAR:
        this.setState({ currentDeviceGeolocation: [] });
        this.props.device.geolocation = [];
        break;
      default:
    }
  }

  handleChangeDeviceIpNorms(event: ForegroundOption[], action: ForegroundSelectMetaAction) {
    const currentDeviceIpNorms = this.state.currentDeviceIpNorms;

    switch (action.action) {
      case SELECT_ACTION.SELECT_OPTION:
        this.props.device.ipnorms = !!event
          ? event.map((r) => {
              return new DeviceIPNorm(r.value, r.label);
            })
          : [];
        this.setState({ currentDeviceIpNorms: event });
        break;
      case SELECT_ACTION.REMOVE_VALUE:
        const newSelectionIpNorms = currentDeviceIpNorms.filter((e) => e.value !== action.removedValue.value);
        this.setState({ currentDeviceIpNorms: newSelectionIpNorms });
        this.props.device.ipnorms = this.props.device.ipnorms.filter((e) => e.id !== action.removedValue.value);
        break;
      case SELECT_ACTION.CLEAR:
        this.setState({ currentDeviceIpNorms: [] });
        this.props.device.ipnorms = [];
        break;
      default:
    }
  }

  handleChangeDeviceProtocols(event: DeviceIOTProtocolsOption[], action: ForegroundSelectMetaAction) {
    const currentDeviceProtocols = this.state.currentDeviceProtocols;

    switch (action.action) {
      case SELECT_ACTION.SELECT_OPTION:
        this.setState({ currentDeviceProtocols: event, isErrorOnProtocol: false });
        this.props.device.protocols = !!event
          ? event.map((r) => {
              return new DeviceIOTProtocol(r.value, r.label, r.parent);
            })
          : [];
        break;
      case SELECT_ACTION.REMOVE_VALUE:
        const newSelectionProtocols = currentDeviceProtocols.filter((e) => e.value !== action.removedValue.value);
        this.props.device.protocols = this.props.device.protocols.filter((e) => e.id !== action.removedValue.value);
        const isError = this.props.device.protocols.length === 0;
        const errorMessage = isError ? PLEASE_SELECT_PROTOCOL : '';
        this.setState({ currentDeviceProtocols: newSelectionProtocols, isErrorOnProtocol: isError, errorMessage: errorMessage });
        break;
      case SELECT_ACTION.CLEAR:
        this.setState({ currentDeviceProtocols: [], isErrorOnProtocol: true, errorMessage: PLEASE_SELECT_PROTOCOL });
        this.props.device.protocols = [];

        break;
      default:
    }
  }

  handleClickPlatform(isPlatformAvailableOn: boolean, isIntegratedWithLOOn: boolean) {
    this.setState({ currentDeviceIsPlatformAvailable: isPlatformAvailableOn, currentDeviceIsIntegratedWithLO: isIntegratedWithLOOn });
    this.props.device.platform = { isPlatformAvailable: isPlatformAvailableOn, isIntegratedWithLO: isIntegratedWithLOOn };
  }

  handleChangeDeviceFrontend(event: ForegroundOption) {
      this.props.device.frontend = new DeviceFrontend(event.value, event.label);
      this.setState({ currentDeviceFrontend: event, isErrorOnFrontend: false });
  }

  validate() {
    if (this.props.isReadOnly) {
      return true;
    }

    if (!!!this.props.device.maturity) {
      this.setState({ isErrorOnMaturity: true, errorMessage: PLEASE_SELECT_DEVICE_MATURITY });
      return false;
    }

    const otherChipsetProvider = !!this.props.device.otherModemMaker ? this.props.device.otherModemMaker : '';
    if (!!!this.props.device.modemMaker && otherChipsetProvider.trim().length === 0) {
      this.setState({ isErrorOnChipsetProvider: true, errorMessage: PLEASE_SELECT_PROVIDER });
      return false;
    }

    const otherCpuProvider = !!this.props.device.otherCpuMaker ? this.props.device.otherCpuMaker : '';
    if (!!!this.props.device.cpuMaker && otherCpuProvider.trim().length === 0) {
      this.setState({ isErrorOnCpuProvider: true, errorMessage: PLEASE_SELECT_PROVIDER });
      return false;
    }

    if (!!!this.props.device.regulations || this.props.device.regulations.length === 0) {
      this.setState({ isErrorOnRegulation: true, errorMessage: PLEASE_SELECT_REGULATION });
      return false;
    }

    if (!!!this.props.device.networks || this.props.device.networks.length === 0) {
      this.setState({ isErrorOnNetwork: true, errorMessage: PLEASE_SELECT_NETWORK });
      return false;
    }

    if (!!!this.props.device.protocols || this.props.device.protocols.length === 0) {
      this.setState({ isErrorOnProtocol: true, errorMessage: PLEASE_SELECT_PROTOCOL });
      return false;
    }

    if (this.props.device.cloud.trim().length === 0) {
      this.setState({ isErrorOnCloud: true, errorMessage: MANDATORY_FIELD_MISSING });
      return false;
    }

    if (!!!this.props.device.frontend) {
      this.setState({ isErrorOnFrontend: true, errorMessage: PLEASE_SELECT_DEVICE_FRONTEND });
      return false;
    }

    return true;
  }

  render() {
    if (this.props.shouldDisplay) {
      return (
        <>
          <div className="row">
          <div className='mb-3 mt-2'>All mandatory fields are marked with an *</div>
          <div className="col-md-6">
            <label className="is-required" htmlFor="deviceMaturities">
              Device maturity
            </label>
            <ForegroundSelectSingle
              inputId="deviceMaturities"
              placeholder={PLEASE_SELECT_DEVICE_MATURITY}
              value={this.state.currentDeviceMaturity}
              options={this.state.deviceMaturityList}
              onChange={(e) => {
                this.handleChangeDeviceMaturity(e);
              }}
              isClearable={false}
              isDisabled={this.props.isReadOnly}
              closeOnSelect={true}
              ariaDescribedBy={this.state.isErrorOnMaturity ? 'error-maturity' : ''}
              ariaInvalid={this.state.isErrorOnMaturity}
            />
            {this.state.isErrorOnMaturity ? (
              <p id="error-maturity" className="errorMessage mt-2">
                {this.state.errorMessage}
              </p>
            ) : null}

            <h2 className="mt-4 ">Components</h2>

            <h3 className=" ">Module provided by:</h3>

            <div className="row ms-2">
              <div className="col-md-3">
                <label className="mt-2" htmlFor="moduleProvider">
                  Module provider:
                </label>
              </div>
              <div className="col-md-9">
                <ForegroundSelectSingle
                  inputId="moduleProvider"
                  placeholder="Please select a module"
                  value={this.state.currentDeviceModuleMaker}
                  options={this.state.modulesMakersList}
                  onChange={(e, a) => this.handleChangeDeviceModuleMaker(e, a)}
                  isDisabled={this.props.isReadOnly || this.state.disableDisplayModuleProvider}
                />
              </div>
            </div>
            <div className="row ms-2 mt-2">
              <div className="col-md-3">
                <label className="" htmlFor="otherModuleProvider">
                  Other module provider:
                </label>
              </div>
              <div className="col-md-9">
                <input
                  type="text"
                  className="form-control"
                  id="otherModuleProvider"
                  name="otherModuleProvider"
                  defaultValue={this.props.device.otherModuleMaker}
                  onChange={(e) => {
                    this.props.device.otherModuleMaker = e.target.value;
                    if (e.target.value.trim().length === 0) {
                      this.setState({ disableDisplayModuleProvider: false, disableDisplayOtherModuleProvider: false });
                    } else {
                      this.setState({ disableDisplayModuleProvider: true, disableDisplayOtherModuleProvider: false });
                    }
                  }}
                  readOnly={this.props.isReadOnly || this.state.disableDisplayOtherModuleProvider}
                  disabled={this.props.isReadOnly || this.state.disableDisplayOtherModuleProvider}
                ></input>
              </div>
            </div>

            <h3 className="mt-3 is-required">Chipset provided by:</h3>

            <div className="row ms-2">
              <div className="col-md-3">
                <label className="mt-2 " htmlFor="chipsetProvider">
                  Chipset provider:
                </label>
              </div>
              <div className="col-md-9">
                <ForegroundSelectSingle
                  inputId="chipsetProvider"
                  placeholder={PLEASE_SELECT_PROVIDER}
                  value={this.state.currentDeviceModemMaker}
                  options={this.state.modemsMakersList}
                  onChange={(e, a) => this.handleChangeDeviceModemMaker(e, a)}
                  isDisabled={this.props.isReadOnly || this.state.disableDisplayChipsetProvider}
                />
              </div>
            </div>
            <div className="row ms-2 mt-2">
              <div className="col-md-3">
                <label className="" htmlFor="otherChipsetsProvider">
                  Other chipset provider:
                </label>
              </div>
              <div className="col-md-9">
                <input
                  id="otherChipsetsProvider"
                  name="otherChipsetsProvider"
                  type="text"
                  className="form-control"
                  defaultValue={this.props.device.otherModemMaker}
                  onChange={(e) => {
                    this.props.device.otherModemMaker = e.target.value;
                    if (e.target.value.trim().length === 0 && !!!this.state.currentDeviceModemMaker) {
                      this.setState({
                        isErrorOnChipsetProvider: true,
                        errorMessage: PLEASE_SELECT_PROVIDER,
                        disableDisplayChipsetProvider: false,
                        disableDisplayOtherChipsetProvider: false,
                      });
                    } else {
                      if (e.target.value.trim().length === 0) {
                        this.setState({
                          isErrorOnChipsetProvider: false,
                          disableDisplayChipsetProvider: false,
                          disableDisplayOtherChipsetProvider: false,
                        });
                      } else {
                        this.setState({
                          isErrorOnChipsetProvider: false,
                          disableDisplayChipsetProvider: true,
                          disableDisplayOtherChipsetProvider: false,
                        });
                      }
                    }
                  }}
                  readOnly={this.props.isReadOnly || this.state.disableDisplayOtherChipsetProvider}
                  disabled={this.props.isReadOnly || this.state.disableDisplayOtherChipsetProvider}
                  aria-describedby={this.state.isErrorOnChipsetProvider ? 'error-chipsetProvider' : ''}
                  aria-invalid={this.state.isErrorOnChipsetProvider}
                  aria-required="true"
                ></input>
              </div>
              {this.state.isErrorOnChipsetProvider ? (
                <p id="error-chipsetProvider" className="errorMessage mt-2">
                  {this.state.errorMessage}
                </p>
              ) : null}
            </div>

            <h3 className="mt-3 is-required">Cpu provided by:</h3>

            <div className="row ms-2">
              <div className="col-md-3">
                <label className="mt-2" htmlFor="cpuProvider">
                  Cpu provider:
                </label>
              </div>
              <div className="col-md-9">
                <ForegroundSelectSingle
                  inputId="cpuProvider"
                  placeholder={PLEASE_SELECT_PROVIDER}
                  value={this.state.currentDeviceCpuMaker}
                  options={this.state.cpusMakersList}
                  onChange={(e, a) => this.handleChangeDeviceCpuMaker(e, a)}
                  isDisabled={this.props.isReadOnly || this.state.disableDisplayCpuProvider}
                />
              </div>
            </div>
            <div className="row pt-2 ms-2">
              <div className="col-md-3">
                <label htmlFor="otherCpuProvider" className="">
                  Other cpu provider:
                </label>
              </div>

              <div className="col-md-9">
                <input
                  required
                  type="text"
                  className="form-control"
                  id="otherCpuProvider"
                  name="otherCpuProvider"
                  defaultValue={this.props.device.otherCpuMaker}
                  onChange={(e) => {
                    this.props.device.otherCpuMaker = e.target.value;
                    if (e.target.value.trim().length === 0 && !!!this.state.currentDeviceCpuMaker) {
                      this.setState({
                        isErrorOnCpuProvider: true,
                        errorMessage: PLEASE_SELECT_PROVIDER,
                        disableDisplayCpuProvider: false,
                        disableDisplayOtherCpuProvider: false,
                      });
                    } else {
                      if (e.target.value.trim().length === 0) {
                        this.setState({
                          isErrorOnCpuProvider: false,
                          errorMessage: '',
                          disableDisplayCpuProvider: false,
                          disableDisplayOtherCpuProvider: false,
                        });
                      } else {
                        this.setState({
                          isErrorOnCpuProvider: false,
                          errorMessage: '',
                          disableDisplayCpuProvider: true,
                          disableDisplayOtherCpuProvider: false,
                        });
                      }
                    }
                  }}
                  readOnly={this.props.isReadOnly || this.state.disableDisplayOtherCpuProvider}
                  disabled={this.props.isReadOnly || this.state.disableDisplayOtherCpuProvider}
                  aria-describedby={this.state.isErrorOnCpuProvider ? 'error-cpuProvider' : ''}
                  aria-invalid={this.state.isErrorOnCpuProvider}
                  aria-required="true"
                ></input>
              </div>
              {this.state.isErrorOnCpuProvider ? (
                <p id="cpuProvider" className="errorMessage mt-2">
                  {this.state.errorMessage}
                </p>
              ) : null}
            </div>
          </div>

          <div className="col-md-6">
            <label className="is-required " htmlFor="regulations">
              Regulations compliancy
            </label>
            <ForegroundSelectMulti
              inputId="regulations"
              isClearable={true}
              placeholder={PLEASE_SELECT_REGULATION}
              value={this.state.currentDeviceRegulationCompliancies}
              options={this.state.regulationComplianciesList}
              onChange={(e, a) => this.handleChangeDeviceRegulationCompliance(e, a)}
              isDisabled={this.props.isReadOnly}
              ariaDescribedBy={this.state.isErrorOnRegulation ? 'error-regulation' : ''}
              ariaInvalid={this.state.isErrorOnRegulation}
              ariaRequired={true}
            />
            {this.state.isErrorOnRegulation ? (
              <p id="error-regulation" className="errorMessage mt-2">
                {this.state.errorMessage}
              </p>
            ) : null}

            <label className="is-required mt-4 " htmlFor="networks">
              Network/Radio interfaces
            </label>
            <ForegroundSelectMulti
              inputId="networks"
              placeholder={PLEASE_SELECT_NETWORK}
              value={this.state.currentDeviceNetworks}
              options={this.state.networksInterfaceList}
              onChange={(e, a) => this.handleChangeDeviceNetworkInterfaces(e, a)}
              isDisabled={this.props.isReadOnly}
              ariaDescribedBy={this.state.isErrorOnNetwork ? 'error-network' : ''}
              ariaInvalid={this.state.isErrorOnNetwork}
              ariaRequired={true}
            />
            {this.state.isErrorOnNetwork ? (
              <p id="error-network" className="errorMessage mt-2">
                {this.state.errorMessage}
              </p>
            ) : null}

            <label className="mt-4 " htmlFor="geolocation">
              Geolocation technology
            </label>
            <ForegroundSelectMulti
              inputId="geolocation"
              placeholder="Please select geolocation technology"
              value={this.state.currentDeviceGeolocation}
              options={this.state.geolocationsTechnologyList}
              onChange={(e, a) => this.handleChangeDeviceGeolocations(e, a)}
              isDisabled={this.props.isReadOnly}
            />

            <label className="mt-4 " htmlFor="ipnorms">
              IP Norms
            </label>
            <ForegroundSelectMulti
              inputId="ipnorms"
              placeholder="Please select IP Norms"
              value={this.state.currentDeviceIpNorms}
              options={this.state.deviceIPNormsList}
              onChange={(e, a) => this.handleChangeDeviceIpNorms(e, a)}
              isDisabled={this.props.isReadOnly}
            />

            <label className="is-required mt-4 " htmlFor="protocols">
              Iot Protocols
            </label>
            <ForegroundSelectMulti
              inputId="protocols"
              placeholder={PLEASE_SELECT_PROTOCOL}
              value={this.state.currentDeviceProtocols}
              options={this.state.deviceIOTProtocolsList}
              onChange={(e, a) => this.handleChangeDeviceProtocols(e, a)}
              isDisabled={this.props.isReadOnly}
              ariaDescribedBy={this.state.isErrorOnProtocol ? 'error-protocol' : ''}
              ariaInvalid={this.state.isErrorOnProtocol}
              ariaRequired={true}
            />
            {this.state.isErrorOnProtocol ? (
              <p id="error-protocol" className="errorMessage mt-2">
                {this.state.errorMessage}
              </p>
            ) : null}

            <div className="form-check form-switch div-check  mt-4">
              <label className="form-check-label" htmlFor="flexSwitchCheckPlatformAvailable">
                Platform available
              </label>
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckPlatformAvailable"
                name="flexSwitchCheckPlatformAvailable"
                checked={this.state.currentDeviceIsPlatformAvailable}
                onChange={() => this.handleClickPlatform(!this.state.currentDeviceIsPlatformAvailable, this.state.currentDeviceIsIntegratedWithLO)}
                readOnly={this.props.isReadOnly}
                disabled={this.props.isReadOnly}
              ></input>
            </div>

            <div className="form-check form-switch div-check mt-2">
              <label className="form-check-label " htmlFor="flexSwitchCheckIntegratedWithLO">
                Integrated with Live Objects
              </label>
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckIntegratedWithLO"
                name="flexSwitchCheckIntegratedWithLO"
                checked={this.state.currentDeviceIsIntegratedWithLO}
                onChange={() => this.handleClickPlatform(this.state.currentDeviceIsPlatformAvailable, !this.state.currentDeviceIsIntegratedWithLO)}
                readOnly={this.props.isReadOnly}
                disabled={this.props.isReadOnly}
              ></input>
            </div>

            <div>
              <label className="is-required  mt-4" htmlFor="textareaCloud">
                Integrated with Cloud Platform
              </label>
              <textarea
                required
                className="w-100 mt-2"
                rows={4}
                id="textareaCloud"
                name="textareaCloud"
                defaultValue={this.props.device.cloud}
                onChange={(e) => {
                  this.props.device.cloud = e.target.value;
                  if (this.props.device.cloud.trim().length !== 0) {
                    this.setState({ isErrorOnCloud: false });
                  } else {
                    this.setState({ isErrorOnCloud: true, errorMessage: MANDATORY_FIELD_MISSING });
                  }
                }}
                readOnly={this.props.isReadOnly}
                disabled={this.props.isReadOnly}
                aria-describedby={this.state.isErrorOnCloud ? 'error-cloud' : ''}
                aria-invalid={this.state.isErrorOnCloud}
                aria-required="true"
              ></textarea>
              {this.state.isErrorOnCloud ? (
                <p id="error-cloud" className="errorMessage mt-2">
                  {this.state.errorMessage}
                </p>
              ) : null}

              <label className="is-required  mt-4" htmlFor="frontend">
                Frontend availability
              </label>
              <ForegroundSelectSingle
                inputId="frontend"
                isClearable={false}
                placeholder={PLEASE_SELECT_DEVICE_FRONTEND}
                value={this.state.currentDeviceFrontend}
                options={this.state.deviceFrontendsList}
                onChange={(e) => this.handleChangeDeviceFrontend(e)}
                isDisabled={this.props.isReadOnly}
                ariaDescribedBy={this.state.isErrorOnFrontend ? 'error-frontend' : ''}
                ariaInvalid={this.state.isErrorOnFrontend}
                ariaRequired={true}
              />
              {this.state.isErrorOnFrontend ? (
                <p id="error-frontend" className="errorMessage mt-2">
                  {this.state.errorMessage}
                </p>
              ) : null}
            </div>
          </div>
        </div>
          {BackToTop}
        </>
      );
    } else {
      return null;
    }
  }
}
