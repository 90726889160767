import React, { Component } from 'react';
import { ManageTranslation } from '../../components/ManageTranslation/ManageTranslation';
import { ImageLoader } from '../../components/ImageLoader';
import {
  BAD_WEB_URL,
  EMPTY_DESCRIPTION,
  FOREGROUND_SCOPE,
  MANDATORY_FIELD_MISSING,
  PLEASE_SELECT_COMPANY_ACTIVITY,
  PLEASE_SELECT_COUNTRY,
  WEB_URL_REGEX,
} from '../../models/constants';
import CompanyService from '../../services/company.service';
import CountryService from '../../services/country.service';
import './company-management.css';
import { ForegroundOption, ForegroundSelectSingle } from '../../components/ForegroundSelect';
import { CompanyWizardStepProps } from './CompanyWizard';
import { CompanyActivity } from '../../models/company';
import { Country } from '../../models/country';
import { BackToTop } from '../../components/backToTopButton';

class CompanyInformationStepState {
  isErrorOnCompanyName: boolean;
  isErrorOnCompanyActivity: boolean;
  isErrorOnCompanyDescription: boolean;
  isErrorOnCompanyAddress: boolean;
  isErrorOnZipCode: boolean;
  isErrorOnCity: boolean;
  isErrorOnCountry: boolean;
  isErrorOnWebsite: boolean;
  errorMessage: string;
  currentCompanyActivity: ForegroundOption;
  currentCompanyCountry: ForegroundOption;

  companyActivities: CompanyActivity[];
  companyActivitiesOptions: ForegroundOption[];
  countryList: Country[];
  countryListOptions: ForegroundOption[];

  constructor() {
    this.isErrorOnCompanyName = false;
    this.isErrorOnCompanyActivity = false;
    this.isErrorOnCompanyDescription = false;
    this.isErrorOnCompanyAddress = false;
    this.isErrorOnZipCode = false;
    this.isErrorOnCity = false;
    this.isErrorOnCountry = false;
    this.isErrorOnWebsite = false;
    this.errorMessage = '';
    this.currentCompanyActivity = null;
    this.currentCompanyCountry = null;

    this.companyActivities = [];
    this.companyActivitiesOptions = [];
    this.countryList = [];
    this.countryListOptions = [];
  }
}

export class CompanyInformationStep extends Component<CompanyWizardStepProps, CompanyInformationStepState> {
  constructor(props) {
    super(props);
    this.state = new CompanyInformationStepState();
    this.handleTranslateValueChanged = this.handleTranslateValueChanged.bind(this);
    this.handleImageChanged = this.handleImageChanged.bind(this);
    this.handleChangeCompanyActivity = this.handleChangeCompanyActivity.bind(this);
    this.handleChangeCompanyCountry = this.handleChangeCompanyCountry.bind(this);
  }

  async componentDidMount() {
    try {
      const response = await Promise.all([CompanyService.activitiesList(), CountryService.list('countryName')]);
      const isCreation = !!!this.props.company.id;
      const activity = !!this.props.company.activity ? response[0].data.find((a) => a.id === this.props.company.activity.id) : null;
      const country = !isCreation ? response[1].data.find((a) => a.id === this.props.company.countryId) : null;
      const companyActivities = response[0].data.map((item) => {
        return new ForegroundOption(item.label, item.id);
      });
      const countryList = response[1].data.map((item) => {
        return new ForegroundOption(item.countryName, item.id);
      });
      this.setState({
        companyActivities: response[0].data,
        companyActivitiesOptions: companyActivities,
        countryList: response[1].data,
        countryListOptions: countryList,
        currentCompanyActivity: !!activity ? new ForegroundOption(activity.label, activity.id) : null,
        currentCompanyCountry: !!country ? new ForegroundOption(country.countryName, country.id) : null,
      });
    } catch (e) {
      console.error(e);
    }
  }

  handleImageChanged(image: string, fileName: string) {
    this.props.company.logo.fileName = fileName;
    this.props.company.logo.fileContent = image;
  }

  handleTranslateValueChanged() {
    if (this.props.company.description.trim().length !== 0 && this.props.company.description.trim() !== EMPTY_DESCRIPTION) {
      this.setState({ isErrorOnCompanyDescription: false });
    } else {
      this.setState({ isErrorOnCompanyDescription: true, errorMessage: 'Please fill english description' });
    }
  }

  handleChangeCompanyActivity(event: ForegroundOption) {
    const activity = this.state.companyActivities.find((a) => a.id === event.value);
    this.props.company.activity = activity;
    this.setState({ currentCompanyActivity: event, isErrorOnCompanyActivity: false });
  }

  handleChangeCompanyCountry(event: ForegroundOption) {
    const country = this.state.countryList.find((a) => a.id === event.value);
    this.props.company.country = country.countryName;
    this.props.company.countryId = country.id;

    this.setState({ currentCompanyCountry: event, isErrorOnCountry: false });
  }

  validate() {
    if (this.props.isReadOnly) {
      return true;
    }
    const companyName = this.props.company.name;
    if (!!!companyName || companyName.trim().length === 0) {
      this.setState({ isErrorOnCompanyName: true, errorMessage: MANDATORY_FIELD_MISSING });
      return false;
    }

    const website = this.props.company.website;
    if (!!!website || website.trim().length === 0) {
      this.setState({ isErrorOnWebsite: true, errorMessage: 'Please fill website' });
      return false;
    }
    if (!WEB_URL_REGEX.test(website)) {
      this.setState({ isErrorOnWebsite: true, errorMessage: BAD_WEB_URL });
      return false;
    }
    const description = this.props.company.description;
    if (!!!description || description.trim().length === 0 || description.trim() === EMPTY_DESCRIPTION) {
      this.setState({ isErrorOnCompanyDescription: true, errorMessage: 'Please fill english description' });
      return false;
    }

    if (!!!this.props.company.activity) {
      this.setState({ isErrorOnCompanyActivity: true, errorMessage: PLEASE_SELECT_COMPANY_ACTIVITY });
      return false;
    }
    const address = this.props.company.address;
    if (!!!address || address.trim().length === 0) {
      this.setState({ isErrorOnCompanyAddress: true, errorMessage: MANDATORY_FIELD_MISSING });
      return false;
    }

    const zipcode = this.props.company.zipcode;
    if (!!!zipcode || zipcode.trim().length === 0) {
      this.setState({ isErrorOnZipCode: true, errorMessage: MANDATORY_FIELD_MISSING });
      return false;
    }
    const city = this.props.company.city;
    if (!!!city || city.trim().length === 0) {
      this.setState({ isErrorOnCity: true, errorMessage: MANDATORY_FIELD_MISSING });
      return false;
    }
    if (!!!this.props.company.countryId) {
      this.setState({ isErrorOnCountry: true, errorMessage: PLEASE_SELECT_COUNTRY });
      return false;
    }

    return true;
  }

  render() {
    if (this.props.shouldDisplay) {
      const companyActivities = this.state.companyActivitiesOptions;
      const countryList = this.state.countryListOptions;
      const currentCompanyActivity = this.state.currentCompanyActivity;
      const currentCompanyCountry = this.state.currentCompanyCountry;

      // const baseUrl = window.location.hostname === 'localhost' ? 'https://foreground-v3-pre-prod.apps.fr01.paas.tech.orange' : '';
      const baseUrl = process.env.REACT_APP_BASE_URL_API;

      return (
        <>
         <div className='row'>
          <div className='col-md-4 mt-1 mb-3'>All mandatory fields are marked with an *</div>
          <div className='row'>
            <div className='col-md-4'>
            <label id='labelName' className='is-required' htmlFor='companyName'>
                Company name
              </label>
            </div>
            <div className='col-md-4 offset-md-1'>
              <label id='labelWebsite' className='is-required' htmlFor='companyWebSite'>
                Website
              </label>
            </div>
          </div>
          <div className='row mt-2'>
            <div className='col-md-4'>
              <input
                className='w-100 form-control'
                type='text'
                id='companyName'
                name='companyName'
                maxLength={191}
                defaultValue={this.props.company.name}
                onChange={(e) => {
                  this.props.company.name = e.target.value;
                  if (e.target.value.trim().length === 0) {
                    this.setState({ isErrorOnCompanyName: true, errorMessage: MANDATORY_FIELD_MISSING });
                  } else {
                    this.setState({ isErrorOnCompanyName: false });
                  }
                }}
                required
                readOnly={this.props.isReadOnly}
                disabled={this.props.isReadOnly || this.props.scope === FOREGROUND_SCOPE.PARTNER}
                aria-describedby={this.state.isErrorOnCompanyName ? 'error-companyName' : ''}
                aria-invalid={this.state.isErrorOnCompanyName}
                aria-required='true'
              ></input>
              {this.state.isErrorOnCompanyName ? (
                <p id='error-companyName' data-testid='error-companyName' className='errorMessage mt-2'>
                  {this.state.errorMessage}
                </p>
              ) : null}
            </div>
            <div className='col-md-4 offset-md-1'>
              <input
                className='w-100 form-control'
                type='text'
                id='companyWebSite'
                placeholder='http://....'
                maxLength={191}
                defaultValue={this.props.company.website}
                onChange={(e) => {
                  this.props.company.website = e.target.value;
                  if (WEB_URL_REGEX.test(e.target.value.trim())) {
                    this.setState({ isErrorOnWebsite: false });
                  } else {
                    this.setState({ isErrorOnWebsite: true, errorMessage: BAD_WEB_URL });
                  }
                }}
                readOnly={this.props.isReadOnly}
                disabled={this.props.isReadOnly}
                aria-describedby={this.state.isErrorOnWebsite ? 'error-website' : ''}
                aria-invalid={this.state.isErrorOnWebsite}
                aria-required='true'
              ></input>
              {this.state.isErrorOnWebsite ? (
                <p id='error-website' data-testid='error-website' className='errorMessage mt-2'>
                  {this.state.errorMessage}
                </p>
              ) : null}
            </div>
          </div>

          <div className='row mt-4'>
            <div className='col-md-4'>
              <label id='socialLabelName' htmlFor='socialNetwork'>
                Social network
              </label>
            </div>
          </div>
          <div className='row mt-2'>
            <div className='col-md-4'>
              <input
                className='w-100 form-control'
                type='text'
                id='socialNetwork'
                name='socialNetwork'
                maxLength={191}
                defaultValue={this.props.company.socialNetwork}
                onChange={(e) => {
                  this.props.company.socialNetwork = e.target.value;
                }}
                readOnly={this.props.isReadOnly}
                disabled={this.props.isReadOnly || this.props.scope === FOREGROUND_SCOPE.PARTNER}
              ></input>
            </div>
          </div>

          <div className='row mt-4'>
            <div className='col-md-9'>
              <label id='labelDescription' className='is-required'>
                Company description
              </label>
              <div className='mt-2'></div>

              <ManageTranslation
                manageObject={this.props.company}
                field='description'
                isReadOnly={this.props.isReadOnly}
                ariaDescribedBy={this.state.isErrorOnCompanyDescription ? 'error-companyDescription' : ''}
                ariaInvalid={this.state.isErrorOnCompanyDescription}
                ariaLabel={'labelDescription'}
                handleTranslateValueChanged={this.handleTranslateValueChanged}
              ></ManageTranslation>

              {this.state.isErrorOnCompanyDescription ? (
                <p id='error-companyDescription' className='errorMessage mt-2'>
                  {this.state.errorMessage}
                </p>
              ) : null}
            </div>
          </div>
          <div className='row col-md-4 mt-4'>
            <label id='labelActivity' htmlFor='activity' className='is-required'>
              Activity
            </label>
            <ForegroundSelectSingle
              inputId='activity'
              placeholder={PLEASE_SELECT_COMPANY_ACTIVITY}
              value={currentCompanyActivity}
              options={companyActivities}
              isClearable={false}
              onChange={(e) => this.handleChangeCompanyActivity(e)}
              isDisabled={this.props.isReadOnly}
              ariaDescribedBy={this.state.isErrorOnCompanyActivity ? 'error-companyActivity' : ''}
              ariaInvalid={this.state.isErrorOnCompanyActivity}
              ariaRequired={true}
            />
            {this.state.isErrorOnCompanyActivity ? (
              <p id='error-companyActivity' className='errorMessage mt-2'>
                {this.state.errorMessage}
              </p>
            ) : null}
          </div>
          <div className='row mt-4'>
            <div className='col-md-9'>
              <label id='labelAddress' className='is-required' htmlFor='companyAddress'>
                Address
              </label>
              <div className='mt-2'></div>
              <input
                className='w-100 form-control'
                type='text'
                id='companyAddress'
                defaultValue={this.props.company.address}
                onChange={(e) => {
                  this.props.company.address = e.target.value;
                  if (this.props.company.address.trim().length !== 0) {
                    this.setState({ isErrorOnCompanyAddress: false });
                  } else {
                    this.setState({ isErrorOnCompanyAddress: true, errorMessage: MANDATORY_FIELD_MISSING });
                  }
                }}
                required
                readOnly={this.props.isReadOnly}
                disabled={this.props.isReadOnly}
                aria-describedby={this.state.isErrorOnCompanyAddress ? 'error-companyAddress' : ''}
                aria-invalid={this.state.isErrorOnCompanyAddress}
                aria-required='true'
              ></input>
              {this.state.isErrorOnCompanyAddress ? (
                <p id='error-companyAddress' data-testid='error-companyAddress' className='errorMessage mt-2'>
                  {this.state.errorMessage}
                </p>
              ) : null}
            </div>
          </div>
          <div className='row mt-4'>
            <div className='col-md-3'>
              <label id='labelZipCode' className='is-required' htmlFor='companyZipCode'>
                Zip code
              </label>
              <input
                className='w-100 form-control mt-2'
                type='text'
                id='companyZipCode'
                defaultValue={this.props.company.zipcode}
                onChange={(e) => {
                  this.props.company.zipcode = e.target.value;
                  if (this.props.company.zipcode.trim().length !== 0) {
                    this.setState({ isErrorOnZipCode: false });
                  } else {
                    this.setState({ isErrorOnZipCode: true, errorMessage: MANDATORY_FIELD_MISSING });
                  }
                }}
                required
                readOnly={this.props.isReadOnly}
                disabled={this.props.isReadOnly}
                aria-describedby={this.state.isErrorOnZipCode ? 'error-zipCode' : ''}
                aria-invalid={this.state.isErrorOnZipCode}
                aria-required='true'
              ></input>
              {this.state.isErrorOnZipCode ? (
                <p id='error-zipCode' data-testid='error-zipCode' className='errorMessage mt-2'>
                  {this.state.errorMessage}
                </p>
              ) : null}
            </div>
            <div className='col-md-3 ml-6'>
              <label id='labelCity' className='is-required' htmlFor='companyCity'>
                City
              </label>
              <input
                className='w-100 form-control mt-2'
                type='text'
                id='companyCity'
                defaultValue={this.props.company.city}
                onChange={(e) => {
                  this.props.company.city = e.target.value;
                  if (this.props.company.city.trim().length !== 0) {
                    this.setState({ isErrorOnCity: false });
                  } else {
                    this.setState({ isErrorOnCity: true, errorMessage: MANDATORY_FIELD_MISSING });
                  }
                }}
                required
                readOnly={this.props.isReadOnly}
                disabled={this.props.isReadOnly}
                aria-describedby={this.state.isErrorOnCity ? 'error-city' : ''}
                aria-invalid={this.state.isErrorOnCity}
                aria-required='true'
              ></input>
              {this.state.isErrorOnCity ? (
                <p id='error-city' data-testid='error-city' className='errorMessage mt-2'>
                  {this.state.errorMessage}
                </p>
              ) : null}
            </div>
            <div className='col-md-3 ml-6'>
              <label id='labelCountry' className='is-required' htmlFor='companyCountry'>
                Country
              </label>
              <ForegroundSelectSingle
                inputId='companyCountry'
                placeholder={PLEASE_SELECT_COUNTRY}
                value={currentCompanyCountry}
                options={countryList}
                onChange={(e) => this.handleChangeCompanyCountry(e)}
                isClearable={false}
                isDisabled={this.props.isReadOnly}
                ariaDescribedBy={this.state.isErrorOnCountry ? 'error-country' : ''}
                ariaInvalid={this.state.isErrorOnCountry}
                ariaRequired={true}
              />
              {this.state.isErrorOnCountry ? (
                <p id='error-country' className='errorMessage mt-2'>
                  {this.state.errorMessage}
                </p>
              ) : null}
            </div>
          </div>

          <div className='row mt-4'>
            <div className='col-md-2'>
              <label className=''>Company logo</label>
            </div>
          </div>
          <div className='row mt-2 '>
            <ImageLoader
              imageUrl={
                !!this.props.company.logo && !!this.props.company.logo.link ? `${baseUrl}/public/storage/img/${this.props.company.logo.link}` : null
              }
              altLabel={`${this.props.company.name} logo`}
              handleImageChanged={this.handleImageChanged}
              isError={false}
              isReadOnly={this.props.isReadOnly}
            ></ImageLoader>
          </div>
        </div>
          {BackToTop}
        </>
      );
    } else {
      return null;
    }
  }
}
