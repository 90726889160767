import React, { Component } from 'react';

export class ContactUs extends Component {
  render() {
    return (
      <div className={`banner yellow-banner pt-5 w-text`}>
        <div className={`container`}>
          <div className={`row`}>
            <div className={`col-md-4`}>
              <h3>
                Contact us &nbsp;
                <i className={`foreground-Email icon-10 align-middle`} aria-hidden="true"></i>
              </h3>
              <p className="fs-4">Our team will be happy to answer any question regarding Foreground.</p>
              <a href={`mailto:${process.env.REACT_APP_MAIL_TO}`} className={`btn btn-secondary`}>
                Email
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
