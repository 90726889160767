import { Component } from 'react';
import CompanyService from '../../services/company.service';
import LoadingSpinner from '../../components/LoadingSpinner';

import './company-management.css';
import Pagination from '../../components/Pagination';
import ProductService from '../../services/product.service';
import ProductCard from '../product_management/productCard';
import { FOREGROUND_SCOPE, ORANGE_ADMIN_SCOPE, PARTNER_SCOPE } from '../../models/constants';
import Utils from '../../helpers/Utils';
import { AppProps, PropsMatch } from '../../models/Props';
import { ReducedDevice } from '../../models/device';
import { Company } from '../../models/company';
import { PageHeader } from '../../components/PageHeader';
import { BackToTop } from '../../components/backToTopButton';

const NB_DEVICES_PER_PAGE = 4;

class CompanyInformationsProps {
  public appProps: AppProps;
  public history: any;
  public match: PropsMatch;
}

class CompanyInformationsState {
  deviceList: ReducedDevice[];

  isLoading: boolean;
  nbPages: number;
  company: Company;

  currentPage: number;
  nbDevices: number;

  companyEmailsDisplayedListing: string;

  constructor() {
    this.deviceList = [];
    this.companyEmailsDisplayedListing = "";

    this.isLoading = true;
    this.nbPages = 1;
    this.company = null;

    this.currentPage = 0;
    this.nbDevices = 0;
  }
}

export class CompanyInformations extends Component<CompanyInformationsProps, CompanyInformationsState> {
  constructor(props) {
    super(props);
    this.state = new CompanyInformationsState();
    this.handlePageClick = this.handlePageClick.bind(this);
  }

  async componentDidMount() {
    try {
      const companySlug = this.props.match.params.id;
      const userScope = !!this.props.appProps.scope ? this.props.appProps.scope : null;
      const response = await CompanyService.companiesDetails(companySlug);
      const company = response.data;

      let companyEmailsList = null;
      let companyEmailsDisplayedListing = "";
      
      if (!!userScope && userScope === FOREGROUND_SCOPE.ORANGE_ADMIN) {
        companyEmailsList = !!company.userEmails ? company.userEmails : [];
        companyEmailsDisplayedListing = companyEmailsList?.map(item => item).join(', ') || '';
      }

      const companyDevices = await ProductService.productsList(0, NB_DEVICES_PER_PAGE, null, null, company.id, null, null, null, null, null, null);

      const nbDevices = companyDevices.headers['x-total-count'];
      const nbPages = Math.ceil(nbDevices / NB_DEVICES_PER_PAGE);
      this.setState({
        deviceList: companyDevices.data,
        company: company,
        companyEmailsDisplayedListing: companyEmailsDisplayedListing,
        isLoading: false,
        nbPages: nbPages,
        nbDevices: nbDevices,
      });
    } catch (error) {
      console.log(`ERROR CompanyInformations : ${JSON.stringify(error)}`);
      this.setState({ isLoading: false });
    }
  }

  async handlePageClick(event) {
    const companyDevices = await ProductService.productsList(
      event.selected * NB_DEVICES_PER_PAGE,
      NB_DEVICES_PER_PAGE,
      null,
      null,
      `${this.state.company.id}`,
      null,
      null,
      null,
      null,
      null,
      null
    );
    this.setState({ currentPage: event.selected, deviceList: companyDevices.data });
  }

  renderInfo() {
    const scope = this.props.appProps.scope;
    const baseUrl = process.env.REACT_APP_BASE_URL_API;
    // const baseUrl = window.location.hostname === 'localhost' || '127.0.0.1' ? 'https://foreground-v3-pre-prod.apps.fr01.paas.tech.orange' : '';

    const company = this.state.company;
    const productsList = this.state.deviceList;
    const companyEmailsDisplayedListing = this.state.companyEmailsDisplayedListing;

    const createdAt = !this.state.isLoading ? Utils.formatDateToWeb(company.createdAt) : '';
    const updatedAt = !this.state.isLoading ? Utils.formatDateToWeb(company.updatedAt) : '';

    document.title = company.name+' - Foreground V3';
    return (
      <>
        <PageHeader title={company.name}></PageHeader>

        <div className="row mt-2">
          <div className="col-md-4">
            {!!company.logo && !!company.logo.link ? (
              <img
                className="company-img img-no-stretch"
                src={`${baseUrl}/public/storage/img/${company.logo.link}`}
                alt={`${company.name}'s logo`}
                onError={(e) => {
                  (e.target as HTMLImageElement).onerror = null;
                  (e.target as HTMLImageElement).remove();
                }}
              />
            ) : (
              <div className="company-img"></div>
            )}
          </div>
          <div className='row col-md-8'>
            <div className='col-md-6'>
              <div className='row mb-3'>
                <div className='col-md-5'>
                  <p className='text-muted'>
                    <strong>Headquarters</strong>
                  </p>
                </div>
                <div className='col-md-7'>
                  <strong id='headquarters'>
                    {company.name} <br />
                    {company.address} <br />
                    {company.zipcode} {company.city}
                  </strong>
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col-md-5'>
                  <p className='text-muted'>
                    <strong>Web</strong>
                  </p>
                </div>
                <div className='col-md-7'>
                  <strong>
                    <a id='website' href={company.website} target='_blank' rel='noopener noreferrer'>
                      {company.website}
                    </a>
                  </strong>
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col-md-5'>
                  <p className='text-muted'>
                    <strong>Main social network</strong>
                  </p>
                </div>
                <div className='col-md-7'>
                  <strong>
                    <a id='socialnetwork' href={company.socialNetwork} target='_blank' rel='noopener noreferrer'>
                      {company.socialNetwork}
                    </a>
                  </strong>
                </div>
              </div>
            </div>
            <div className='col-md-4 offset-md-1'>
              <div className='pb-4 '>
                <div>
                  <strong>Registered: {createdAt} </strong>
                </div>
                <div>
                  <strong>Last updated: {updatedAt}</strong>
                </div>
              </div>
              <div className='w-250'>
                {scope === ORANGE_ADMIN_SCOPE || scope === PARTNER_SCOPE ? (
                  <button
                    id='editCompany'
                    className='btn btn-dark btn-block mt-3'
                    onClick={() => {
                      this.props.history.push(`/companies/${company.slug}/edit`);
                    }}
                  >
                    <span className='icon foreground-ic_Pencil me-2' aria-hidden='true'></span> Edit informations
                  </button>
                ): null}
                {this.props.appProps.canDeleteCompaniesAndDevices ? (
                  <div className='w-250'>
                    <button
                      id='deleteCompany'
                      className='btn btn-dark btn-block mt-3'
                      onClick={() => {
                        CompanyService.deleteCompany(company.slug);
                        this.props.history.push('/companies');
                      }}
                    >
                      <span className='icon foreground-Trash me-2' aria-hidden='true'></span> Delete
                    </button>
                  </div>
                ) : null}
              </div>
            </div>
            {this.props.appProps.scope === ORANGE_ADMIN_SCOPE ? (
              <div className='col'>
                <div className='row mb-2'>
                  <div className='col-md-2 ml-3'>
                    <p className='text-muted'>
                      <strong>Emails list</strong>
                    </p>
                  </div>
                  <div className='col-md-9 ml-3'>
                    <strong>
                      {companyEmailsDisplayedListing}
                    </strong>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        {/*Activity and distribution */}
        <div className="mb-3">
          <div className="border-bottom border-light mt-5 pb-2 mb-4">
            <h2>Activity and distribution</h2>
          </div>
          <div className="row mb-3">
            <div className="col-md-3">
              <p className="text-muted">
                <strong>Activity</strong>
              </p>
            </div>
            <div className="col-md-6">
              <strong id="activity">{company.activity.label}</strong>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-3">
              <p className="text-muted">
                <strong>Description</strong>
              </p>
            </div>
            <div className="col-md-6">
              <strong id="description" dangerouslySetInnerHTML={{ __html: company.description }}></strong>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-3">
              <p className="text-muted">
                <strong>Distribution strategy</strong>
              </p>
            </div>
            <div className="col-md-6">
              <strong id="distributionStrategy">{!!company.customerReferences ? company.customerReferences.distributionStrategy : ''}</strong>
            </div>
          </div>
        </div>

        {/* Project with Orange */}
        <div className="mb-3">
          <div className="border-bottom border-light mt-5 pb-2 mb-4">
            <h2>Project with Orange</h2>
          </div>
          <div className="row mb-3">
            <div className="col-md-3">
              <p className="text-muted">
                <strong>IoT activity</strong>
              </p>
            </div>
            <div className="col-md-6">
              <div className="mb-2">
                <strong
                  id="jointProjectWithOrange"
                  dangerouslySetInnerHTML={{ __html: !!company.jointProjectWithOrange ? company.jointProjectWithOrange : '' }}
                ></strong>
              </div>
            </div>
          </div>
        </div>

        {/* Customer reference */}
        <div className="mb-3">
          <div className="border-bottom border-light mt-5 pb-2 mb-4">
            <h2>Customer reference</h2>
          </div>
          <div className="row mb-3">
            <div className="col-md-3">
              <p className="text-muted">
                <strong>IoT references</strong>
              </p>
            </div>
            <div className="col-md-6">
              <div className="mb-2">
                <strong
                  id="customerReferencesInIot"
                  dangerouslySetInnerHTML={{ __html: !!company.customerReferences ? company.customerReferences.customerReferencesInIot : '' }}
                ></strong>
              </div>
            </div>
          </div>
        </div>

        {/* Product range and rodmap */}
        <div className="mb-3">
          <div className="border-bottom border-light mt-5 pb-2 mb-4">
            <h2>Product range and rodmap</h2>
          </div>
          <div className="row mb-3">
            <div className="col-md-3">
              <p className="text-muted">
                <strong>Product range</strong>
              </p>
            </div>
            <div className="col-md-6">
              <div className="mb-2">
                <strong id="productsRange" dangerouslySetInnerHTML={{ __html: company.productsRange }}></strong>
              </div>
            </div>
          </div>
        </div>

        {/* Finance */}
        <div className="mb-3">
          <div className="border-bottom border-light mt-5 pb-2 mb-4">
            <h2>Finance</h2>
          </div>
          <div className="row mb-3">
            <div className="col-md-3">
              <p className="text-muted">
                <strong>Date of creation of the IoT activity</strong>
              </p>
            </div>
            <div className="col-md-6">
              <strong id="creationIotActivity">
                {!!company.financeAndSustainability ? Utils.formatDateToWeb(company.financeAndSustainability.creationIotActivity) : ''}
              </strong>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-3">
              <p className="text-muted">
                <strong>Headcount in IoT</strong>
              </p>
            </div>
            <div className="col-md-6">
              <strong>
                <p id="headCountIot">
                  {!!company.financeAndSustainability
                    ? company.financeAndSustainability.headCountIot > 0
                      ? company.financeAndSustainability.headCountIot
                      : ''
                    : ''}
                </p>
              </strong>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-3">
              <p className="text-muted">
                <strong>Shareholders of the IoT activity</strong>
              </p>
            </div>
            <div className="col-md-6">
              <strong id="stackholdersIotSusbsidiary">
                {!!company.financeAndSustainability ? company.financeAndSustainability.stackholdersIotSusbsidiary : ''}
              </strong>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-3">
              <p className="text-muted">
                <strong>Company capital</strong>
              </p>
            </div>
            <div className="col-md-6">
              <strong>
                <p id="companyCapital">
                  {!!company.financeAndSustainability
                    ? company.financeAndSustainability.companyCapital > 0
                      ? `${company.financeAndSustainability.companyCapital}${company.financeAndSustainability.companyCapitalUnit}`
                      : ''
                    : ''}
                </p>
              </strong>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-3">
              <p className="text-muted">
                <strong>Turn over </strong>
              </p>
            </div>
            <div className="col-md-6">
              <strong>
                <p id="turnoverLastYear">
                  {!!company.financeAndSustainability
                    ? company.financeAndSustainability.turnoverLastYear > 0
                      ? `${company.financeAndSustainability.turnoverLastYear}${company.financeAndSustainability.turnoverLastYearUnit}`
                      : ''
                    : ''}
                </p>
              </strong>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-3">
              <p className="text-muted">
                <strong>Monthly volume of production (units)</strong>
              </p>
            </div>
            <div className="col-md-6">
              <strong>
                <p id="volumeOfProduction">
                  {!!company.financeAndSustainability
                    ? company.financeAndSustainability.volumeOfProduction > 0
                      ? company.financeAndSustainability.volumeOfProduction
                      : ''
                    : ''}
                </p>
              </strong>
            </div>
          </div>
        </div>

        {/* R&D */}
        <div className="mb-3">
          <div className="border-bottom border-light mt-5 pb-2 mb-4">
            <h2>R&D</h2>
          </div>
          <div className="row mb-3">
            <div className="col-md-2">
              <p className="text-muted">
                <strong>Locations</strong>
              </p>
            </div>
            <div className="col-md-6">
              <strong id="rdLocation">{!!company.rdPolicy ? company.rdPolicy.rdLocation : ''}</strong>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-2">
              <p className="text-muted">
                <strong>Number of engineers</strong>
              </p>
            </div>
            <div className="col-md-6">
              <strong>
                <p id="nbEngineersRd">{!!company.rdPolicy && company.rdPolicy.nbEngineersRd > 0 ? company.rdPolicy.nbEngineersRd : ''}</p>
              </strong>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-2">
              <p className="text-muted">
                <strong>Annual spending</strong>
              </p>
            </div>
            <div className="col-md-6">
              <strong>
                <p id="rdAnnualSpending">
                  {!!company.rdPolicy
                    ? company.rdPolicy.rdAnnualSpending > 0
                      ? `${company.rdPolicy.rdAnnualSpending}${company.rdPolicy.rdAnnualSpendingMoney}`
                      : ''
                    : ''}
                </p>
              </strong>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-2">
              <p className="text-muted">
                <strong>Patent portfolio</strong>
              </p>
            </div>
            <div className="col-md-6">
              <strong id="patentPortfolio">{!!company.rdPolicy ? company.rdPolicy.patentPortfolio : ''}</strong>
            </div>
          </div>
        </div>

        {/* Quality policy */}
        <div className="mb-3">
          <div className="border-bottom border-light mt-5 pb-2 mb-4">
            <h2>Quality policy production</h2>
          </div>
          <div className="row mb-3">
            <div className="col-md-2">
              <p className="text-muted">
                <strong>Certification</strong>
              </p>
            </div>
            <div className="col-md-6">
              <strong id="isoCertificationPolicy">
                {!!company.qualityPolicyProduction ? company.qualityPolicyProduction.isoCertificationPolicy : ''}
              </strong>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-2">
              <p className="text-muted">
                <strong>Production country</strong>
              </p>
            </div>
            <div className="col-md-6">
              <strong id="productionCapacityAndLocation">
                {!!company.qualityPolicyProduction ? company.qualityPolicyProduction.productionCapacityAndLocation : ''}
              </strong>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-2">
              <p className="text-muted">
                <strong>Production partners</strong>
              </p>
            </div>
            <div className="col-md-6">
              <strong id="productionPartners">{!!company.qualityPolicyProduction ? company.qualityPolicyProduction.productionPartners : ''}</strong>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-2">
              <p className="text-muted">
                <strong>Corporate sourcing contract</strong>
              </p>
            </div>
            <div className="col-md-6">
              <strong id="nda">
                {!!company.corporateSourcingContract
                  ? `${Utils.formatDateToWeb(company.corporateSourcingContract.signature)} - ${Utils.formatDateToWeb(
                      company.corporateSourcingContract.expiration
                    )}`
                  : ''}
              </strong>
            </div>
          </div>
          {scope === FOREGROUND_SCOPE.ORANGE_ADMIN || scope === FOREGROUND_SCOPE.ORANGE_USER ? (
            <div className="row mb-3">
              <div className="col-md-2">
                <p className="text-muted">
                  <strong>Comment</strong>
                </p>
              </div>
              <div className="col-md-6">
                <strong id="ndaComment">{!!company.corporateSourcingContract ? company.corporateSourcingContract.commentary : ''}</strong>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>

        {/* ESG/CSR */}
        <div className="mb-3">
          <div className="border-bottom border-light mt-5 pb-2 mb-4">
            <h2>ESG / CSR</h2>
          </div>
          <div className="row mb-3">
            <div className="col-md-3">
              <p className="text-muted">
                <strong>ESG/CSR policy</strong>
              </p>
            </div>
            <div className="col-md-6">
              <strong id="esgCSRAdditionalInformation">
                {!!company.sustainableDevelopment ? company.sustainableDevelopment.esgCSRAdditionalInformation : ''}
              </strong>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-3">
              <p className="text-muted">
                <strong>CSR policy evaluated by Ecovadis or similar</strong>
              </p>
            </div>
            <div className="col-md-6">
              <strong id="csrPolicyEvaluated">
                {!!company.sustainableDevelopment && (company.sustainableDevelopment.csrPolicyEvaluated ? 'Yes' : 'No')}
              </strong>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-3">
              <p className="text-muted">
                <strong>Sustainable procurement policy</strong>
              </p>
            </div>
            <div className="col-md-6">
              <strong id="sustainableProcurementAdditionalInformation">
                {!!company.sustainableDevelopment ? company.sustainableDevelopment.sustainableProcurementAdditionalInformation : ''}
              </strong>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-3">
              <p className="text-muted">
                <strong>Contact</strong>
              </p>
            </div>
            <div className="col-md-6">
              <strong id="isoCertificationPolicy">{!!company.sustainableDevelopment ? company.sustainableDevelopment.esgCSRContact : ''}</strong>
            </div>
          </div>
        </div>

        {/* Products */}
        <div className="mb-3">
          <div className="border-bottom border-light mt-5 pb-2 mb-4">
            <h2>Products ({this.state.nbDevices})</h2>
          </div>
          <div>
            <div className="products-list" style={{ margin: 'auto' }}>
              {!!productsList
                ? productsList.map((product) => {
                    return <ProductCard product={product} history={this.props.history} key={product.id} showCompany={false}></ProductCard>;
                  })
                : null}
            </div>
          </div>
          {this.state.nbPages > 1 ? <Pagination handlePageClick={this.handlePageClick} nbPages={this.state.nbPages} /> : ''}
        </div>

        {/* Documents */}
        <div className="mb-3">
          <div className="border-bottom border-light mt-5 pb-2 mb-4">
            <h2>Documents</h2>
          </div>
          <div className="mb-3">
            <div className="mt-5 pb-2 mb-4">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col" className="col-md-7">
                      <p className="text-muted text-small">Name</p>
                    </th>
                    <th scope="col">
                      <p className="text-muted text-small">Type</p>
                    </th>
                    <th scope="col">
                      <p className="text-muted text-small">Category</p>
                    </th>
                    <th scope="col">
                      <p className="text-muted text-small">Updated at</p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {!!company.documents
                    ? company.documents.map((d) => {
                        return (
                          <tr key={d.id}>
                            <td>
                              <a href={`${baseUrl}/public/storage/files/${d.link}`} target="_blank" rel="noopener noreferrer">
                                {d.name}
                              </a>
                            </td>

                            <td>
                              <img
                                className="document-extension"
                                src={Utils.getIconForDocument(d.link)}
                                alt={Utils.getExtensionDocumentForAlt(d.link)}
                              />
                            </td>
                            <td>{d.type.label}</td>

                            <td>{Utils.formatDateToWebWithTime(d.updatedAt)}</td>
                            <td></td>
                          </tr>
                        );
                      })
                    : null}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {BackToTop}
      </>
    );
  }

  render() {
    return <div className="p-3 bg-white">{this.state.isLoading ? <LoadingSpinner /> : this.renderInfo()}</div>;
  }
}
