import React, { Component } from 'react';
import './home.css';

export class WhyCertification extends Component {
  render() {
    return (
      <div className="grey-banner p-4">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h3>Certification is key</h3>
              <div className="row mt-5">
                <div className="col-md-5 px-4 mb-3 text-center">
                  <div>
                    <i className="foreground-Antenna icon-40" aria-hidden="true"></i>
                  </div>
                  <h4 className="font-size-1125"> Detecting network issues </h4>
                </div>
                <div className="col-md-5 px-4 mb-3 text-center">
                  <div>
                    <i className="foreground-Battery_full icon-40" aria-hidden="true"></i>
                  </div>
                  <h4 className="font-size-1125"> Testing battery life </h4>
                </div>
                <div className="col-md-5 px-4 mb-3 text-center">
                  <div>
                    <i className="foreground-Security_status_critical icon-40" aria-hidden="true"></i>
                  </div>
                  <h4 className="font-size-1125"> Checking security </h4>
                </div>
                <div className="col-md-5 px-4 mb-3 text-center">
                  <div>
                    <i className="foreground-Fire icon-40" aria-hidden="true"></i>
                  </div>
                  <h4 className="font-size-1125"> Preventing customer crisis </h4>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="ratio ratio-16x9">
                <iframe
                  className="ratio ratio-16x9"
                  src="https://www.youtube.com/embed/JRRjYyT37gM"
                  allowFullScreen
                  title="The challenges of the IoT"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
