import { Component } from 'react';
import { SignupLogin } from '../../components/homepage/SignupLogin';
import { MembershipAdvantages } from '../../components/homepage/MembershipAdvantages';
import { ForegroundCatalog } from '../../components/homepage/ForegroundCatalog';
import { PartnersTestimonials } from '../../components/homepage/PartnersTestimonials';
import { WhyCertification } from '../../components/homepage/WhyCertification';
import { ContactUs } from '../../components/homepage/ContactUs';
import { connect } from 'react-redux';
import { AppProps } from '../../models/Props';

export class Accueil extends Component<AppProps, AppProps> {
  constructor(props) {
    super(props);
    this.state = new AppProps(
      props.token,
      props.userid,
      props.firstname,
      props.lastname,
      props.scope,
      props.companySlug,
      props.canDeleteCompaniesAndDevices
    );
  }

  render() {
    document.title = 'Home - Foreground V3';
    return (
      <div className="main-content">
        <div className={`py-5 banner-1 introSection ${this.props.token ? ' pb-0' : ''}`}>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-12">
                <h2 id="home-title" className="display-2 mb-5">
                  IoT Ecosystem for partners, devices and solutions
                </h2>
                {this.props.token ? '' : <SignupLogin></SignupLogin>}
              </div>
            </div>
          </div>
        </div>
        <MembershipAdvantages></MembershipAdvantages>
        <ForegroundCatalog></ForegroundCatalog>
        <PartnersTestimonials></PartnersTestimonials>
        <WhyCertification></WhyCertification>
        <ContactUs></ContactUs>
        <div className="p-2 dark-gray"></div>
      </div>
    );
  }
}

const mapStateToProps = (state: AppProps) => {
  return { token: state.token, userid: state.userid, firstname: state.firstname, lastname: state.lastname, scope: state.scope };
};

export default connect(mapStateToProps, null)(Accueil);
