import React, { Component } from 'react';
import ProductService from '../../services/product.service';
import ProductCard from './productCard';
import LoadingSpinner from '../../components/LoadingSpinner';
import { createBrowserHistory } from 'history';
import './product_management.css';
import Pagination from '../../components/Pagination';
import { ORANGE_ADMIN_SCOPE, ORANGE_USER_SCOPE, ORANGE_VALIDATION_SCOPE, PARTNER_SCOPE, ORANGE_PARTNER_SCOPE } from '../../models/constants';
import { ForegroundSelectSingle, ForegroundSelectMulti, SELECT_ACTION, ForegroundOption } from '../../components/ForegroundSelect';
import { AppProps } from '../../models/Props';
import { DeviceCount, ReducedDevice } from '../../models/device';
import { LISTING_ERROR } from '../../models/constants';
import { PageHeader } from '../../components/PageHeader';
import Utils from '../../helpers/Utils';
import { BackToTop } from '../../components/backToTopButton';


const NB_PRODUCTS_PER_PAGE = 18;

const canBeOrderBy = [
  new ForegroundOption('Last updated - Labelled first', 'labelAndUpdatedAt'),
  new ForegroundOption('Last updated', 'updatedAt'),
  new ForegroundOption('Creation order', 'id'),
];

export class ProducstListProps {
  public appProps: AppProps;
  public history: any;
}

export class ProducstListState {
  productsList: Array<ReducedDevice>;
  productsCount: DeviceCount;
  isLoading: boolean;
  nbPages: number;
  nbProducts: number;
  nbTotalProducts: number;
  currentPage: number;
  currentStatusFilter: Array<ForegroundOption>;
  productsStatus: Array<ForegroundOption>;
  currentTypesFilter: Array<ForegroundOption>;
  productsTypes: Array<ForegroundOption>;
  currentCompaniesFilter: Array<ForegroundOption>;
  productsCompanies: Array<ForegroundOption>;
  currentNetworkInterfaceFilter: Array<ForegroundOption>;
  productsNetworkInterface: Array<ForegroundOption>;
  currentVerticalsFilter: Array<ForegroundOption>;
  productsVerticals: Array<ForegroundOption>;
  currentUseCasesFilter: Array<ForegroundOption>;
  productsUseCases: Array<ForegroundOption>;
  currentOrder: ForegroundOption;
  deviceName: string;
  companyName: string;
  errorMessage: string;

  constructor() {
    this.productsList = [];
    this.productsCount = new DeviceCount();
    this.isLoading = true;
    this.nbPages = 1;
    this.nbProducts = 0;
    this.nbTotalProducts = 0;
    this.currentPage = 0;
    this.currentStatusFilter = [];
    this.productsStatus = [];
    this.currentTypesFilter = [];
    this.productsTypes = [];
    this.currentCompaniesFilter = [];
    this.productsCompanies = [];
    this.currentNetworkInterfaceFilter = [];
    this.productsNetworkInterface = [];
    this.currentVerticalsFilter = [];
    this.productsVerticals = [];
    this.currentUseCasesFilter = [];
    this.productsUseCases = [];
    this.currentOrder = canBeOrderBy[0];
    this.deviceName = '';
    this.companyName = '';
    this.errorMessage = '';
  }
}

export class ProducstList extends Component<ProducstListProps, ProducstListState> {
  constructor(props) {
    super(props);
    this.state = new ProducstListState();
    this.handlePageClick = this.handlePageClick.bind(this);
    this.handleChangeFilterStatus = this.handleChangeFilterStatus.bind(this);
    this.handleChangeFilterTypes = this.handleChangeFilterTypes.bind(this);
    this.handleChangeFilterCompanies = this.handleChangeFilterCompanies.bind(this);
    this.handleChangeFilterNetworkInterface = this.handleChangeFilterNetworkInterface.bind(this);
    this.handleChangeFilterVerticals = this.handleChangeFilterVerticals.bind(this);
    this.handleChangeFilterUseCases = this.handleChangeFilterUseCases.bind(this);
    this.handleChangeOrder = this.handleChangeOrder.bind(this);
    this.handleChangeDeviceName = this.handleChangeDeviceName.bind(this);
    this.handleChangeCompanyName = this.handleChangeCompanyName.bind(this);
    this.reset = this.reset.bind(this);
    this.handleApplyFilter = this.handleApplyFilter.bind(this);
  }

  async componentDidMount() {
    let statusFilter = Utils.getFilter(Utils.PRODUCTFILTERSTATUS);
    if(statusFilter) {
      this.setState({ currentStatusFilter: statusFilter});
      statusFilter = statusFilter.map((s) => s.value).join(',');
    }
    let typesFilter = Utils.getFilter(Utils.PRODUCTFILTERTYPES);
    if(typesFilter) {
      this.setState({ currentTypesFilter: typesFilter});
      typesFilter = typesFilter.map((s) => s.value).join(',');
    }
    let companiesFilter = Utils.getFilter(Utils.PRODUCTFILTERCOMPANIES);
    if(companiesFilter) {
      this.setState({ currentCompaniesFilter: companiesFilter});
      companiesFilter = companiesFilter.map((s) => s.value).join(',');
    }
    let networkInterfaceFilter = Utils.getFilter(Utils.PRODUCTFILTERNETWORK);
    if(networkInterfaceFilter) {
      this.setState({ currentNetworkInterfaceFilter: networkInterfaceFilter});
      networkInterfaceFilter = networkInterfaceFilter.map((s) => s.value).join(',');
    }
    let verticalsFilter = Utils.getFilter(Utils.PRODUCTFILTERVERTICALS);
    if(verticalsFilter) {
      this.setState({ currentVerticalsFilter: verticalsFilter});
      verticalsFilter = verticalsFilter.map((s) => s.value).join(',');
    }
    let useCasesFilter = Utils.getFilter(Utils.PRODUCTFILTERUSECASES);
    if(useCasesFilter) {
      this.setState({ currentUseCasesFilter: useCasesFilter});
      useCasesFilter = useCasesFilter.map((s) => s.value).join(',');
    }
    let orderBy = Utils.getFilter(Utils.PRODUCTFILTERORDER);
    if(orderBy) {
      this.setState({ currentOrder: orderBy});
      orderBy = orderBy.value;
    }
    let deviceName = Utils.getFilter(Utils.PRODUCTFILTERDEVICENAME);
    if(deviceName) {
      this.setState({ deviceName: deviceName});
    }
    let companyName = Utils.getFilter(Utils.PRODUCTFILTERCOMPANYNAME);
    if(companyName) {
      this.setState({ companyName: companyName});
    }

    try {
      const promises = [ProductService.productsList(0, NB_PRODUCTS_PER_PAGE, statusFilter, typesFilter, companiesFilter, networkInterfaceFilter, verticalsFilter, useCasesFilter, orderBy, deviceName, companyName)];
      const scope = this.props.appProps.scope;
      const isOrangeScope = scope === ORANGE_ADMIN_SCOPE || scope === ORANGE_USER_SCOPE || scope === ORANGE_VALIDATION_SCOPE;
      if (isOrangeScope) {
        promises.push(ProductService.productsCount());
      }

      const response = await Promise.all(promises);
      const nbProducts = response[0].headers['x-total-count'];
      const nbPages = Math.ceil(nbProducts / NB_PRODUCTS_PER_PAGE);

      const productsCount = isOrangeScope ? response[1].data : [];

      const productsStatus = isOrangeScope
        ? !!productsCount.deviceStatus
          ? productsCount.deviceStatus.map((item) => {
              return {
                label: `${item.label} (${item.nbDevice})`,
                value: item.id,
              };
            })
          : []
        : [];
      const productsTypes = isOrangeScope
        ? !!productsCount.deviceTypes
          ? productsCount.deviceTypes.map((item) => {
              return {
                label: `${item.label} (${item.nbDevice})`,
                value: item.id,
              };
            })
          : []
        : [];
      const productsCompanies = isOrangeScope
        ? !!productsCount.companies
          ? productsCount.companies.map((item) => {
              return {
                label: `${item.label} (${item.nbDevice})`,
                value: item.id,
              };
            })
          : []
        : [];
      const productsNetworkInterface = isOrangeScope
        ? !!productsCount.networkInterface
          ? productsCount.networkInterface.map((item) => {
              return {
                label: `${item.label} (${item.nbDevice})`,
                value: item.id,
              };
            })
          : []
        : [];
      const productsVerticals = isOrangeScope
        ? !!productsCount.verticals
          ? productsCount.verticals.map((item) => {
              return {
                label: `${item.label} (${item.nbDevice})`,
                value: item.id,
              };
            })
          : []
        : [];
      const productsUseCases = isOrangeScope
        ? !!productsCount.usescases
          ? productsCount.usescases.map((item) => {
              return {
                label: `${item.label} (${item.nbDevice})`,
                value: item.id,
              };
            })
          : []
        : [];

      let errorMessage = '';
      let productsList = !!response[0] && !!response[0].data ? response[0].data : [];
      this.setState({
        productsList: productsList,
        productsCount: isOrangeScope ? response[1].data : [],
        isLoading: false,
        nbProducts: nbProducts,
        nbTotalProducts: nbProducts,
        nbPages: nbPages,
        productsStatus: productsStatus,
        productsTypes: productsTypes,
        productsCompanies: productsCompanies,
        productsNetworkInterface: productsNetworkInterface,
        productsVerticals: productsVerticals,
        productsUseCases: productsUseCases,
        errorMessage: errorMessage,
      });
    } catch (error) {
      console.log('ERROR ProductsList : ' + JSON.stringify(error));
      this.setState({ isLoading: false, errorMessage: LISTING_ERROR });
    }
  }

  public async refresh(
    currentPage: number,
    currentStatusFilter: ForegroundOption[],
    currentTypesFilter: ForegroundOption[],
    currentCompaniesFilter: ForegroundOption[],
    currentNetworkInterfaceFilter: ForegroundOption[],
    currentVerticalsFilter: ForegroundOption[],
    currentUseCasesFilter: ForegroundOption[],

    currentOrder: ForegroundOption,
    deviceName: string,
    companyName: string,
    errorMessage: string = ''
  ) {
    const statusFilter = currentStatusFilter.map((s) => s.value).join(',');
    const typesFilter = currentTypesFilter.map((s) => s.value).join(',');
    const companiesFilter = currentCompaniesFilter.map((s) => s.value).join(',');
    const networkInterfaceFilter = currentNetworkInterfaceFilter.map((s) => s.value).join(',');
    const verticalsFilter = currentVerticalsFilter.map((s) => s.value).join(',');
    const useCasesFilter = currentUseCasesFilter.map((s) => s.value).join(',');

    try {
      let response = await ProductService.productsList(
        currentPage * NB_PRODUCTS_PER_PAGE,
        NB_PRODUCTS_PER_PAGE,
        statusFilter,
        typesFilter,
        companiesFilter,
        networkInterfaceFilter,
        verticalsFilter,
        useCasesFilter,
        currentOrder.value,
        deviceName,
        companyName
      );
      const nbProducts = response.headers['x-total-count'];
      const nbPages = Math.ceil(nbProducts / NB_PRODUCTS_PER_PAGE);

      const withoutFilter =
        currentStatusFilter.length === 0 &&
        currentTypesFilter.length === 0 &&
        currentCompaniesFilter.length === 0 &&
        currentNetworkInterfaceFilter.length === 0 &&
        currentVerticalsFilter.length === 0 &&
        currentUseCasesFilter.length === 0 &&
        deviceName === '' &&
        companyName === '';
      const nbTotalProducts = withoutFilter ? nbProducts : this.state.nbTotalProducts;

      let productsList = !!response.data ? response.data : [];
      this.setState({
        productsList: productsList,
        isLoading: false,
        nbProducts: nbProducts,
        nbTotalProducts: nbTotalProducts,
        nbPages: nbPages,
        errorMessage: errorMessage,
      });
    } catch (error) {
      console.log(`ERROR ProductsList (refresh) : ${JSON.stringify(error)}`);
      this.setState({ isLoading: false, errorMessage: LISTING_ERROR });
    }
  }

  private setStoredFilters(status, types, companies, networkInterface, verticals, useCases, order, deviceName, companyName) {
    Utils.setFilter(Utils.PRODUCTFILTERSTATUS,status);
    Utils.setFilter(Utils.PRODUCTFILTERTYPES,types);
    Utils.setFilter(Utils.PRODUCTFILTERCOMPANIES,companies);
    Utils.setFilter(Utils.PRODUCTFILTERNETWORK,networkInterface);
    Utils.setFilter(Utils.PRODUCTFILTERVERTICALS,verticals);
    Utils.setFilter(Utils.PRODUCTFILTERUSECASES,useCases);
    Utils.setFilter(Utils.PRODUCTFILTERORDER,order);
    Utils.setFilter(Utils.PRODUCTFILTERDEVICENAME,deviceName);
    Utils.setFilter(Utils.PRODUCTFILTERCOMPANYNAME,companyName);
  }

  private deleteStoredFilters() {
    Utils.deleteFilter(Utils.PRODUCTFILTERSTATUS);
    Utils.deleteFilter(Utils.PRODUCTFILTERTYPES);
    Utils.deleteFilter(Utils.PRODUCTFILTERCOMPANIES);
    Utils.deleteFilter(Utils.PRODUCTFILTERNETWORK);
    Utils.deleteFilter(Utils.PRODUCTFILTERVERTICALS);
    Utils.deleteFilter(Utils.PRODUCTFILTERUSECASES);
    Utils.deleteFilter(Utils.PRODUCTFILTERORDER);
    Utils.deleteFilter(Utils.PRODUCTFILTERDEVICENAME);
    Utils.deleteFilter(Utils.PRODUCTFILTERCOMPANYNAME);
  }

  public reset() {
    this.setState({
      currentPage: 0,
      currentStatusFilter: [],
      currentTypesFilter: [],
      currentCompaniesFilter: [],
      currentNetworkInterfaceFilter: [],
      currentVerticalsFilter: [],
      currentUseCasesFilter: [],
      currentOrder: canBeOrderBy[0],
      deviceName: '',
      companyName: '',
    });
    this.deleteStoredFilters();
    this.refresh(0, [], [], [], [], [], [], canBeOrderBy[0], '', '');
  }

  handlePageClick(event) {
    this.setState({ currentPage: event.selected });
    const currentStatusFilter = this.state.currentStatusFilter;
    const currentTypesFilter = this.state.currentTypesFilter;
    const currentCompaniesFilter = this.state.currentCompaniesFilter;
    const currentNetworkInterfaceFilter = this.state.currentNetworkInterfaceFilter;
    const currentVerticalsFilter = this.state.currentVerticalsFilter;
    const currentUseCasesFilter = this.state.currentUseCasesFilter;
    const currentOrder = this.state.currentOrder;
    const deviceName = this.state.deviceName;
    const companyName = this.state.companyName;

    this.refresh(
      event.selected,
      currentStatusFilter,
      currentTypesFilter,
      currentCompaniesFilter,
      currentNetworkInterfaceFilter,
      currentVerticalsFilter,
      currentUseCasesFilter,
      currentOrder,
      deviceName,
      companyName
    );
  }

  handleApplyFilter() {
    const currentStatusFilter = this.state.currentStatusFilter;
    const currentTypesFilter = this.state.currentTypesFilter;
    const currentCompaniesFilter = this.state.currentCompaniesFilter;
    const currentNetworkInterfaceFilter = this.state.currentNetworkInterfaceFilter;
    const currentVerticalsFilter = this.state.currentVerticalsFilter;
    const currentUseCasesFilter = this.state.currentUseCasesFilter;
    const currentOrder = this.state.currentOrder;
    const deviceName = this.state.deviceName;
    const companyName = this.state.companyName;
    const currentPage = this.state.currentPage;

    this.setStoredFilters(currentStatusFilter, currentTypesFilter, currentCompaniesFilter, currentNetworkInterfaceFilter, currentVerticalsFilter, currentUseCasesFilter, currentOrder, deviceName, companyName);

    this.refresh(
    currentPage,
    currentStatusFilter,
    currentTypesFilter,
    currentCompaniesFilter,
    currentNetworkInterfaceFilter,
    currentVerticalsFilter,
    currentUseCasesFilter,
    currentOrder,
    deviceName,
    companyName
  );
}

  handleChangeFilterStatus(event, action) {
    const currentStatusFilter = this.state.currentStatusFilter;
    switch (action.action) {
      case SELECT_ACTION.SELECT_OPTION:
        currentStatusFilter.push(action.option);
        this.setState({ currentStatusFilter: event, currentPage: 0 });
        break;
      case SELECT_ACTION.REMOVE_VALUE:
        const newSelection = currentStatusFilter.filter((e) => e.value !== action.removedValue.value);
        this.setState({ currentStatusFilter: newSelection, currentPage: 0 });
        break;
      case SELECT_ACTION.CLEAR:
        this.setState({ currentStatusFilter: [], currentPage: 0 });
        break;
      default:
    }
  }

  handleChangeFilterTypes(event, action) {
    const currentTypesFilter = this.state.currentTypesFilter;

    switch (action.action) {
      case SELECT_ACTION.SELECT_OPTION:
        currentTypesFilter.push(action.option);
        this.setState({ currentTypesFilter: event, currentPage: 0 });
        break;
      case SELECT_ACTION.REMOVE_VALUE:
        const newSelection = currentTypesFilter.filter((e) => e.value !== action.removedValue.value);
        this.setState({ currentTypesFilter: newSelection, currentPage: 0 });
        break;
      case SELECT_ACTION.CLEAR:
        this.setState({ currentTypesFilter: [], currentPage: 0 });
        break;
      default:
    }
  }

  handleChangeFilterCompanies(event, action) {
    const currentCompaniesFilter = this.state.currentCompaniesFilter;

    switch (action.action) {
      case SELECT_ACTION.SELECT_OPTION:
        currentCompaniesFilter.push(action.option);
        this.setState({ currentCompaniesFilter: event, currentPage: 0 });
        break;
      case SELECT_ACTION.REMOVE_VALUE:
        const newSelection = currentCompaniesFilter.filter((e) => e.value !== action.removedValue.value);
        this.setState({ currentCompaniesFilter: newSelection, currentPage: 0 });
        break;
      case SELECT_ACTION.CLEAR:
        this.setState({ currentCompaniesFilter: [], currentPage: 0 });
        break;
      default:
    }
  }

  handleChangeFilterNetworkInterface(event, action) {
    const currentNetworkInterfaceFilter = this.state.currentNetworkInterfaceFilter;
    let newNetworkInterface = currentNetworkInterfaceFilter;

    switch (action.action) {
      case SELECT_ACTION.SELECT_OPTION:
        currentNetworkInterfaceFilter.push(action.option);
        this.setState({ currentNetworkInterfaceFilter: event, currentPage: 0 });
        break;
      case SELECT_ACTION.REMOVE_VALUE:
        const newSelection = currentNetworkInterfaceFilter.filter((e) => e.value !== action.removedValue.value);
        this.setState({ currentNetworkInterfaceFilter: newSelection, currentPage: 0 });
        newNetworkInterface = newSelection;
        break;
      case SELECT_ACTION.CLEAR:
        this.setState({ currentNetworkInterfaceFilter: [], currentPage: 0 });
        newNetworkInterface = [];
        break;
      default:
    }
  }

  handleChangeFilterVerticals(event, action) {
    const currentVerticalsFilter = this.state.currentVerticalsFilter;

    switch (action.action) {
      case SELECT_ACTION.SELECT_OPTION:
        currentVerticalsFilter.push(action.option);
        this.setState({ currentVerticalsFilter: event, currentPage: 0 });
        break;
      case SELECT_ACTION.REMOVE_VALUE:
        const newSelection = currentVerticalsFilter.filter((e) => e.value !== action.removedValue.value);
        this.setState({ currentVerticalsFilter: newSelection, currentPage: 0 });
        break;
      case SELECT_ACTION.CLEAR:
        this.setState({ currentVerticalsFilter: [], currentPage: 0 });
        break;
      default:
    }
  }

  handleChangeFilterUseCases(event, action) {
    const currentUseCasesFilter = this.state.currentUseCasesFilter;

    switch (action.action) {
      case SELECT_ACTION.SELECT_OPTION:
        currentUseCasesFilter.push(action.option);
        this.setState({ currentUseCasesFilter: event, currentPage: 0 });
        break;
      case SELECT_ACTION.REMOVE_VALUE:
        const newSelection = currentUseCasesFilter.filter((e) => e.value !== action.removedValue.value);
        this.setState({ currentUseCasesFilter: newSelection, currentPage: 0 });
        break;
      case SELECT_ACTION.CLEAR:
        this.setState({ currentUseCasesFilter: [], currentPage: 0 });
        break;
      default:
    }
  }

  handleChangeOrder(event) {
    this.setState({ currentOrder: event, currentPage: 0 });
    const currentStatusFilter = this.state.currentStatusFilter;
    const currentTypesFilter = this.state.currentTypesFilter;
    const currentCompaniesFilter = this.state.currentCompaniesFilter;
    const currentNetworkInterfaceFilter = this.state.currentNetworkInterfaceFilter;
    const currentVerticalsFilter = this.state.currentVerticalsFilter;
    const currentUseCasesFilter = this.state.currentUseCasesFilter;
    const deviceName = this.state.deviceName;
    const companyName = this.state.companyName;

    this.refresh(
      0,
      currentStatusFilter,
      currentTypesFilter,
      currentCompaniesFilter,
      currentNetworkInterfaceFilter,
      currentVerticalsFilter,
      currentUseCasesFilter,
      event,
      deviceName,
      companyName
    );
  }

  handleChangeDeviceName(value: string) {
    this.setState({ deviceName: value });
  }

  handleChangeCompanyName(value: string) {
    this.setState({ companyName: value });
  }

  async CSVProductExport() {
    this.setState({ isLoading: true });
    const SEP = ',', LINESEP = '\r\n';
    const header = 'device name' + SEP + 'main features' + SEP + 'created at' + SEP + 'indicative price per quantities' + SEP + 'device type' + SEP + 'company name' + SEP + 'iot group catalog' + SEP + 'networks/radio interfaces' + SEP + 'geolocation technology' + SEP + 'verticals' + LINESEP;
    const statusFilter = this.state.currentStatusFilter.map((s) => s.value).join(',');
    const typesFilter = this.state.currentTypesFilter.map((s) => s.value).join(',');
    const companiesFilter = this.state.currentCompaniesFilter.map((s) => s.value).join(',');
    const networkInterfaceFilter = this.state.currentNetworkInterfaceFilter.map((s) => s.value).join(',');
    const verticalsFilter = this.state.currentVerticalsFilter.map((s) => s.value).join(',');
    const usesCasesFilter = this.state.currentUseCasesFilter.map((s) => s.value).join(',');
    const currentOrder = this.state.currentOrder;
    const deviceName = this.state.deviceName;
    const companyName = this.state.companyName;
    try {
      let response = await ProductService.productsList(null,
        null,
        statusFilter,
        typesFilter,
        companiesFilter,
        networkInterfaceFilter,
        verticalsFilter,
        usesCasesFilter,
        currentOrder.value,
        deviceName,
        companyName
      );
      const CSVData = header + response.data.map(item => Utils.formatCSVData(item.name) + SEP + Utils.formatCSVData(item.features)
        + SEP + Utils.formatCSVData(Utils.formatDateToWeb(item.createdAt))
        + SEP + Utils.formatCSVData(item.pricing + " " + item.pricingUnit).trim()
        + SEP + Utils.formatCSVData(item.type.label)
        + SEP + Utils.formatCSVData(item.company.name)
        + SEP + Utils.formatCSVData(item.iotMarketplace ? "Yes":"No")
        + SEP + Utils.formatCSVData(Utils.reduceArrayToString(item.networks,"connectivity"))
        + SEP + Utils.formatCSVData(Utils.reduceArrayToString(item.networks,"geolocation"))
        + SEP + Utils.formatCSVData(Utils.reduceArrayToString(item.verticals)) + LINESEP
      ).join('')
      const url = window.URL.createObjectURL(new Blob([CSVData]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', Utils.formatDateToWebWithTime(Date()).replace(/\s/g, '') + '.csv');
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      document.dispatchEvent(
        new CustomEvent('addNotification', {
          detail: { type: 'success', content: 'CSV Export successful. The file has been downloaded on your computer!' },
        })
      );
    }
    catch (error) {
      console.log(`ERROR ProductsList : ${JSON.stringify(error)}`);
      document.dispatchEvent(
        new CustomEvent('addNotification', {
          detail: {
            type: 'danger',
            category: 'error',
            content: 'CSV Export failed. Try again later!'
          }
        })
      )
    }

    this.setState({ isLoading: false });
  }


  renderTable() {
    const scope = this.props.appProps.scope;
    let currentOrder = this.state.currentOrder;
    const isOrangeScope = scope === ORANGE_ADMIN_SCOPE || scope === ORANGE_USER_SCOPE || scope === ORANGE_VALIDATION_SCOPE;

    let {
      nbProducts,
      nbTotalProducts,
      productsList,
      nbPages,
      productsStatus,
      productsTypes,
      productsCompanies,
      productsNetworkInterface,
      productsVerticals,
      productsUseCases,
      errorMessage,
    } = this.state;

    // In case of missing data, in order to avoid uncaught runtime errors
    if (!!!productsList || !Array.isArray(productsList)) {
      productsList = [];
      errorMessage = LISTING_ERROR;
    }
    if (!!!nbProducts) nbProducts = 0;
    if (!!!nbTotalProducts) nbTotalProducts = 0;

    const productLabel = nbProducts > 1 ? 'products' : 'product';
    const productLabelTotal = nbTotalProducts > 1 ? 'products' : 'product';

    return (
      <div>
        <div className="container mt-4">
          <div className="">
              <p id="nbProducts" className="catalog-total-numbers mt-4">
                <span className="catalog-total-numbers">{`${nbTotalProducts} `}</span>
                {productLabelTotal}
                {scope === PARTNER_SCOPE || scope === ORANGE_PARTNER_SCOPE ? ' in your catalog' : ''}
              </p>
              {scope === ORANGE_ADMIN_SCOPE || scope === ORANGE_USER_SCOPE || scope === ORANGE_VALIDATION_SCOPE ? (
                <p id="criteria-number" className="catalog-criterias-numbers">
                  {`${nbProducts} ${productLabel} matching your criterias`}
                </p>
              ) : null}
              {!!errorMessage ? (
                <p id="error-listing" className="errorMessage mt-2">
                  {errorMessage}
                </p>
              ) : null}
              {scope === PARTNER_SCOPE || scope === ORANGE_PARTNER_SCOPE || scope === ORANGE_ADMIN_SCOPE ? (
                <div className="row mt-4 mb-2">
                  <a id="addDeviceButton" className="add btn btn-primary m-1" href="/products/add">
                    Add Device
                  </a>
                  {scope === ORANGE_ADMIN_SCOPE ? (
                  <button id="CSVProductButton" onClick={() => this.CSVProductExport()} className="add btn btn-primary m-1">
                    Filtered CSV Export
                  </button>
                  ) : null }
                </div>
              ) : null}
              <div className="row">
                {isOrangeScope ? (
                  <div className="col-md-3">
                    <p className="filter-title">Filter</p>
                    <label className="orderby-title" htmlFor="name_filter">
                      Device name
                    </label>
                    <input
                      className=" form-control mt-2 mb-3"
                      type="text"
                      id="name_filter"
                      placeholder="Write device name"
                      maxLength={191}
                      value={this.state.deviceName}
                      onChange={(e) => {
                        this.handleChangeDeviceName(e.target.value);
                      }}
                    ></input>
                    <label className="orderby-title" htmlFor="company_name_filter">
                      Company name
                    </label>
                    <input
                      className=" form-control mt-2 mb-3"
                      type="text"
                      id="company_name_filter"
                      placeholder="Write company name"
                      maxLength={191}
                      value={this.state.companyName}
                      onChange={(e) => {
                        this.handleChangeCompanyName(e.target.value);
                      }}
                    ></input>
                    <label className="orderby-title" htmlFor="productStatus">
                      Device status
                    </label>
                    <div className="mb-3">
                      <ForegroundSelectMulti
                        inputId="productStatus"
                        placeholder="Select status"
                        options={productsStatus}
                        value={this.state.currentStatusFilter}
                        onChange={(e, a) => this.handleChangeFilterStatus(e, a)}
                      />
                    </div>
                    <label className="orderby-title" htmlFor="productType">
                      Types
                    </label>
                    <div className="mb-3">
                      <ForegroundSelectMulti
                        inputId="productType"
                        placeholder="Select type"
                        options={productsTypes}
                        value={this.state.currentTypesFilter}
                        onChange={(e, a) => this.handleChangeFilterTypes(e, a)}
                      />
                    </div>
                    <label className="orderby-title" htmlFor="productCompany">
                      Company
                    </label>
                    <div className="mb-3">
                      <ForegroundSelectMulti
                        inputId="productCompany"
                        placeholder="Select company"
                        options={productsCompanies}
                        value={this.state.currentCompaniesFilter}
                        onChange={(e, a) => this.handleChangeFilterCompanies(e, a)}
                      />
                    </div>
                    <label className="orderby-title" htmlFor="networkInterface">
                      Network interface
                    </label>
                    <div className="mb-3">
                      <ForegroundSelectMulti
                        inputId="networkInterface"
                        placeholder="Select network interface"
                        options={productsNetworkInterface}
                        value={this.state.currentNetworkInterfaceFilter}
                        onChange={(e, a) => this.handleChangeFilterNetworkInterface(e, a)}
                      />
                    </div>
                    <label className="orderby-title" htmlFor="vertical">
                      Verticals
                    </label>
                    <div className="mb-3">
                      <ForegroundSelectMulti
                        inputId="vertical"
                        placeholder="Select verticals"
                        options={productsVerticals}
                        value={this.state.currentVerticalsFilter}
                        onChange={(e, a) => this.handleChangeFilterVerticals(e, a)}
                      />
                    </div>
                    <label className="orderby-title" htmlFor="usecases">
                      Use cases
                    </label>
                    <div className="mb-3">
                      <ForegroundSelectMulti
                        inputId="usecases"
                        placeholder="Select use cases"
                        options={productsUseCases}
                        value={this.state.currentUseCasesFilter}
                        onChange={(e, a) => this.handleChangeFilterUseCases(e, a)}
                      />
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-3">
                        <button id="reset-1" onClick={() => this.reset()} className="me-3 btn btn-secondary">
                          Reset
                        </button>
                      </div>
                      <div className="col-md-3 ms-3">
                        <button id="apply-1" onClick={() => this.handleApplyFilter()} className="btn btn-primary">
                          Apply
                        </button>
                      </div>
                    </div>
                  </div>
                ) : null}

                <div className={`col-md-8 ${isOrangeScope} ? 'offset-md-1' : ''`}>
                  {isOrangeScope ? (
                    <div className="row justify-content-end orderby-div">
                      <div className="form-group col-md-5">
                        <label className="orderby-title " htmlFor="orderBy">
                          Order By
                        </label>
                        <ForegroundSelectSingle
                          inputId="orderBy"
                          value={currentOrder}
                          options={canBeOrderBy}
                          isClearable={false}
                          onChange={(e) => this.handleChangeOrder(e)}
                          placeholder="Select order"
                        />
                      </div>
                    </div>
                  ) : null}
                  <div className="products-list">
                    {!!productsList && Array.isArray(productsList)
                      ? productsList.map((product) => {
                          return <ProductCard product={product} history={this.props.history} key={product.id} showCompany={true}></ProductCard>;
                        })
                      : null}
                  </div>
                  {nbPages > 1 ? <Pagination handlePageClick={this.handlePageClick} nbPages={this.state.nbPages} /> : ''}
                </div>
              </div>
          </div>
        </div>
        {BackToTop}
      </div>
    );
  }

  render() {
    document.title = 'Products - Foreground V3';

    return (
      <div className="p-3 bg-white">
        <PageHeader title="Products"></PageHeader>
        {this.state.isLoading ? <LoadingSpinner /> : this.renderTable()}
      </div>
    );
  }
}

export default createBrowserHistory();
