import React, { Component } from 'react';
import { DATE_PICKER_FORMAT, NUMERIC_FIELD_ERROR, NUMERIC_REGEX, UNIT_OPTIONS } from '../../models/constants';
import './company-management.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Utils from '../../helpers/Utils';
import { ForegroundOption, ForegroundSelectSingle } from '../../components/ForegroundSelect';
import { CompanyWizardStepProps } from './CompanyWizard';
import { BackToTop } from '../../components/backToTopButton';

class CompanyFinanceStepState {
  errorMessage: string;
  creationDate: Date;
  capitalUnit: ForegroundOption;
  turnoverUnit: ForegroundOption;
  isErrorOnHeadCountInIoT: boolean;
  isErrorOnCompanyCapital: boolean;
  isErrorOnVolumeOfProduction: boolean;
  isErrorOnTurnoverLastYear: boolean;

  constructor() {
    this.errorMessage = '';
    this.creationDate = null;
    this.capitalUnit = UNIT_OPTIONS[0];
    this.turnoverUnit = UNIT_OPTIONS[0];
    this.isErrorOnHeadCountInIoT = false;
    this.isErrorOnCompanyCapital = false;
    this.isErrorOnVolumeOfProduction = false;
    this.isErrorOnTurnoverLastYear = false;
  }
}

export class CompanyFinanceStep extends Component<CompanyWizardStepProps, CompanyFinanceStepState> {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: '',
      creationDate: null,
      capitalUnit: UNIT_OPTIONS[0],
      turnoverUnit: UNIT_OPTIONS[0],
      isErrorOnHeadCountInIoT: false,
      isErrorOnCompanyCapital: false,
      isErrorOnVolumeOfProduction: false,
      isErrorOnTurnoverLastYear: false,
    };
    this.handleChangeCreationDate = this.handleChangeCreationDate.bind(this);
    this.handleChangeCapitalUnit = this.handleChangeCapitalUnit.bind(this);
    this.handleChangeTurnoverUnit = this.handleChangeTurnoverUnit.bind(this);
  }

  async componentDidMount() {
    try {
      const isCreation = !!!this.props.company.id;
      if (!isCreation) {
        const capitalUnit = {
          label: this.props.company.financeAndSustainability.companyCapitalUnit,
          value: this.props.company.financeAndSustainability.companyCapitalUnit,
        };
        const turnoverUnit = {
          label: this.props.company.financeAndSustainability.turnoverLastYearUnit,
          value: this.props.company.financeAndSustainability.turnoverLastYearUnit,
        };
        this.setState({
          capitalUnit: capitalUnit,
          turnoverUnit: turnoverUnit,
          creationDate: new Date(this.props.company.financeAndSustainability.creationIotActivity),
        });
      }
    } catch (e) {
      console.error(e);
    }
  }

  validate() {
    if (this.props.isReadOnly) {
      return true;
    }
    if (
      this.state.isErrorOnHeadCountInIoT ||
      this.state.isErrorOnCompanyCapital ||
      this.state.isErrorOnTurnoverLastYear ||
      this.state.isErrorOnVolumeOfProduction
    ) {
      return false;
    }
    return true;
  }

  handleChangeCreationDate(newDate) {
    this.setState({ creationDate: newDate });
    this.props.company.financeAndSustainability.creationIotActivity = Utils.formatDateToAPI(newDate);
  }

  handleChangeCapitalUnit(event) {
    this.props.company.financeAndSustainability.companyCapitalUnit = event.value;
    this.setState({ capitalUnit: event });
  }

  handleChangeTurnoverUnit(event) {
    this.props.company.financeAndSustainability.turnoverLastYearUnit = event.value;
    this.setState({ turnoverUnit: event });
  }

  render() {
    if (this.props.shouldDisplay) {
      const isCreation = !!!this.props.company.id;

      const creationDate = this.state.creationDate;

      return (
        <>
          <div className="row">
            <div className='col-md-12'>
              <div className='mb-3 mt-1'>All mandatory fields are marked with an *</div>
              <label htmlFor='date_creation'>Date of creation the IoT activity</label>
              <DatePicker
                id='date_creation'
                dateFormat={DATE_PICKER_FORMAT}
                className='w-100 form-control mt-2'
                selected={creationDate}
                onChange={(newDate) => {
                  this.handleChangeCreationDate(newDate);
                }}
                readOnly={this.props.isReadOnly}
                disabled={this.props.isReadOnly}
              />
            </div>
          </div>

          <div className='row mt-4'>
            <div className="col-md-12">
              <label htmlFor="headcount_in_iot">Headcount in IoT</label>
              <input
                type="text"
                id="headcount_in_iot"
                name="headcount_in_iot"
                className="form-control mt-2"
                defaultValue={this.props.company.financeAndSustainability.headCountIot}
                onChange={(e) => {
                  if (NUMERIC_REGEX.test(e.target.value)) {
                    this.props.company.financeAndSustainability.headCountIot = parseInt(e.target.value);
                    this.setState({ isErrorOnHeadCountInIoT: false });
                  } else {
                    this.setState({ isErrorOnHeadCountInIoT: true, errorMessage: NUMERIC_FIELD_ERROR });
                  }
                }}
                readOnly={this.props.isReadOnly}
                disabled={this.props.isReadOnly}
                aria-describedby={this.state.isErrorOnHeadCountInIoT ? 'error-headCountInIoT' : ''}
                aria-invalid={this.state.isErrorOnHeadCountInIoT}
              ></input>
              {this.state.isErrorOnHeadCountInIoT ? (
                <p id="error-headCountInIoT" className="errorMessage">
                  {this.state.errorMessage}
                </p>
              ) : null}
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-md-12">
              <label htmlFor="stackholders_iot_susbsidiary">Stackholders of the IoT subsidiary</label>
              <input
                type="text"
                id="stackholders_iot_susbsidiary"
                name="stackholders_iot_susbsidiary"
                className="w-100 form-control mt-2"
                maxLength={191}
                defaultValue={this.props.company.financeAndSustainability.stackholdersIotSusbsidiary}
                onChange={(e) => {
                  this.props.company.financeAndSustainability.stackholdersIotSusbsidiary = e.target.value;
                }}
                readOnly={this.props.isReadOnly}
                disabled={this.props.isReadOnly}
              ></input>
            </div>
          </div>

          <div className="row  mt-4">
            <div className="col-md-7">
              <label htmlFor="company_capital">Company capital</label>
              <input
                className="form-control mt-2"
                type="text"
                id="company_capital"
                defaultValue={this.props.company.financeAndSustainability.companyCapital}
                onChange={(e) => {
                  if (NUMERIC_REGEX.test(e.target.value)) {
                    this.props.company.financeAndSustainability.companyCapital = parseInt(e.target.value);
                    this.setState({ isErrorOnCompanyCapital: false });
                  } else {
                    this.setState({ isErrorOnCompanyCapital: true, errorMessage: NUMERIC_FIELD_ERROR });
                  }
                }}
                readOnly={this.props.isReadOnly}
                disabled={this.props.isReadOnly}
                aria-describedby={this.state.isErrorOnCompanyCapital ? 'error-companyCapital' : ''}
                aria-invalid={this.state.isErrorOnCompanyCapital}
              ></input>
              {this.state.isErrorOnCompanyCapital ? (
                <p id="error-companyCapital" className="errorMessage">
                  {this.state.errorMessage}
                </p>
              ) : null}
            </div>
            <div className="col-md-5">
              <label className="" htmlFor="company_capital_unit">
                Company capital unit
              </label>
              <ForegroundSelectSingle
                inputId="company_capital_unit"
                value={this.state.capitalUnit}
                options={UNIT_OPTIONS}
                isClearable={false}
                onChange={(e) => this.handleChangeCapitalUnit(e)}
                isDisabled={this.props.isReadOnly}
                placeholder="Please select the unit for company capital"
              />
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-md-12">
              <label htmlFor="volume_of_production">Volume of production</label>
              <input
                type="text"
                id="volume_of_production"
                name="volume_of_production"
                className="w-100 form-control mt-2"
                defaultValue={this.props.company.financeAndSustainability.volumeOfProduction}
                onChange={(e) => {
                  if (NUMERIC_REGEX.test(e.target.value)) {
                    this.props.company.financeAndSustainability.volumeOfProduction = parseInt(e.target.value);
                    this.setState({ isErrorOnVolumeOfProduction: false });
                  } else {
                    this.setState({ isErrorOnVolumeOfProduction: true, errorMessage: NUMERIC_FIELD_ERROR });
                  }
                }}
                readOnly={this.props.isReadOnly}
                disabled={this.props.isReadOnly}
                aria-describedby={this.state.isErrorOnVolumeOfProduction ? 'error-volumeOfProduction' : ''}
                aria-invalid={this.state.isErrorOnVolumeOfProduction}
              ></input>
              {this.state.isErrorOnVolumeOfProduction ? (
                <p id="error-volumeOfProduction" className="errorMessage">
                  {this.state.errorMessage}
                </p>
              ) : null}
            </div>
          </div>

          <div className="row  mt-4">
            <div className="col-md-7">
              <label htmlFor="turnover_last_year">Turnover last year</label>
              <input
                className="w-100 form-control mt-2"
                type="text"
                id="turnover_last_year"
                defaultValue={this.props.company.financeAndSustainability.turnoverLastYear}
                onChange={(e) => {
                  if (NUMERIC_REGEX.test(e.target.value)) {
                    this.props.company.financeAndSustainability.turnoverLastYear = parseInt(e.target.value);
                    this.setState({ isErrorOnTurnoverLastYear: false });
                  } else {
                    this.setState({ isErrorOnTurnoverLastYear: true, errorMessage: NUMERIC_FIELD_ERROR });
                  }
                }}
                readOnly={this.props.isReadOnly}
                disabled={this.props.isReadOnly}
                aria-describedby={this.state.isErrorOnTurnoverLastYear ? 'error-turnoverLastYear' : ''}
                aria-invalid={this.state.isErrorOnTurnoverLastYear}
              ></input>
              {this.state.isErrorOnTurnoverLastYear ? (
                <p id="error-turnoverLastYear" className="errorMessage">
                  {this.state.errorMessage}
                </p>
              ) : null}
            </div>
            <div className="col-md-5">
              <label className="" htmlFor="turnover_last_year_unit">
                Turnover last year unit
              </label>
              <ForegroundSelectSingle
                inputId="turnover_last_year_unit"
                value={this.state.turnoverUnit}
                options={UNIT_OPTIONS}
                isClearable={false}
                placeholder="Please select the unit for turnover"
                onChange={(e) => this.handleChangeTurnoverUnit(e)}
                isDisabled={this.props.isReadOnly}
              />
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-md-12">
              <label htmlFor="commercial_organisation">Commercial organisation and partners for IoT</label>
              <input
                type="text"
                id="commercial_organisation"
                name="commercial_organisation"
                className="w-100 form-control mt-2"
                maxLength={191}
                defaultValue={this.props.company.financeAndSustainability.commercialOrganisation}
                onChange={(e) => {
                  this.props.company.financeAndSustainability.commercialOrganisation = e.target.value;
                }}
                readOnly={this.props.isReadOnly}
                disabled={this.props.isReadOnly}
              ></input>
            </div>
          </div>
          {BackToTop}
        </>
      );
    } else {
      return null;
    }
  }
}
