import React, { Component } from 'react';
import './product_management.css';
import 'react-datepicker/dist/react-datepicker.css';
import { DeviceWizardStepProps } from './DeviceWizard';
import { MANDATORY_FIELD_MISSING, MANDATORY_FIELD_MISSING_PERCENTAGE, PLACEHOLDER_FILL_FIELD } from '../../models/constants';
import { BackToTop } from '../../components/backToTopButton';

class DeviceESGCSRStepState {
  public errorMessage: string;

  ecRegulationsCompliance: boolean;
  reachRegulationsCompliance: boolean;
  useRecycledMaterials: boolean;
  percentageRecycledMaterials: number;
  isErrorOnUseRecycledMaterials: boolean;
  evaluateCarbonImpact: boolean;
  carbonImpact: number;
  isErrorOncarbonImpact: boolean;
  lifecycleAssessment: boolean;
  canShare: boolean;
  evaluateEnvironmentalImpactReduction: boolean;
  environmentalImpactReduction: string;
  expectedLifeSpan: number;
  isErrorExpectedLifeSpan: boolean;
  refurbishingProcess: boolean;
  docsForRefurbishing: string;
  repairingProcess: boolean;
  docsForRepairing: string;
  frenchMarketProducerRole: boolean;
  ecoOrganismAffiliation: string;
  ecMarketProducerRole: boolean;
  ecCountriesNote: string;

  constructor() {
    this.errorMessage = '';

    this.ecRegulationsCompliance = false;
    this.reachRegulationsCompliance = false;
    this.useRecycledMaterials = false;
    this.percentageRecycledMaterials = null;
    this.isErrorOnUseRecycledMaterials = false;
    this.evaluateCarbonImpact = false;
    this.carbonImpact = null;
    this.isErrorOncarbonImpact = false;
    this.lifecycleAssessment = false;
    this.canShare = false;
    this.evaluateEnvironmentalImpactReduction = false;
    this.environmentalImpactReduction = '';
    this.expectedLifeSpan = null;
    this.isErrorExpectedLifeSpan = false;
    this.refurbishingProcess = false;
    this.docsForRefurbishing = '';
    this.repairingProcess = false;
    this.docsForRepairing = '';
    this.frenchMarketProducerRole = false;
    this.ecoOrganismAffiliation = '';
    this.ecMarketProducerRole = false;
    this.ecCountriesNote = '';
  }
}

export class DeviceESGCSRStep extends Component<DeviceWizardStepProps, DeviceESGCSRStepState> {
  constructor(props) {
    super(props);
    this.state = new DeviceESGCSRStepState();
  }

  async componentDidMount() {
    try {
      const device = this.props.device.esgCsrSustainability;
      if (!!device) {
        this.setState({
          ecRegulationsCompliance: !!device.ecRegulationsCompliance,
          reachRegulationsCompliance: !!device.reachRegulationsCompliance,
          useRecycledMaterials: !!device.useRecycledMaterials,
          percentageRecycledMaterials: !!device.percentageRecycledMaterials ? device.percentageRecycledMaterials : null,
          evaluateCarbonImpact: !!device.evaluateCarbonImpact,
          carbonImpact: !!device.carbonImpact ? device.carbonImpact : null,
          lifecycleAssessment: !!device.lifecycleAssessment,
          canShare: !!device.canShare,
          evaluateEnvironmentalImpactReduction: !!device.evaluateEnvironmentalImpactReduction,
          environmentalImpactReduction: !!device.environmentalImpactReduction ? device.environmentalImpactReduction : '',
          expectedLifeSpan: !!device.expectedLifeSpan ? device.expectedLifeSpan : null,
          refurbishingProcess: !!device.refurbishingProcess,
          docsForRefurbishing: !!device.docsForRefurbishing ? device.docsForRefurbishing : '',
          repairingProcess: !!device.repairingProcess,
          docsForRepairing: !!device.docsForRepairing ? device.docsForRepairing : '',
          frenchMarketProducerRole: !!device.frenchMarketProducerRole,
          ecoOrganismAffiliation: !!device.ecoOrganismAffiliation ? device.ecoOrganismAffiliation : '',
          ecMarketProducerRole: !!device.ecMarketProducerRole,
          ecCountriesNote: !!device.ecCountriesNote ? device.ecCountriesNote : '',
        });
      }
    } catch (e) {
      console.error(e);
    }
  }

  // Regulatory compliance
  handleClickSwitchEcRegulationsCompliance(bool: boolean) {
    this.setState({ ecRegulationsCompliance: bool });
    this.props.device.esgCsrSustainability.ecRegulationsCompliance = bool;
  }
  handleClickSwitchReachRegulationsCompliance(bool: boolean) {
    this.setState({ reachRegulationsCompliance: bool });
    this.props.device.esgCsrSustainability.reachRegulationsCompliance = bool;
  }

  // Eco-conception
  handleClickSwitchUseRecycledMaterials(bool: boolean) {
    this.setState({ useRecycledMaterials: bool });
    this.props.device.esgCsrSustainability.useRecycledMaterials = bool;
    if (!bool) {
      this.onCommentPercentageRecycledMaterialsChange(null);
      this.setState({ isErrorOnUseRecycledMaterials: false });
    }
  }

  onCommentPercentageRecycledMaterialsChange(percent: number) {
    this.props.device.esgCsrSustainability.percentageRecycledMaterials = percent;
    if (percent < 0) {
      this.setState({ percentageRecycledMaterials: null, isErrorOnUseRecycledMaterials: true, errorMessage: MANDATORY_FIELD_MISSING_PERCENTAGE });
      return;
    }
    this.setState({ percentageRecycledMaterials: percent ? percent : null, isErrorOnUseRecycledMaterials: false });
  }
  handleClickSwitchEvaluateCarbonImpact(bool: boolean) {
    this.setState({ evaluateCarbonImpact: bool });
    this.props.device.esgCsrSustainability.evaluateCarbonImpact = bool;
    if (!bool) {
      this.onCommentCarbonImpactChange(null);
      this.setState({ isErrorOncarbonImpact: false });
    }
  }
  onCommentCarbonImpactChange(number: number) {
    this.props.device.esgCsrSustainability.carbonImpact = number;
    if (number < 0) {
      this.setState({ carbonImpact: null, isErrorOncarbonImpact: true, errorMessage: MANDATORY_FIELD_MISSING });
      return;
    }

    this.setState({ carbonImpact: number ? number : null, isErrorOncarbonImpact: false });
  }
  handleClickSwitchLifecycleAssessment(bool: boolean) {
    this.setState({ lifecycleAssessment: bool });
    this.props.device.esgCsrSustainability.lifecycleAssessment = bool;
  }
  handleClickSwitchCanShare(bool: boolean) {
    this.setState({ canShare: bool });
    if (this.props.device.esgCsrSustainability) {
      this.props.device.esgCsrSustainability.canShare = bool;
    }
  }

  // Transport
  handleClickSwitchEvaluateEnvironmentalImpactReduction(bool: boolean) {
    this.setState({ evaluateEnvironmentalImpactReduction: bool });
    this.props.device.esgCsrSustainability.evaluateEnvironmentalImpactReduction = bool;
    if (!bool) {
      this.onCommentenvironmentalImpactReduction(null);
    }
  }
  onCommentenvironmentalImpactReduction(comment: string) {
    this.setState({ environmentalImpactReduction: comment ? comment : '' });
    this.props.device.esgCsrSustainability.environmentalImpactReduction = comment;
  }

  // Usage
  onCommentExpectedLifeSpan(number: number) {
    if (!number && !isNaN(number)) {
      this.setState({ isErrorExpectedLifeSpan: true, errorMessage: MANDATORY_FIELD_MISSING });
      return;
    }
    const realNumber = isNaN(number) ? null : number;
    this.setState({ expectedLifeSpan: realNumber, isErrorExpectedLifeSpan: false });
    this.props.device.esgCsrSustainability.expectedLifeSpan = number;
  }

  // Refurbish and Repair
  handleClickSwitchRefurbishingProcess(bool: boolean) {
    this.setState({ refurbishingProcess: bool });
    this.props.device.esgCsrSustainability.refurbishingProcess = bool;
    if (!bool) {
      this.onCommentDocsForRefurbishingChange('');
    }
  }
  onCommentDocsForRefurbishingChange(comment: string) {
    this.setState({ docsForRefurbishing: comment });
    this.props.device.esgCsrSustainability.docsForRefurbishing = comment;
  }
  handleClickSwitchRepairingProcess(bool: boolean) {
    this.setState({ repairingProcess: bool });
    this.props.device.esgCsrSustainability.repairingProcess = bool;
    if (!bool) {
      this.onCommentDocsForRepairing('');
    }
  }
  onCommentDocsForRepairing(comment: string) {
    this.setState({ docsForRepairing: comment ? comment : '' });
    this.props.device.esgCsrSustainability.docsForRepairing = comment ? comment : '';
  }

  // End of life - Conformity with WEEE regulation
  handleClickSwitchFrenchMarketProducerRole(bool: boolean) {
    this.setState({ frenchMarketProducerRole: bool });
    this.props.device.esgCsrSustainability.frenchMarketProducerRole = bool;
    if (!bool) {
      this.onCommentEcoOrganismAffiliation(null);
    }
  }
  onCommentEcoOrganismAffiliation(comment: string) {
    this.setState({ ecoOrganismAffiliation: comment ? comment : '' });
    this.props.device.esgCsrSustainability.ecoOrganismAffiliation = comment ? comment : '';
  }
  handleClickSwitchEcMarketProducerRole(bool: boolean) {
    this.setState({ ecMarketProducerRole: bool });
    this.props.device.esgCsrSustainability.ecMarketProducerRole = bool;
    if (!bool) {
      this.onCommentEcCountriesNote('');
    }
  }
  onCommentEcCountriesNote(comment: string) {
    this.setState({ ecCountriesNote: comment ? comment : '' });
    this.props.device.esgCsrSustainability.ecCountriesNote = comment ? comment : '';
  }

  validate() {
    if (this.props.isReadOnly) {
      return true;
    }
    return true;
  }

  render() {
    if (this.props.shouldDisplay) {
      return (
        <>
          <div className='mb-3 mt-4'>All mandatory fields are marked with an *</div>
          <div className="row mt-3">
            <strong>ESG : Environmental, social and corporate governance</strong>
            <strong>CSR : Corporate social responsibility</strong>
          </div>
          {/* Regulatory compliance*/}
          <div className="row mt-3">
            <strong>
              Regulatory compliance is Mandatory to sell in Europe - Products that do not comply with European
              regulation cannot be considered
            </strong>
          </div>
          <div className="mt-2">
            <div className="form-row">
              <div className="form-check form-check-long-label form-switch m-2 d-flex align-items-center">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="switchEcRegulationsCompliance"
                  name="switchEcRegulationsCompliance"
                  checked={this.state.ecRegulationsCompliance}
                  onChange={() => this.handleClickSwitchEcRegulationsCompliance(!this.state.ecRegulationsCompliance)}
                  disabled={this.props.isReadOnly}
                  readOnly={this.props.isReadOnly}
                />
                <label className="form-check-label form-label p-1" htmlFor="switchEcRegulationsCompliance">
                  Please confirm the product compliance with EC regulations/ (RED,RoHS,...)
                </label>
              </div>
            </div>
          </div>
          <div className="mt-2">
            <div className="form-row">
              <div className="form-check form-check-long-label form-switch m-2 d-flex align-items-center">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="switchReachRegulationsCompliance"
                  name="switchReachRegulationsCompliance"
                  checked={this.state.reachRegulationsCompliance}
                  onChange={() => this.handleClickSwitchReachRegulationsCompliance(!this.state.reachRegulationsCompliance)}
                  disabled={this.props.isReadOnly}
                  readOnly={this.props.isReadOnly}
                />
                <label className="form-check-label form-check-long-label form-label p-1"
                       htmlFor="switchReachRegulationsCompliance">
                  {`REACH regulation: Please confirm that in accordance with article 33 of the REACH regulation, you communicate to your customers information on the presence of substances of very high concern included in the articles in a concentration > 0.1% mass/mass?`}
                </label>
              </div>
            </div>
          </div>
          {/* Eco-conception */}
          <div className="row mt-3">
            <strong>Eco-conception</strong>
          </div>
          {/* useRecycledMaterials */}
          <div className="mt-2">
            <div className="form-row">
              <div className="form-check form-check-long-label form-switch m-2 d-flex align-items-center">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="switchUseRecycledMaterials"
                  name="switchUseRecycledMaterials"
                  checked={this.state.useRecycledMaterials}
                  onChange={() => this.handleClickSwitchUseRecycledMaterials(!this.state.useRecycledMaterials)}
                  disabled={this.props.isReadOnly}
                  readOnly={this.props.isReadOnly}
                />
                <label className="form-check-label form-label p-1" htmlFor="switchUseRecycledMaterials">
                  Did you use recycled materials to manufacture the product?
                </label>
              </div>
              <div className="form-group m-2 col-md-9">
                <label htmlFor="percentageRecycledMaterials" className="form-label">
                  If yes, what is the % of recycled materials used?
                </label>
                <input
                  className="form-control mt-1"
                  id="percentageRecycledMaterials"
                  name="percentageRecycledMaterials"
                  type="number"
                  placeholder="%"
                  defaultValue={!!this.state.percentageRecycledMaterials ? this.state.percentageRecycledMaterials : null}
                  onChange={(e) => this.onCommentPercentageRecycledMaterialsChange(parseInt(e.target.value))}
                  onFocus={() => this.setState({ isErrorOnUseRecycledMaterials: false })}
                  disabled={this.props.isReadOnly || !this.state.useRecycledMaterials}
                  readOnly={this.props.isReadOnly || !this.state.useRecycledMaterials}
                ></input>
                {this.state.isErrorOnUseRecycledMaterials ? (
                  <p id="errorOnUseRecycledMaterials" className="errorMessage mt-2">
                    {this.state.errorMessage}
                  </p>
                ) : null}
              </div>
            </div>
          </div>
          {/* evaluateCarbonImpact */}
          <div className="mt-2">
            <div className="form-row">
              <div className="form-check form-check-long-label form-switch m-2 d-flex align-items-center">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="switchEvaluateCarbonImpact"
                  name="switchEvaluateCarbonImpact"
                  checked={this.state.evaluateCarbonImpact}
                  onChange={() => this.handleClickSwitchEvaluateCarbonImpact(!this.state.evaluateCarbonImpact)}
                  disabled={this.props.isReadOnly}
                  readOnly={this.props.isReadOnly}
                />
                <label className="form-check-label form-check-long-label form-label p-1"
                       htmlFor="switchEvaluateCarbonImpact">
                  Did you evaluate the carbon impact of the product manufacturing "cradle-to-gate carbon footprint" and
                  transport?
                </label>
              </div>
              <div className="form-group m-2 col-md-9">
                <label htmlFor="evaluateCarbonImpact" className="form-label">
                  If yes, can you indicate the carbon impact of the product manufacturing? (kg CO2 eq)
                </label>
                <input
                  className="form-control mt-1"
                  id="evaluateCarbonImpact"
                  name="evaluateCarbonImpact"
                  type="number"
                  defaultValue={!!this.state.carbonImpact ? this.state.carbonImpact : null}
                  onChange={(e) => this.onCommentCarbonImpactChange(parseInt(e.target.value))}
                  onFocus={() => this.setState({ isErrorOncarbonImpact: false })}
                  disabled={this.props.isReadOnly || !this.state.evaluateCarbonImpact}
                  readOnly={this.props.isReadOnly || !this.state.evaluateCarbonImpact}
                ></input>
                {this.state.isErrorOncarbonImpact ? (
                  <p id="error-expiration-nda" className="errorMessage mt-2">
                    {this.state.errorMessage}
                  </p>
                ) : null}
              </div>
            </div>
          </div>
          {/* lifecycleAssessment */}
          <div className="mt-2">
            <div className="form-row">
              <div className="form-check form-check-long-label form-switch m-2 d-flex align-items-center">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="switchLifecycleAssessment"
                  name="switchLifecycleAssessment"
                  checked={this.state.lifecycleAssessment}
                  onChange={() => this.handleClickSwitchLifecycleAssessment(!this.state.lifecycleAssessment)}
                  disabled={this.props.isReadOnly}
                  readOnly={this.props.isReadOnly}
                />
                <label className="form-check-label form-label p-1" htmlFor="switchLifecycleAssessment">
                  Did you perform a Life Cycle assessment of the product?
                </label>
              </div>
            </div>
          </div>

          {/* canShare */}
          <div className="mt-2">
            <div className="form-row">
              <div className="form-check form-check-long-label form-switch m-2 d-flex align-items-center">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="switchCanShare"
                  name="switchCanShare"
                  checked={this.state.canShare}
                  onChange={() => this.handleClickSwitchCanShare(!this.state.canShare)}
                  disabled={this.props.isReadOnly}
                  readOnly={this.props.isReadOnly}
                />
                <label className="form-check-label form-check-long-label form-label p-1" htmlFor="switchCanShare">
                  If yes, do you agree to share this information on the products that you want to reference in
                  Foreground on case by case basis?
                </label>
              </div>
            </div>
          </div>
          {/* Transport */}
          <div className="row mt-3">
            <strong>Transport</strong>
          </div>
          {/* evaluateEnvironmentalImpactReduction */}
          <div className="mt-2">
            <div className="form-row">
              <div className="form-check form-check-long-label form-switch m-2 d-flex align-items-center">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="switchEvaluateEnvironmentalImpactReduction"
                  name="switchEvaluateEnvironmentalImpactReduction"
                  checked={this.state.evaluateEnvironmentalImpactReduction}
                  onChange={() => this.handleClickSwitchEvaluateEnvironmentalImpactReduction(!this.state.evaluateEnvironmentalImpactReduction)}
                  disabled={this.props.isReadOnly}
                  readOnly={this.props.isReadOnly}
                />
                <label className="form-check-label form-check-long-label form-label p-1"
                       htmlFor="switchEvaluateEnvironmentalImpactReduction">
                  Are you looking to systematically reduce the Environmental impact of transport?
                </label>
              </div>
            </div>
          </div>
          {/* environmentalImpactReduction */}
          <div className="mt-2">
            <div className="form-row">
              <div className="form-group m-2 col-md-9">
                <textarea
                  className="form-control mt-1"
                  id="environmentalImpactReduction"
                  name="environmentalImpactReduction"
                  rows={2}
                  maxLength={1024}
                  aria-label='environnement impact reduction'
                  defaultValue={!!this.state.environmentalImpactReduction ? this.state.environmentalImpactReduction : ''}
                  onChange={(e) => this.onCommentenvironmentalImpactReduction(e.target.value)}
                  disabled={this.props.isReadOnly || !this.state.evaluateEnvironmentalImpactReduction}
                  readOnly={this.props.isReadOnly || !this.state.evaluateEnvironmentalImpactReduction}
                ></textarea>
              </div>
            </div>
          </div>
          {/* Usage */}
          <div className="row mt-3">
            <strong>Usage</strong>
          </div>
          {/* expectedLifeSpan */}
          <div className="form-group m-2 col-md-9">
            <label htmlFor="expectedLifeSpan" className="form-label">
              What is the minimum expected life span of the product? (number of Years)
            </label>
            <input
              className="form-control mt-1"
              id="expectedLifeSpan"
              name="expectedLifeSpan"
              type="number"
              maxLength={4}
              defaultValue={!!this.state.expectedLifeSpan ? this.state.expectedLifeSpan : null}
              onChange={(e) => this.onCommentExpectedLifeSpan(parseInt(e.target.value))}
              onFocus={() => this.setState({ isErrorExpectedLifeSpan: false })}
              disabled={this.props.isReadOnly}
              readOnly={this.props.isReadOnly}
            ></input>
            {this.state.isErrorExpectedLifeSpan ? (
              <p id="errorOnExpectedLifeSpan" className="errorMessage mt-2">
                {this.state.errorMessage}
              </p>
            ) : null}
          </div>
          {/* Refurbish and Repair */}
          <div className="row mt-3">
            <strong>Refurbish and Repair</strong>
          </div>
          {/* refurbishingProcess */}
          <div className="mt-2">
            <div className="form-row">
              <div className="form-check form-check-long-label form-switch m-2 d-flex align-items-center">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="switchRefurbishingProcess"
                  name="switchRefurbishingProcess"
                  maxLength={1024}
                  checked={this.state.refurbishingProcess}
                  onChange={() => this.handleClickSwitchRefurbishingProcess(!this.state.refurbishingProcess)}
                  disabled={this.props.isReadOnly}
                  readOnly={this.props.isReadOnly}
                />
                <label className="form-check-label form-label p-1" htmlFor="switchRefurbishingProcess">
                  Do you have a process for refurbishing the product?
                </label>
              </div>
              <div className="form-group m-2 col-md-9">
                <label htmlFor="docsForRefurbishing" className="form-label">
                  Please explain or add any link to relevant website; if you need to add documents, please do it on step
                  7.
                </label>
                <textarea
                  className="form-control mt-1"
                  id="docsForRefurbishing"
                  name="docsForRefurbishing"
                  rows={2}
                  maxLength={1024}
                  defaultValue={!!this.state.docsForRefurbishing ? this.state.docsForRefurbishing : ''}
                  onChange={(e) => this.onCommentDocsForRefurbishingChange(e.target.value)}
                  disabled={this.props.isReadOnly || !this.state.refurbishingProcess}
                  readOnly={this.props.isReadOnly || !this.state.refurbishingProcess}
                ></textarea>
              </div>
            </div>
          </div>
          {/* repairingProcess */}
          <div className="mt-2">
            <div className="form-row">
              <div className="form-check form-check-long-label form-switch m-2 d-flex align-items-center">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="switchRepairingProcess"
                  name="switchRepairingProcess"
                  checked={this.state.repairingProcess}
                  onChange={() => this.handleClickSwitchRepairingProcess(!this.state.repairingProcess)}
                  disabled={this.props.isReadOnly}
                  readOnly={this.props.isReadOnly}
                />
                <label className="form-check-label form-check-long-label form-label p-1"
                       htmlFor="switchRepairingProcess">
                  Do you have a process for repairing the products?
                </label>
              </div>
              <div className="form-group m-2 col-md-9">
                <label htmlFor="docsForRepairing" className="form-label">
                  Please explain or add any link to relevant website; if you need to add documents, please do it on step
                  7.
                </label>
                <textarea
                  className="form-control mt-1"
                  id="docsForRepairing"
                  name="docsForRepairing"
                  rows={2}
                  maxLength={1024}
                  defaultValue={!!this.state.docsForRepairing ? this.state.docsForRepairing : ''}
                  onChange={(e) => this.onCommentDocsForRepairing(e.target.value)}
                  onFocus={() => this.setState({ isErrorOncarbonImpact: false })}
                  disabled={this.props.isReadOnly || !this.state.repairingProcess}
                  readOnly={this.props.isReadOnly || !this.state.repairingProcess}
                ></textarea>
              </div>
            </div>
          </div>
          {/* End of life - Conformity with WEEE regulation */}
          <div className="row mt-3">
            <strong>End of life - Conformity with WEEE regulation</strong>
          </div>

          {/* frenchMarketProducerRole */}
          <div className="mt-2">
            <div className="form-row">
              <div className="form-check form-check-long-label form-switch m-2 d-flex align-items-center">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="switchFrenchMarketProducerRole"
                  name="switchFrenchMarketProducerRole"
                  checked={this.state.frenchMarketProducerRole}
                  onChange={() => this.handleClickSwitchFrenchMarketProducerRole(!this.state.frenchMarketProducerRole)}
                  disabled={this.props.isReadOnly}
                  readOnly={this.props.isReadOnly}
                />
                <label className="form-check-label form-check-long-label form-label p-1"
                       htmlFor="switchFrenchMarketProducerRole">
                  If you are selling products on the French market, is your company assuming the role of producer in
                  conformity with European
                  regulation?
                </label>
              </div>
              <div className="form-group m-2 col-md-9">
                <label htmlFor="ecoOrganismAffiliation" className="form-label">
                  If yes, to which eco-organism are you affiliated?
                </label>
                <textarea
                  className="form-control mt-1"
                  id="ecoOrganismAffiliation"
                  name="ecoOrganismAffiliation"
                  rows={2}
                  maxLength={1024}
                  placeholder={PLACEHOLDER_FILL_FIELD}
                  defaultValue={!!this.state.ecoOrganismAffiliation ? this.state.ecoOrganismAffiliation : ''}
                  onChange={(e) => this.onCommentEcoOrganismAffiliation(e.target.value)}
                  disabled={this.props.isReadOnly || !this.state.frenchMarketProducerRole}
                  readOnly={this.props.isReadOnly || !this.state.frenchMarketProducerRole}
                ></textarea>
              </div>
            </div>
          </div>
          {/* ecMarketProducerRole */}
          <div className="mt-2">
            <div className="form-row">
              <div className="form-check form-check-long-label form-switch m-2 d-flex align-items-center">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="switchEcMarketProducerRole"
                  name="switchEcMarketProducerRole"
                  checked={this.state.ecMarketProducerRole}
                  onChange={() => this.handleClickSwitchEcMarketProducerRole(!this.state.ecMarketProducerRole)}
                  disabled={this.props.isReadOnly}
                  readOnly={this.props.isReadOnly}
                />
                <div>
                  <label htmlFor="switchEcMarketProducerRole"
                         className="form-check-label form-check-long-label form-label p-1">
                    If you are selling products in other EC countries, is your Company assuming the role of producer in
                    conformity with European
                    regulation?
                    <a
                      className="ms-1"
                      href="https://environment.ec.europa.eu/topics/waste-and-recycling/waste-electrical-and-electronic-equipment-weee_en"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      For more Information
                    </a>
                  </label>
                </div>
              </div>
              <div className="form-group m-2 col-md-9">
                <textarea
                  className="form-control mt-1"
                  id="ecCountriesNote"
                  name="ecCountriesNote"
                  rows={2}
                  maxLength={1024}
                  placeholder={PLACEHOLDER_FILL_FIELD}
                  defaultValue={!!this.state.ecCountriesNote ? this.state.ecCountriesNote : ''}
                  onChange={(e) => this.onCommentEcCountriesNote(e.target.value)}
                  disabled={this.props.isReadOnly || !this.state.ecMarketProducerRole}
                  readOnly={this.props.isReadOnly || !this.state.ecMarketProducerRole}
                ></textarea>
              </div>
            </div>
          </div>
          {BackToTop}
        </>
      );
    } else {
      return null;
    }
  }
}
