import React, { Component } from 'react';
import {
  DATE_PICKER_FORMAT,
  ORANGE_ADMIN_SCOPE,
  ORANGE_PARTNER_SCOPE,
  ORANGE_USER_SCOPE,
  PARTNER_SCOPE,
  PLEASE_SELECT_TECHNOLOGIES,
} from '../../models/constants';
import './product_management.css';
import { Device, DeviceTechnology, DeviceVersion, DeviceVersionToAdd } from '../../models/device';
import Utils from '../../helpers/Utils';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { DeviceWizardStepProps } from './DeviceWizard';
import { Tooltip } from 'react-tooltip';
import { ForegroundOption, ForegroundSelectMetaAction, ForegroundSelectMulti, SELECT_ACTION } from '../../components/ForegroundSelect';
import ProductService from '../../services/product.service';
import { BackToTop } from '../../components/backToTopButton';

class DeviceVersionStepState {
  errorMessage: string;
  isErrorOnAdd: boolean;
  newHardwareVersion: string;
  newFirmwareVersion: string;
  versions: DeviceVersion[];
  iotMarketplace: boolean;
  deviceTechnologiesSelected: Array<ForegroundOption[]>;
  deviceTechnologiesOptions: Array<ForegroundOption[]>;

  constructor(device: Device) {
    this.errorMessage = '';
    this.isErrorOnAdd = false;
    this.newHardwareVersion = '';
    this.newFirmwareVersion = '';
    this.versions = device.versions;
    this.iotMarketplace = device.iotMarketplace;
  }
}

export class DeviceVersionStep extends Component<DeviceWizardStepProps, DeviceVersionStepState> {
  constructor(props) {
    super(props);
    this.state = new DeviceVersionStepState(this.props.device);
    this.handleClickVersionAssessed = this.handleClickVersionAssessed.bind(this);
    this.handleChangeDateAssessed = this.handleChangeDateAssessed.bind(this);
    this.handleClickVersionConnected = this.handleClickVersionConnected.bind(this);
    this.handleChangeDateConnected = this.handleChangeDateConnected.bind(this);
    this.handleClickVersionApproved = this.handleClickVersionApproved.bind(this);
    this.handleChangeDateApproved = this.handleChangeDateApproved.bind(this);
    this.handleDeleteVersion = this.handleDeleteVersion.bind(this);
    this.handleAddVersion = this.handleAddVersion.bind(this);
    this.handleChangeDeviceNetworkInterfaces = this.handleChangeDeviceNetworkInterfaces.bind(this);
  }

  async componentDidMount() {
    try {
      if (!!this.state.versions && this.state.versions.length > 0) {
        this.setDeviceTechnologiesSelectedState();
        await this.setDeviceTechnologiesOptionsState();
      }
    } catch (e) {
      console.error(e);
    }
  }

  setDeviceTechnologiesSelectedState() {
    let deviceTechnologiesSelected = new Array<ForegroundOption[]>();
    this.state.versions.forEach((item, index) => {
      deviceTechnologiesSelected.push([]);
      item.technologies.forEach((tech) => {
        deviceTechnologiesSelected[index].push(new ForegroundOption(tech.label, tech.id));
      });
    });
    this.setState({
      deviceTechnologiesSelected: deviceTechnologiesSelected,
    });
  }

  async setDeviceTechnologiesOptionsState() {
    try {
      const scope = this.props.scope;
      let options;
      if (scope === ORANGE_ADMIN_SCOPE) {
        options = (await ProductService.getDeviceTechnologies()).data
          .sort((a, b) => (a.order > b.order ? 1 : -1))
          .map((item) => {
            return new ForegroundOption(item.label, item.id);
          });
      }

      let deviceTechnologiesOptions = new Array<ForegroundOption[]>();
      if (!!options) {
        if (!!this.state.versions && Array.isArray(this.state.versions)) {
          this.state.versions.forEach(() => {
            deviceTechnologiesOptions.push(options);
          });
        }
      }
      this.setState({
        deviceTechnologiesOptions: deviceTechnologiesOptions,
      });
    } catch (error) {
      console.log('DeviceVersionStep : ' + JSON.stringify(error));
    }
  }

  validate() {
    return true;
  }

  handleUpdateVersion(id: string) {
    if (!!!this.props.device.versionsToUpdate) {
      this.props.device.versionsToUpdate = [];
    }
    const idx = this.props.device.versionsToUpdate.findIndex((v) => v === id);
    if (idx === -1) {
      this.props.device.versionsToUpdate.push(id);
    }
  }

  handleClickVersionAssessed(id: string) {
    const version = this.props.device.versions.find((v) => v.id === id);
    if (!!version) {
      version.assessed = !version.assessed;
      if (!!!version.assessedDate) {
        version.assessedDate = Utils.formatDateToAPI(new Date());
      }
      this.handleUpdateVersion(id);
    }

    this.setState({ versions: this.props.device.versions });
  }

  handleChangeDateAssessed(id: string, date) {
    const version = this.props.device.versions.find((v) => v.id === id);
    if (!!version) {
      version.assessedDate = Utils.formatDateToAPI(date);
      this.handleUpdateVersion(id);
    }
    this.setState({ versions: this.props.device.versions });
  }

  handleClickVersionConnected(id: string) {
    const version = this.props.device.versions.find((v) => v.id === id);
    if (!!version) {
      version.connected = !version.connected;
      this.handleUpdateVersion(id);
    }
    this.setState({ versions: this.props.device.versions });
  }

  handleChangeDateConnected(id: string, date) {
    const version = this.props.device.versions.find((v) => v.id === id);
    if (!!version) {
      version.connectedDate = Utils.formatDateToAPI(date);
      this.handleUpdateVersion(id);
    }
    this.setState({ versions: this.props.device.versions });
  }

  handleClickVersionApproved(id: string) {
    const version = this.props.device.versions.find((v) => v.id === id);
    if (!!version) {
      version.approved = !version.approved;
      this.handleUpdateVersion(id);
    }

    this.setState({ versions: this.props.device.versions });
  }

  handleChangeDateApproved(id: string, date) {
    const version = this.props.device.versions.find((v) => v.id === id);
    if (!!version) {
      version.approvedDate = Utils.formatDateToAPI(date);
      this.handleUpdateVersion(id);
    }
    this.setState({ versions: this.props.device.versions });
  }

  handleDeleteVersion(id: string) {
    const versionIdx = this.props.device.versions.findIndex((v) => v.id === id);
    if (!!!this.props.device.versionsToDelete) {
      this.props.device.versionsToDelete = [];
    }
    this.props.device.versionsToDelete.push(id);
    if (versionIdx !== -1) {
      this.props.device.versions.splice(versionIdx, 1);
    }
    this.setState({ versions: this.props.device.versions });
  }

  handleAddVersion() {
    const newHardwareVersion = this.state.newHardwareVersion;
    const newFirmwareVersion = this.state.newFirmwareVersion;
    const version = this.props.device.versions.find((v) => v.firmwareVersion === newFirmwareVersion && v.hardwareVersion === newHardwareVersion);
    if (!!version) {
      this.setState({
        isErrorOnAdd: true,
        errorMessage: 'Version already exist, it cannot be added.',
      });
    } else {
      const newVersionToAdd = new DeviceVersionToAdd(this.state.newHardwareVersion, this.state.newFirmwareVersion);
      const newVersion = new DeviceVersion(newVersionToAdd);
      if (!!!this.props.device.versionsToAdd) {
        this.props.device.versionsToAdd = [];
      }
      this.props.device.versionsToAdd.push(newVersionToAdd);
      this.props.device.versions.push(newVersion);
      this.setState({
        newHardwareVersion: '',
        newFirmwareVersion: '',
        versions: this.props.device.versions,
        isErrorOnAdd: false,
      });
    }
  }

  isLabelled() {
    return !!this.props.device.versions.find((item) => {
      return item.approved === true || item.assessed === true || item.connected === true;
    });
  }

  handleSwitchIotGroupCatalog() {
    this.setState({ iotMarketplace: !this.state.iotMarketplace });
    this.props.device.iotMarketplace = !this.state.iotMarketplace;
  }

  handleChangeDeviceNetworkInterfaces(event: ForegroundOption[], action: ForegroundSelectMetaAction, id: string) {
    switch (action.action) {
      case SELECT_ACTION.SELECT_OPTION:
        {
          let version = this.props.device.versions.filter((v) => v.id === id)[0];
          version.technologies = [...version.technologies, new DeviceTechnology(undefined, action.option.label, null, action.option.value)];
          this.setDeviceTechnologiesSelectedState();
          this.handleUpdateVersion(id);
        }
        break;
      case SELECT_ACTION.REMOVE_VALUE:
        {
          let version = this.props.device.versions.filter((v) => v.id === id)[0];
          version.technologies = version.technologies.filter((t) => t.id !== action.removedValue.value);
          this.setDeviceTechnologiesSelectedState();
          this.handleUpdateVersion(id);
        }
        break;
      case SELECT_ACTION.CLEAR:
        const version = this.props.device.versions.find((v) => v.id === id);
        if (!!version) {
          version.technologies = [];
        }
        this.setDeviceTechnologiesSelectedState();
        this.handleUpdateVersion(id);
        break;
      default:
        console.log('DeviceVersionStep : unexpected action ...');
        break;
    }
  }

  handleClickTestedBy(id: string) {
    const version = this.props.device.versions.find((v) => v.id === id);
    if (!!version) {
      version.testedBy = !version.testedBy;
      this.handleUpdateVersion(id);
    }

    this.setState({ versions: this.props.device.versions });
  }

  handleChangeTestedByName(id: string, name: string) {
    const version = this.props.device.versions.find((v) => v.id === id);
    if (!!version) {
      version.testedByName = name;
      this.handleUpdateVersion(id);
    }
    this.setState({ versions: this.props.device.versions });
  }

  render() {
    if (this.props.shouldDisplay) {
      const scope = this.props.scope;
      const canAddVersion = scope === ORANGE_ADMIN_SCOPE || scope === ORANGE_PARTNER_SCOPE || scope === PARTNER_SCOPE;
      const newVersionCanBeAdded = this.state.newFirmwareVersion !== '' && this.state.newHardwareVersion !== '';
      const canDeleteVersion = scope === ORANGE_ADMIN_SCOPE;
      const canLabelledVersion = scope === ORANGE_ADMIN_SCOPE;
      const canSwitchIotMarketPlace = scope === ORANGE_ADMIN_SCOPE;
      const showSwitchIotMarketPlace = scope === ORANGE_ADMIN_SCOPE || scope === ORANGE_USER_SCOPE;
      const showAddTechnologies = scope === ORANGE_ADMIN_SCOPE;
      const showTestedBy = scope === ORANGE_ADMIN_SCOPE;

      return (
        <>
          <div className='row'>
            <div className='mt-2'>All mandatory fields are marked with an *</div>
            <div className='row col-md-6 mt-2'>
              {showSwitchIotMarketPlace ? (
                <>
                  <div
                    className='form-check form-switch m-2 div-check'
                    data-tooltip-id='my-tooltip'
                    data-tooltip-content={!this.isLabelled() ? 'At least a version should be labelled!!! ' : null}
                    data-tooltip-place='right'
                  >
                    <input
                      className='form-check-input'
                      type='checkbox'
                      role='switch'
                      id='iotGroupCatalog'
                      name='iotGroupCatalog'
                      checked={this.props.device.iotMarketplace}
                      onChange={() => {
                        this.handleSwitchIotGroupCatalog();
                      }}
                      disabled={!canSwitchIotMarketPlace || !this.isLabelled()}
                      readOnly={!canSwitchIotMarketPlace || !this.isLabelled()}
                    />
                    <label className='form-check-label' htmlFor='iotGroupCatalog'>
                      Iot Group Catalog
                    </label>
                    <a
                      className='icon foreground-ic_Guide_help not_underlined ms-2 font-28'
                      href='https://iotjourney.orange.com'
                      target='_blank'
                      rel='noreferrer'
                    >
                      <span className='visually-hidden'>IoT Journey portal </span>
                    </a>
                  </div>
                  <Tooltip id='my-tooltip' className='tooltip-content' />
                </>
              ) : null}
              <h2 className='fs-4 is-required mt-3'>New version</h2>
              <div className='row mt-2'>
                <div className='col-md-4'>
                  <input
                    className='w-100 form-control'
                    type='text'
                    id='newHardwareVersion'
                    maxLength={191}
                    placeholder='Hardware version'
                    aria-label='Hardware version'
                    defaultValue={this.state.newHardwareVersion}
                    onChange={(e) => {
                      this.setState({
                        newHardwareVersion: e.target.value,
                        isErrorOnAdd: false,
                      });
                    }}
                    readOnly={!canAddVersion}
                    disabled={!canAddVersion}
                  ></input>
                </div>
                <div className='col-md-4'>
                  <input
                    className='w-100 form-control fs-6'
                    type='text'
                    id='newFirmwareVersion'
                    maxLength={191}
                    placeholder='Firmware version'
                    aria-label='Firmware version'
                    defaultValue={this.state.newFirmwareVersion}
                    onChange={(e) => {
                      this.setState({
                        newFirmwareVersion: e.target.value,
                        isErrorOnAdd: false,
                      });
                    }}
                    readOnly={!canAddVersion}
                    disabled={!canAddVersion}
                    aria-describedby={this.state.isErrorOnAdd ? 'error-versionAdd' : ''}
                    aria-invalid={this.state.isErrorOnAdd}
                  ></input>
                </div>
                <div className=' col-md-4 '>
                  <button
                    className='btn btn-primary btn-block'
                    disabled={!newVersionCanBeAdded}
                    onClick={() => {
                      this.handleAddVersion();
                    }}
                  >
                    <span aria-hidden='true'></span> Add
                  </button>
                </div>
                {this.state.isErrorOnAdd ? (
                  <p id='error-versionAdd' className='errorMessage mt-2'>
                    {this.state.errorMessage}
                  </p>
                ) : null}
              </div>
            </div>
          </div>
          <div className='row mt-4'>
            <h2 className='fs-4'>Versions</h2>
            {!!this.state.versions && this.state.versions.length <= 0 ? (
              <label>No version defined</label>
            ) : (
              <>
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col" style={{ width: '100px' }}>
                        Action
                      </th>
                      <th scope="col">Hardware Version</th>
                      <th scope="col">Firmware Version</th>
                      <th scope="col">
                        <img src={`${process.env.PUBLIC_URL}/assets/img/reminders_19371.svg`} alt="" height="40px" />
                        Assessed
                      </th>
                      <th scope="col">
                        <img src={`${process.env.PUBLIC_URL}/assets/img/smart_object_19371.svg`} alt="" height="40px" />
                        Connected
                      </th>
                      <th scope="col">
                        <img src={`${process.env.PUBLIC_URL}/assets/img/orange_rewards_17792.svg`} alt="" height="40px" />
                        Approved
                      </th>
                      <th scope="col">
                        <img src={`${process.env.PUBLIC_URL}/assets/img/iot-device-network.svg`} alt="" height="40px" />
                        Technologies
                      </th>
                      <th scope="col">
                        <img src={`${process.env.PUBLIC_URL}/assets/img/task-list.svg`} alt="" height="40px" />
                        Tested by
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {!!this.state.versions
                      ? this.state.versions.map((v, index) => {
                          return (
                            <tr key={v.id}>
                              <td style={{ width: '100px', textAlign: 'center' }} className="vertical-align-middle">
                                {canDeleteVersion || v.id.toString().startsWith('TEMP-') ? (
                                  <>
                                    <button
                                      style={{ border: 'none' }}
                                      id={`deleteVersion-${v.id}`}
                                      className="foreground-Trash delete_button not_underlined btn btn-link"
                                      onClick={() => {
                                        this.handleDeleteVersion(v.id);
                                      }}
                                    ></button>
                                  </>
                                ) : (
                                  ''
                                )}
                              </td>
                              <td className="vertical-align-middle">{v.hardwareVersion}</td>
                              <td className="vertical-align-middle">{v.firmwareVersion}</td>
                              {v.newVersion ? (
                                <td className="vertical-align-middle" colSpan={5}>Please save the current version before making any changes (by clicking on save on the last step).</td>
                                ) : (
                                <>
                              <td className="col-version">
                                <div className="d-flex flex-row">
                                  <div className="form-check form-switch mt-2 d-flex">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      role="switch"
                                      id={`assessed-${v.id}`}
                                      name={`assessed-${v.id}`}
                                      aria-label={`Assessed Hardware Version ${v.hardwareVersion} Firmware Version ${v.firmwareVersion}`}
                                      checked={v.assessed}
                                      onChange={() => this.handleClickVersionAssessed(v.id)}
                                      disabled={!canLabelledVersion}
                                      readOnly={!canLabelledVersion}
                                    />
                                  </div>
                                  {canLabelledVersion ? (
                                    <>
                                      <DatePicker
                                        className="form-control mt-1 datePicker w-75 d-flex"
                                        id={`assessed-date-${v.id}`}
                                        name={`assessed-date-${v.id}`}
                                        ariaLabelledBy={`assessed-description-${v.id}`}
                                        dateFormat={DATE_PICKER_FORMAT}
                                        selected={
                                          v.assessed || (!v.assessed && !!v.assessedDate && v.assessedDate !== '')
                                            ? !!v.assessedDate
                                              ? new Date(v.assessedDate)
                                              : new Date()
                                            : ''
                                        }
                                        onChange={(date) => this.handleChangeDateAssessed(v.id, date)}
                                        disabled={!v.assessed}
                                        readOnly={!v.assessed}
                                      />
                                      <span className="visually-hidden" id={`assessed-description-${v.id}`}>
                                        Assessed date for Hardware Version {v.hardwareVersion} Firmware Version {v.firmwareVersion}
                                      </span>
                                    </>
                                  ) : (
                                    <span className="device-version-readonly-date">{Utils.formatDateToWeb(v.assessedDate)}</span>
                                  )}
                                </div>
                              </td>
                              <td className="col-version">
                                <div className="d-flex flex-row">
                                  <div className="form-check form-switch mt-2 d-flex">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      role="switch"
                                      id={`connected-${v.id}`}
                                      name={`connected-${v.id}`}
                                      aria-label={`Connected Hardware Version ${v.hardwareVersion} Firmware Version ${v.firmwareVersion}`}
                                      checked={v.connected}
                                      onChange={() => this.handleClickVersionConnected(v.id)}
                                      disabled={!canLabelledVersion}
                                      readOnly={!canLabelledVersion}
                                    />
                                  </div>
                                  {canLabelledVersion ? (
                                    <>
                                      <DatePicker
                                        className="form-control mt-1 datePicker w-75 d-flex"
                                        id={`connected-date-${v.id}`}
                                        name={`connected-date-${v.id}`}
                                        ariaLabelledBy={`connected-description-${v.id}`}
                                        dateFormat={DATE_PICKER_FORMAT}
                                        selected={
                                          v.connected || (!v.connected && !!v.connectedDate && v.connectedDate !== '')
                                            ? !!v.connectedDate
                                              ? new Date(v.connectedDate)
                                              : new Date()
                                            : ''
                                        }
                                        onChange={(date) => this.handleChangeDateConnected(v.id, date)}
                                        disabled={!v.connected}
                                        readOnly={!v.connected}
                                      />
                                      <span className="visually-hidden" id={`connected-description-${v.id}`}>
                                        Connected date for Hardware Version {v.hardwareVersion} Firmware Version {v.firmwareVersion}
                                      </span>
                                    </>
                                  ) : (
                                    <span className="device-version-readonly-date">{Utils.formatDateToWeb(v.connectedDate)}</span>
                                  )}
                                </div>
                              </td>
                              <td className="col-version">
                                <div className="d-flex flex-row">
                                  <div className="form-check form-switch mt-2 d-flex">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      role="switch"
                                      id={`approved-${v.id}`}
                                      name={`approved-${v.id}`}
                                      aria-label={`Approved Hardware Version ${v.hardwareVersion} Firmware Version ${v.firmwareVersion}`}
                                      checked={v.approved}
                                      onChange={() => this.handleClickVersionApproved(v.id)}
                                      disabled={!canLabelledVersion}
                                      readOnly={!canLabelledVersion}
                                    />
                                  </div>
                                  {canLabelledVersion ? (
                                    <>
                                      <DatePicker
                                        className="form-control mt-1 datePicker w-75 d-flex"
                                        id={`approved-date-${v.id}`}
                                        name={`approved-date-${v.id}`}
                                        ariaLabelledBy={`approved-description-${v.id}`}
                                        dateFormat={DATE_PICKER_FORMAT}
                                        selected={v.approved ? (!!v.approvedDate ? new Date(v.approvedDate) : new Date()) : ''}
                                        onChange={(date) => this.handleChangeDateApproved(v.id, date)}
                                        disabled={!v.approved}
                                        readOnly={!v.approved}
                                      />
                                      <span className="visually-hidden" id={`approved-description-${v.id}`}>
                                        Connected date for Hardware Version {v.hardwareVersion} Firmware Version {v.firmwareVersion}
                                      </span>
                                    </>
                                  ) : (
                                    <span className="device-version-readonly-date">{Utils.formatDateToWeb(v.approvedDate)}</span>
                                  )}
                                </div>
                              </td>
                              <td className="col-version pt-1">
                                <ForegroundSelectMulti
                                  inputId="technologies"
                                  placeholder={PLEASE_SELECT_TECHNOLOGIES}
                                  value={this.state.deviceTechnologiesSelected[index]}
                                  options={this.state.deviceTechnologiesOptions[index]}
                                  onChange={(e, a) => this.handleChangeDeviceNetworkInterfaces(e, a, v.id)}
                                  isDisabled={this.props.isReadOnly || !showAddTechnologies}
                                  ariaRequired={false}
                                />
                              </td>
                              <td className="col-version">
                                <div className="d-flex flex-row">
                                  <div className="form-check form-switch mt-2 d-flex">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      role="switch"
                                      id={`tested-by-${v.id}`}
                                      name={`tested-by-${v.id}`}
                                      aria-label={`Tested By`}
                                      checked={v.testedBy}
                                      onChange={() => this.handleClickTestedBy(v.id)}
                                      disabled={!showTestedBy}
                                      readOnly={!showTestedBy}
                                    />
                                  </div>
                                  <input
                                    className="w-100 form-control"
                                    type="text"
                                    id={`tested-by-name-${v.id}`}
                                    maxLength={255}
                                    placeholder="Tested by name"
                                    aria-label="Tested by name"
                                    defaultValue={v.testedByName}
                                    onChange={(e) => this.handleChangeTestedByName(v.id, e.target.value)}
                                    readOnly={!v.testedBy}
                                    disabled={!v.testedBy}
                                  ></input>
                                </div>
                              </td>
                                </>)}
                            </tr>
                          );
                        })
                      : null}
                  </tbody>
                </table>
              </>
            )}
          </div>
          {BackToTop}
        </>
      );
    } else {
      return null;
    }
  }
}
