import React, { act, Component } from 'react';
import './wizard.css';

class WizardFooterProps {
  public currentStep: number;
  public nbMaxItem: number;
  public canCancel: boolean;
  public hidePrevious?: boolean;
  public isReadOnly: boolean;
  public actionLabel: string;
  public onCancel() {}
  public onPrevious() {}
  public onNext() {}
  public onSubmit() {}
}

class WizardFooterState {
  public submitDisabled:boolean;

  constructor() {
    this.submitDisabled = false;
  }
}

export class WizardFooter extends Component<WizardFooterProps, WizardFooterState> {
  constructor(props) {
    super(props);
    this.state=new WizardFooterState();
    this.state=({submitDisabled:this.props.isReadOnly});
  }

  private async onSubmit(e){
    e.preventDefault();
    if (this.state.submitDisabled) return

    if (!!!this.props.isReadOnly) {
      this.setState({submitDisabled:true})
    }
    await this.props.onSubmit()
    act (()=>{
      this.setState({submitDisabled:this.props.isReadOnly })
    })

  }

  render() {
    const { currentStep, nbMaxItem, canCancel, hidePrevious, actionLabel } = this.props;

    return (
      <div className="d-flex justify-content-end">
        {canCancel ? (
          <div className="wizard-footer-div">
            <button id="wizardCancel" onClick={() => this.props.onCancel()} className=" btn btn-secondary">
              Cancel
            </button>
          </div>
        ) : (
          ''
        )}

        <div className="wizard-footer-div">
          {this.props.hidePrevious ? (



























































































            ''
          ) : (
            <button
              id="wizardPrevious"
              onClick={() => this.props.onPrevious()}
              className={`btn btn-secondary ${currentStep === 1 ? 'disabled' : ''}`}
              disabled={currentStep === 1 ? true : false}
            >
              Previous
            </button>
          )}
        </div>
        <div>
          {currentStep < nbMaxItem ? (
            <button id="wizardNext" onClick={() => this.props.onNext()} className="btn btn-primary ">
              Next
            </button>
          ) : (
            <button id="wizardAction" onClick={(e) => this.onSubmit(e)} className="btn btn-primary" disabled={this.state.submitDisabled}>
              {actionLabel}
            </button>
          )}
        </div>
      </div>
    );
  }
}
